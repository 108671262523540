
import BreadCrumb from '@/components/template/bread-crumb.vue'
import CartItems from '@/components/cart/cart-items.vue'
import SearchSelect from '@/components/share/search-select.vue'
import InputText from '@/components/share/input-text.vue'

import SwiperSlider from '@/components/share/swiper-item.vue'
import { mapGetters, mapActions } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined
import modalCoupon from '@/components/suggest-coupon/modalCoupon.vue'

export default {
  name: 'Cart',
  components: {
    breadcrumb: BreadCrumb,
    'input-text': InputText,
    'cart-items': CartItems,
    'search-select': SearchSelect,
    'swiper-item': SwiperSlider,
    modalCoupon,
    // modalCoupon: async () =>
    //   await import('@/components/suggest-coupon/modalCoupon.vue'),
  },
  // head() {
  //   return {
  //     // title: 'This is the about page',
  //     meta: [
  //       {
  //         name: 'description',
  //         content: this.content,
  //       },
  //     ],
  //   }
  // },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'cart',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      showErrCouponVoucher: false,
      showErrCouponDelivery: false,
      is_use_coupon: false,
      is_use_coupon_delivery: false,
      used_voucher_type: '',
      getItems: false,
      input: {
        sku: '',
      },
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
      },
      productGroups: [],
      warningMessages: [],
      departmentId: '',
      requesterLineId: '',
      requesterLineList: [],
      selectedCostcenter: {},
      costcenterList: [],
      approval: [],
      approverLevel: '1',
      approverLevelOptions: [],
      selectedApprover: {},
      openCat: false,
      breadcrumbItems: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Shopping Cart',
        },
      ],
      budget: {
        id: '',
        used_amt: 0,
        budget_amt: 0,
        remain_amt: 0,
        variant: 'primary',
      },
      filterDepartment: '',
      items: [],
      noItems: [],
      cart: {
        voucher_no: '',
        delivery_fee_voucher_no: '',
      },
      itemsRecommended: [],
      checkoutWarningMessages: [],
      totalExceptVat: 0,
      totalExcludedVat: 0,
      vat: 0,
      deliveryFee: 0,
      ofmDiscount: 50,
      sellerDiscount: 50,
      deliveryDiscount: 100,
      fixedPosition: false,
      absoluteBottom: false,
      hasBudgetValue: false,
      is_allow_create_order_over_budget: false,
      companyControlType: '',
      isNotPurchaser: false,
      addProductWarningMessages: [],
      showSuccess: false,
      productOverStockList: [],
      favoriteList: [],
      warningVoucher: {},
      warningVoucherDelivery: {},
      isLoadingCart: true,
      isLoadingRecentProduct: true,
      isover_goodsreceive_period: false,
      flashSaleEndDate: '',
      currentDate: '',
      promotion_discounts: [],
      isResetName: false,
      isShowLimitWarning: false,
      // multi coupon
      voucher_no: '',
      delivery_fee_voucher_no: '',
      couponsType: [],
      tempCouponVouchers: '',
      tempCouponDelivery: '',
      selected_suborders: '',
      cookie_selected_suborders: [],
      overlayImagesItemsRecommended: [],
      isModalCoupon: false,
      modalData: {},
      isCouponCollapsed: false,
      isCodeCollapsed: true,
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    ...mapGetters('cart', ['cartId']),
    overBudgetInfo() {
      if (
        this.profileType !== undefined &&
        this.profileType === 'eprocurement'
      ) {
        if (!this.is_allow_create_order_over_budget) return false
        return this.is_allow_create_order_over_budget &&
          this.budget.remain_amt < this.summary.net_amt + this.summary.vat_amt
          ? true
          : false
      } else return false
    },
    hasWrongConditionLimitMinMaxQty() {
      if (this.items.length) {
        return this.items.filter((q) => q.is_selected && q.is_limit_qty).length
          ? true
          : false
      } else return false
    },
    hasWrongConditionOverStock() {
      if (this.items.length) {
        return this.items.filter((q) => q.is_selected && q.is_over_stock).length
          ? true
          : false
      } else return false
    },
    hasCheckoutItem() {
      if (this.items.length) {
        return this.items.filter((q) => q.is_selected && !q.is_wrong_condition)
          .length
          ? true
          : false
      } else return false
    },
  },
  watch: {
    summary: {
      handler() {
        if (
          this.profileType !== undefined &&
          this.profileType === 'eprocurement'
        ) {
          this.checkOverBudget()
        }
        this.$_onInsiderBasket({
          page: {
            type: 'Basket',
            custom: {
              category_name: null,
            },
          },
          total: this.$utils.formatPrice(
            this.summary.net_amt + this.summary.vat_amt,
            2
          ),
          voucher_discount: this.$utils.formatPrice(
            this.summary.voucher_disc_amt_excvat,
            2
          ),
          shipping_cost: this.$utils.formatPrice(
            this.summary.net_deliveryfee_excvat,
            2
          ),
          vouchers: this.cart.voucher_no,
          line_items: this.items,
        })
        // if (!this.cart.delivery_fee_voucher_no && !this.cart.voucher_no) {
        //   this.isCouponCollapsed = false
        // }
      },
      immediate: true,
    },
    selectedCostcenter: {
      async handler() {
        this.checkOverLastAppovalBudget()
      },
      deep: true,
    },
    'productGroups.length': {
      handler: function (val) {
        console.log('productGroups', val)
        if (val) {
          this.onSetCookieShift()
        }

        if (val === 0 && process.client) {
          Cookie.set(
            `coupons_${this.openId}-${this.userProfile.id}`,
            {
              voucher_no: '',
              delivery_fee_voucher_no: '',
              selected_suborders: [],
            },
            {
              expires: 1,
              secure: true,
              sameSite: 'none',
            }
          )
        }
      },
      // immediate: true,
      deep: true,
    },
    // items: {
    //   handler: function (val) {
    //     this.totalExceptVat = 0
    //     this.totalExcludedVat = 0
    //     this.vat = 0
    //     val.forEach((element) => {
    //       this.totalExceptVat += Number(element.discExcVat * element.qty)
    //       this.totalExcludedVat += Number(element.excVat * element.qty)
    //       this.vat += Number((element.incVat - element.excVat) * element.qty)
    //     })
    //   },
    //   deep: true,
    // },
  },
  async created() {
    console.log('created')
    this.$nuxt.$on('reloadCart', await this.getCalculateCart)
    if (process.client) {
      await this.getCalculateCart(false)
    }

    console.log('this.profileType', this.profileType)
    if (this.profileType !== undefined && this.profileType === 'eprocurement') {
      await this.getCompaniesRequesterLineByUser()
      await this.checkOverLastAppovalBudget()
      await this.checkOverGoodsReceivePeroid()
      if (!this.userProfile.is_site_active)
        this.checkoutWarningMessages.push('closed_site')
    }
  },
  async mounted() {
    await this.getFlashSaleExpired()
    await this.getUserProductHistory()
    this.checkAccountActivate()
    const _this = this
    window.addEventListener('resize', function () {
      _this.onSetCookieShift()
    })
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  updated() {},
  async beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    this.$nuxt.$off('reloadCart', await this.getCalculateCart)
    this.$nuxt.$emit('cookieShift', { isShift: false })
  },
  methods: {
    ...mapActions('cart', ['setCartCount']),
    // check end date flash sale
    async getFlashSaleExpired() {
      await this.$productProvider.getFlashSaleExpired().then(
        (result) => {
          if (result.status === 200) {
            this.currentDate = new Date(
              result.data.current_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })

            this.flashSaleEndDate = new Date(
              result.data.end_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })
          }
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
    },
    async reloadPage() {
      await this.getCalculateCart()
      if (this.profileType === 'eprocurement')
        await this.checkOverLastAppovalBudget()
      // this.getCalculateCart()
    },
    checkOverLastAppovalBudget() {
      let reqesterLine = this.requesterLineList.find(
        (q) => q.costcenter.id === this.selectedCostcenter.value
      )
      if (reqesterLine !== undefined && reqesterLine.approval.length) {
        if (
          reqesterLine.approval[reqesterLine.approval.length - 1].credit_limit <
          this.summary.net_amt + this.summary.vat_amt
        ) {
          let index = this.checkoutWarningMessages.findIndex(
            (q) => q === 'over_approval_budget'
          )
          if (index !== -1) this.checkoutWarningMessages.splice(index)
          this.checkoutWarningMessages.push('over_approval_budget')
        } else {
          let index = this.checkoutWarningMessages.findIndex(
            (q) => q === 'over_approval_budget'
          )
          if (index !== -1) this.checkoutWarningMessages.splice(index)
        }
      }
      // else {
      //   let index = this.checkoutWarningMessages.findIndex(
      //     (q) => q === 'requesterline_notfound'
      //   )
      //   if (index !== -1) this.checkoutWarningMessages.splice(index)
      //   this.checkoutWarningMessages.push('requesterline_notfound')
      // }
    },
    checkOverBudget() {
      if (this.budget.id !== '') {
        if (
          !this.is_allow_create_order_over_budget &&
          this.budget.remain_amt < this.summary.net_amt + this.summary.vat_amt
        ) {
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'over_budget'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
          this.checkoutWarningMessages.push('over_budget')
        } else if (
          !this.is_allow_create_order_over_budget &&
          this.budget.remain_amt >= this.summary.net_amt + this.summary.vat_amt
        ) {
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'over_budget'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
        } else if (
          this.is_allow_create_order_over_budget &&
          this.budget.remain_amt < this.summary.net_amt + this.summary.vat_amt
        ) {
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'over_budget'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
        }
      }
    },
    async getCompaniesRequesterLineByUser() {
      console.log('getCompaniesRequesterLineByUser')
      const result =
        await this.$profileProvider.getCompaniesRequesterLineByUser(
          this.userProfile.id,
          this.openId
        )
      console.log('result', result)
      if (result.status === 200) {
        if (result.data.length) {
          this.requesterLineList = result.data
          result.data.forEach((item) => {
            this.costcenterList.push({
              value: item.costcenter.id,
              text: `[${item.costcenter.code}] ${
                item.costcenter.name[this.$i18n.locale]
              }`,
            })
          })

          if (Object.keys(this.userCostcenter).length) {
            console.log(
              'klsajdkljasdkljasdklj',
              result.data.find(
                (q) => q.costcenter.id === this.userCostcenter.id
              )
            )
            if (
              result.data.find(
                (q) => q.costcenter.id === this.userCostcenter.id
              ) !== undefined
            ) {
              this.selectedCostcenter = {
                value: this.userCostcenter.id,
                text: `[${this.userCostcenter.code}] ${
                  this.userCostcenter.name[this.$i18n.locale]
                }`,
              }
              this.departmentId = this.userCostcenter.department_id
              this.requesterLineId = result.data.find(
                (q) => q.costcenter.id === this.userCostcenter.id
              ).id
            } else {
              if (this.companyControlType !== 'Costcenter') {
                this.selectedCostcenter = {
                  value: result.data[0].costcenter.id,
                  text: `[${result.data[0].costcenter.code}] ${
                    result.data[0].costcenter.name[this.$i18n.locale]
                  }`,
                }
                this.departmentId = result.data[0].costcenter.department_id
                this.requesterLineId = result.data[0].id
              } else {
                this.isNotPurchaser = true
                this.checkoutWarningMessages.push('not_purchaser')
              }
            }

            result.data.forEach((item) => {
              if (item.costcenter.id === this.selectedCostcenter.value) {
                if (item.approval.length) {
                  this.approval = item.approval
                  this.approverLevel = 1
                }
              }
            })
          } else {
            this.selectedCostcenter = {
              value: result.data[0].costcenter.id,
              text: `[${result.data[0].costcenter.code}] ${
                result.data[0].costcenter.name[this.$i18n.locale]
              }`,
            }
            this.departmentId = result.data[0].costcenter.department_id
            result.data.forEach((item) => {
              if (item.costcenter.id === this.selectedCostcenter.value) {
                if (item.approval.length) {
                  this.approval = item.approval
                  this.approverLevel = 1
                }
              }
            })
          }
          if (this.userProfile.is_use_budget_control)
            if (!this.isNotPurchaser) await this.getCompaniesControlBudget()
        } else {
          this.checkoutWarningMessages.push('requesterline_notfoud')
        }
      } else if (result.status === 404) {
        this.checkoutWarningMessages.push('requesterline_notfoud')
      }
    },
    async getCompaniesControlBudget() {
      const result = await this.$profileProvider.getCompaniesControlBudget(
        this.userProfile.id,
        this.selectedCostcenter.value,
        this.departmentId
      )
      console.log('result getCompaniesControlBudget:', result)
      if (result.status === 200) {
        if (result.data.period != null) {
          this.budget = result.data.period
          this.budget.id = result.data.id
          this.hasBudgetValue = true
          this.is_allow_create_order_over_budget =
            result.data.is_allow_create_order_over_budget
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'budget_notfound'
            )
            if (index !== -1) this.checkoutWarningMessages.splice(index)
          }
          await this.checkOverBudget()
        } else {
          this.hasBudgetValue = false
          if (this.checkoutWarningMessages.length) {
            let index = this.checkoutWarningMessages.findIndex(
              (q) => q === 'budget_notfound'
            )

            if (index !== -1) this.checkoutWarningMessages.splice(index)
            this.checkoutWarningMessages.push('budget_notfound')
          } else {
            this.checkoutWarningMessages.push('budget_notfound')
          }
        }
      } else if (result.status === 404) {
        this.hasBudgetValue = false
        this.checkoutWarningMessages.push('budget_notfound')
      }
    },
    async onSelectedCoscenter(value) {
      console.log('value', value)
      this.approverLevel = 1
      this.selectedCostcenter = value
      let temp = this.requesterLineList.find(
        (q) => q.costcenter.id === this.selectedCostcenter.value
      )
      this.approval = temp !== undefined ? temp.approval : []
      this.departmentId =
        temp !== undefined ? temp.costcenter.department_id : ''
      this.requesterLineId = temp !== undefined ? temp.id : ''
      if (
        this.userProfile !== undefined &&
        this.userProfile !== null &&
        this.userProfile.is_use_budget_control
      )
        await this.getCompaniesControlBudget()
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      // console.log(this.approverLevel)
    },
    onSelectApprover(value) {
      this.selectedApprover = value
      // console.log(this.selectedApprover)
    },
    // async getCalculateCart() {
    //   const result = await this.$productProvider.getCalculateCart(
    //     this.cartId,
    //     this.userProfile.id,
    //     this.profileType
    //   )getCalculateCart
    //   console.log('result', result)
    //   if (result.status === 200) {
    //     this.summary = result.data
    //   }
    // },
    async getCalculateCart(showLoading = true) {
      if (Cookie.get(`coupons_${this.openId}-${this.userProfile.id}`)) {
        const coupons = JSON.parse(
          Cookie.get(`coupons_${this.openId}-${this.userProfile.id}`)
        )
        this.cart.delivery_fee_voucher_no =
          coupons.delivery_fee_voucher_no || ''
        this.cart.voucher_no = coupons.voucher_no || ''
        this.cookie_selected_suborders = coupons.selected_suborders || []
        console.log('cookie_selected_suborders', this.cookie_selected_suborders)
      }

      this.isLoadingCart = true
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('voucher_no', this.cart.voucher_no || '')
      params.append(
        'delivery_fee_voucher_no',
        this.cart.delivery_fee_voucher_no || ''
      )
      if (this.cookie_selected_suborders.length)
        this.cookie_selected_suborders.forEach((e) => {
          params.append('selected_suborders', e)
        })

      const result = await this.$shoppingCartProvider.getCalculateCart(
        `${this.cartId}-${this.userProfile.id}`,
        params
        // this.cartId,
        // this.userProfile.id,
        // this.profileType,
        // Object.keys(this.userCostcenter).length ? this.userCostcenter.id : '',
        // this.$utils.isValid(this.cart.voucher_no) ? this.cart.voucher_no : '',
        // this.$utils.isValid(this.cart.delivery_fee_voucher_no)
        //   ? this.cart.delivery_fee_voucher_no
        //   : '',
        // showLoading
      )
      this.items = []
      console.log('result', result)
      if (result.status === 200) {
        this.couponsType = ['Platform', 'NewCustomer', 'DeliveryDiscount']
        // this.promotion_discounts = result.data.promotion_discounts
        // console.log('promotion_discounts', result.data.promotion_discounts)
        // console.log('result.data', result.data)
        this.warningVoucher = {}
        this.warningVoucherDelivery = {}
        this.tempCouponVouchers = ''
        this.tempCouponDelivery = ''
        this.warningMessages = []
        this.is_use_coupon = result.data.is_use_coupon
        this.is_use_coupon_delivery = result.data.is_use_coupon_delivery
        this.used_voucher_type = result.data.voucher_type // used_voucher_type
        // responsive conpon code layout
        if (
          this.is_use_coupon === false &&
          this.is_use_coupon_delivery === false
        ) {
          this.isCouponCollapsed = false
        }

        this.showErrCouponVoucher =
          this.$utils.isValid(this.cart.voucher_no) && !this.is_use_coupon
            ? true
            : false
        this.showErrCouponDelivery =
          this.$utils.isValid(this.cart.delivery_fee_voucher_no) &&
          !this.is_use_coupon_delivery
            ? true
            : false
        if (result.data.summary !== null) this.summary = result.data.summary
        // v1
        // if (result.data.items !== null && result.data.items.length) {
        //   this.productOverStockList = result.data.items.filter(
        //     (q) => q.is_over_stock
        //   )
        // }
        if (this.profileType === 'eprocurement')
          this.companyControlType = result.data.company_control_type
        this.productGroups = []
        this.noItems = []
        if (
          result.data.warning_messages !== null &&
          result.data.warning_messages.length
        ) {
          this.warningVoucher = result.data.warning_messages.find(
            (q) => q.key === 'use_coupon'
          )
          this.warningVoucherDelivery = result.data.warning_messages.find(
            (q) => q.key === 'use_coupon_delivery'
          )
          this.warningMessages = result.data.warning_messages.filter(
            (q) => q.key !== 'use_coupon'
          )
        }
        if (this.showErrCouponVoucher) {
          this.tempCouponVouchers = `${this.cart.voucher_no}`

          // Cookie.set(
          //   `coupons_${this.openId}-${this.userProfile.id}`,
          //   {
          //     voucher_no: '',
          //     delivery_fee_voucher_no: '',
          //   },
          //   {
          //     expires: 1,
          //     secure: true,
          //     sameSite: 'none',
          //     // domain: 'localhost',
          //   }
          // )
          // this.cart.voucher_no = ''
          // this.cart.delivery_fee_voucher_no = ''
        }
        if (this.showErrCouponDelivery) {
          this.tempCouponDelivery = `${this.cart.delivery_fee_voucher_no}`
        }

        // v1
        // if (result.data.items !== null && result.data.items.length) {
        //   this.items = result.data.items.filter(
        //     (q) => q.is_wrong_condition === false && !q.is_free
        //   )
        //   let filterItems = result.data.items.filter(
        //     (q) => q.is_wrong_condition === false
        //   )
        //   let groupVendorType = filterItems.reduce((h, value) => {
        //     return Object.assign(h, {
        //       [value.vendor.vendor_type]: (
        //         h[value.vendor.vendor_type] || []
        //       ).concat(value),
        //     })
        //   }, {})
        //   console.log('groupVendorType', groupVendorType)
        //   let groupProduct = {}
        //   Object.keys(groupVendorType).forEach((groupKey) => {
        //     if (groupKey === 'OFMStock')
        //       groupProduct[groupKey] = groupVendorType[groupKey]
        //     else {
        //       //DropShip,Marketplace
        //       let subGroup = groupVendorType[groupKey].reduce((h, value) => {
        //         return Object.assign(h, {
        //           [value.vendor.code]: (h[value.vendor.code] || []).concat(
        //             value
        //           ),
        //         })
        //       }, {})
        //       // console.log(`${groupKey}`, subGroup)
        //       Object.keys(subGroup).forEach((subgroupKey) => {
        //         groupProduct[`${groupKey}_${subgroupKey}`] =
        //           subGroup[subgroupKey]
        //         return groupProduct[`${groupKey}_${subgroupKey}`]
        //       })
        //     }
        //   })
        //   console.log('groupProduct', groupProduct)

        //   Object.keys(groupProduct).forEach((groupKey) => {
        //     return this.productGroups.push({
        //       soldBy:
        //         groupKey === 'OFMStock' || groupKey.includes('DropShip')
        //           ? 'OfficeMate'
        //           : groupProduct[groupKey][0].vendor.display_name[
        //               this.$i18n.locale
        //             ],
        //       FulfilledBy:
        //         groupKey === 'OFMStock'
        //           ? 'OfficeMate'
        //           : groupProduct[groupKey][0].vendor.display_name[
        //               this.$i18n.locale
        //             ],
        //       items: groupProduct[groupKey],
        //       delivery_lead_time: '',
        //       premiums: [],
        //       premiums_non_stock: [],
        //       // shipping
        //       shippingOption: [
        //         {
        //           type: 'OFFICEMATE SHIPPING',
        //           description:
        //             'Delivery to your preferred address in 2-5 working days',
        //           cost: 0,
        //         },
        //         {
        //           type: 'BANGKOK SAMEDAY',
        //           description:
        //             'Delivery to your preferred address in 2-5 working days',
        //           cost: 80,
        //         },
        //         {
        //           type: 'EXPRESS DELEVERY',
        //           description:
        //             'Delivery to your preferred address in 2-5 working days',
        //           cost: 200,
        //         },
        //       ],
        //     })
        //   })
        //   this.productGroups.map((group) => {
        //     console.log('group', group)
        //     group.premiums = group.items.filter(
        //       (q) => q.is_free && q.onhand > q.reserve
        //     )
        //     group.premiums_non_stock = group.items.filter(
        //       (q) => q.is_free && q.onhand <= q.reserve
        //     )
        //     group.items = group.items.filter((q) => !q.is_free)
        //     group.items.map((item) => {
        //       console.log('item', item)
        //       item.product_name = item.product_names.find(
        //         (q) => q.type === 'Online'
        //       ).display_name
        //     })
        //     if (group.premiums.length) {
        //       group.premiums.map((item) => {
        //         item.product_name = item.product_names.find(
        //           (q) => q.type === 'Online'
        //         ).display_name
        //       })
        //     }
        //     if (group.premiums_non_stock.length) {
        //       group.premiums_non_stock.map((item) => {
        //         item.product_name = item.product_names.find(
        //           (q) => q.type === 'Online'
        //         ).display_name
        //       })
        //     }
        //     if (group.items.length) {
        //       const maxLeadTime = group.items.reduce((prev, current) => {
        //         return prev.delivery_lead_time > current.delivery_lead_time
        //           ? prev
        //           : current
        //       })
        //       // console.log('maxLeadTime', maxLeadTime)
        //       group.delivery_lead_time = this.$utils.formatDateExcludeTime(
        //         maxLeadTime.delivery_lead_time
        //       )
        //     }
        //   })
        //   this.noItems = result.data.items.filter(
        //     (q) => q.is_wrong_condition === true
        //   )
        //   console.log('this.noItems : ', this.noItems)
        //   if (this.noItems.length) {
        //     this.noItems.map((item) => {
        //       item.product_name = item.product_names.find(
        //         (q) => q.type === 'Online'
        //       ).display_name
        //     })
        //   }
        // }
        // v2
        if (result.data.sub_orders.length) {
          let promotion_items = result.data.sub_orders.flatMap((subOrder) =>
            subOrder.promotion_discounts.flatMap((promo) => promo.items)
          )

          this.items = result.data.sub_orders
            .flatMap((subOrder) => [
              ...subOrder.items,
              ...subOrder.premium_items,
            ])
            .concat(promotion_items)

          this.productGroups = [...result.data.sub_orders]
          console.log('productGroups', this.productGroups)
        }
        if (result.data?.unsellable_items.length) {
          this.noItems = [...result.data.unsellable_items]
        }
        // create coupon cookies
        // this.cookie_selected_suborders.forEach({

        // })
        let vendorCodes = new Set(
          this.productGroups.map((item) => item.vendor.code.toString())
        )
        // console.log('use_sub_coupon', this.cookie_selected_suborders)
        this.cookie_selected_suborders = this.cookie_selected_suborders.filter(
          (item) => {
            let prefix = item.split('-')[1]
            return vendorCodes.has(prefix)
          }
        )
        // remove error coupon
        // const filteredCoupon = this.productGroups.filter(e =>
        //     e.seller_voucher_no === seller_voucher_no_to_find && e.vendor.code === vendor_to_find
        // );

        if (showLoading) {
          console.log('coupons selected', this.productGroups)
          let tempCoupon = []
          this.productGroups.forEach((e) => {
            if (e.seller_voucher_no)
              //  && e.is_use_coupon_seller
              tempCoupon.push(
                `${e.vendor?.vendor_type}-${e.vendor?.code}-${e.seller_voucher_no}`
              )
          })
          const filteredCodes = this.cookie_selected_suborders.filter((code) =>
            tempCoupon.includes(code)
          )

          console.log('coupons selected', filteredCodes)
          // Cookie.remove(`coupons_${this.openId}-${this.userProfile.id}`)
          Cookie.set(
            `coupons_${this.openId}-${this.userProfile.id}`,
            {
              voucher_no: this.cart.voucher_no,
              delivery_fee_voucher_no: this.cart.delivery_fee_voucher_no,
              // selected_suborders: this.cookie_selected_suborders,
              selected_suborders: filteredCodes,
            },
            {
              expires: 1,
              secure: true,
              sameSite: 'none',
              // domain: 'localhost',
            }
          )
        }
        // remove unused coupon

        // gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'checkout',
          basketTotal: this.$utils.formatPrice(
            this.summary.net_amt + this.summary.vat_amt,
            2,
            true
          ),
          ecommerce: {
            checkout: {
              actionField: { step: 1 },
              products: this.items
                ? this.items.map((item) => {
                    console.log('log item', item)
                    let catLv1 = ''
                    let catLv2 = ''
                    let catLv3 = ''
                    let catIdLv1 = ''
                    let catIdLv2 = ''
                    let catIdLv3 = ''
                    if (this.$utils.isObjectKeyValid(item, 'categories')) {
                      if (item.categories.find((key) => key.level === 1)) {
                        catLv1 = item.categories.find((key) => key.level === 1)
                          .display_name['en']
                        catIdLv1 = item.categories.find(
                          (key) => key.level === 1
                        ).id
                      }
                      if (item.categories.find((key) => key.level === 2)) {
                        catLv2 = item.categories.find((key) => key.level === 2)
                          .display_name['en']
                        catIdLv2 = item.categories.find(
                          (key) => key.level === 2
                        ).id
                      }
                      if (item.categories.find((key) => key.level === 3)) {
                        catLv3 = item.categories.find((key) => key.level === 3)
                          .display_name['en']
                        catIdLv3 = item.categories.find(
                          (key) => key.level === 3
                        ).id
                      }
                    }
                    return {
                      id: item.sku_code,
                      price: this.$utils.formatPrice(
                        item.price_include_vat,
                        2,
                        true
                      ),
                      categoryLv1: catLv1,
                      categoryLv2: catLv2,
                      categoryLv3: catLv3,
                      categoryIdLv1: catIdLv1,
                      categoryIdLv2: catIdLv2,
                      categoryIdLv3: catIdLv3,
                      quantity: item.qty,
                    }
                  })
                : [],
            },
          },
        })

        // net_amt: 0,
        // net_deliveryfee_excvat: 0,
        // nonvat_prod_net_amt: 0,
        // vat_amt: 0,
        // vat_prod_net_amt: 0,
        // voucher_disc_amt_excvat: 0,
        // payment_disc_amt_excvat: 0,
        // payment_disc_code: '',
        // gtm GA4

        console.log(this.summary)
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_cart',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            currency: 'THB',
            value: this.summary.net_amt + this.summary.vat_amt,
            coupon_discount: this.summary.voucher_disc_amt_excvat,
            coupon: this.cart.voucher_no,
            total_price_no_tax: this.summary.nonvat_prod_net_amt,
            total_price_before_tax: this.summary.vat_prod_net_amt,
            shipping: this.summary.net_deliveryfee_excvat || 0.0,
            tax: this.summary.vat_amt,
            total_discount: this.summary.other_disc_amt_incvat,
            items: this.items.map((item, index) => {
              return {
                item_id: item.sku_code,
                item_name: item.product_names[1]?.display_name.th,
                index: index,
                item_brand: item.brand?.display_name.en,
                item_category: item.categories[2]?.display_name.en,
                item_category2: item.categories[1]?.display_name.en,
                item_category3: item.categories[0]?.display_name.en,
                price: item.price_include_vat,
                discount:
                  item.retail_price.include_vat - item.price_include_vat,
                quantity: item.qty,
                ...this.$store.getters['user/userPromotionTrack'],
                item_list_id:
                  (item.is_flash_sale && 'flash_sale') ||
                  (item.is_promotion && 'promotions') ||
                  '',
                item_list_name:
                  (item.is_flash_sale && 'flash sale') ||
                  (item.is_promotion && 'Promotions') ||
                  '',
              }
            }),
          },
        })

        await this.getUserFavorites()

        // multi coupons
        // คูปอง Global (คูปองที่ท้ายบิล)

        // คูปองแพลตฟอร์ม (สีส้ม)
        // เงื่อนไขเชค คือ use_type = 'Global' และ product_vendor_type = 'All'

        // คูปองลูกค้าใหม่(สีฟ้า)
        // เงื่อนไขเชค คือ  use_type = 'Global' และ product_vendor_type = 'All'  และ new_customer = True

        // คูปองค่าจัดส่ง (สีเขียว)
        // เงื่อนไขเชค คือ  use_type = 'Global' และ product_vendor_type = 'All' , 'OFMStock', 'Dropship'
      } else if (result.status === 404) {
        // console.log(result.data)
        this.productGroups = []
        this.noItems = []
      } else {
        Cookie.set(
          `coupons_${this.openId}-${this.userProfile.id}`,
          {
            voucher_no: '',
            delivery_fee_voucher_no: '',
            selected_suborders: [],
          },
          {
            expires: 1,
            secure: true,
            sameSite: 'none',
            // domain: 'localhost',
          }
        )
      }
      this.$nextTick(() => {
        this.isLoadingCart = false
      })
    },
    uploadCSV() {
      this.$refs['upload-csv'].click()
    },
    async selected({ target: { files } }) {
      console.log('files', files)
      if (files.length) {
        await this.$refs['input-file']
          .validate(files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.valid) {
              console.log('valid')
              console.log('file', files[0])
              this.importFile(files[0])
            } else console.log('not valid')
          })
      }
    },
    async importFile(file) {
      let skuList = []
      let fileName = file.name.split('.')
      console.log('fileName[1]', fileName[1])
      if (fileName[1] === 'csv') {
        await this.$utils.importCSV(file).then(
          async (result) => {
            console.log('result ', result)
            if (result.length) {
              result.forEach((item) => {
                if (this.$utils.isValid(item['product code'])) {
                  let quantity = parseInt(item.quantity)
                  skuList.push({
                    sku: item['product code'],
                    qty: !isNaN(quantity) ? quantity : 1,
                  })
                }
              })
              // console.log('skuList', skuList)
              await this.addCart('import', skuList)
            }
          },
          (error) => {
            console.log('error', error)
          }
        )
      } else if (fileName[1] === 'xlsx' || fileName[1] === 'xls') {
        const result = await this.$utils.importExcel(file)
        // console.log('import result', result)
        result.body.forEach((item) => {
          // console.log('item', item)
          skuList.push({
            sku: item.productcode,
            qty: item.quantity,
          })
        })
        // console.log('skuList', skuList)
        await this.addCart('import', skuList)
      }
      this.$refs['upload-csv'].value = null
    },
    async validateAddCart() {
      this.$refs['input-sku'].validate().then(async (result) => {
        console.log('validate result', result)
        if (result.valid) await this.addCart('single')
      })
    },
    async addCart(type, skuList) {
      this.isShowLimitWarning = false
      this.addProductWarningMessages = []
      let parseData = this.userProfile
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: parseData.id,
        account_channel: this.profileType,
        skus: [],
      })
      apiData = JSON.parse(apiData)
      if (type === 'import') {
        apiData.skus = skuList
      } else {
        apiData.skus.push({
          sku: this.input.sku,
          qty: 1,
        })
      }
      apiData = JSON.stringify(apiData)
      // console.log('apiData', apiData)
      const result = await this.$shoppingCartProvider.addCart(apiData)
      console.log('shoppingCartProvider', result.data)
      if (result.status === 201) {
        if (result.data.warning_messages.length) {
          this.addProductWarningMessages = result.data.warning_messages

          if (
            result.data.warning_messages.length &&
            result.data.warning_messages.filter(
              (item) => item.key === 'is_sku_limit'
            ).length
          ) {
            const sku_limit = result.data.warning_messages.filter(
              (item) => item.key === 'is_sku_limit'
            )
            type === 'single'
              ? this.$nuxt.$emit('addToCart', {
                  message: {
                    title: sku_limit[0].message_title,
                    description: sku_limit[0].message_description,
                  },
                  isWarning: true,
                })
              : (this.isShowLimitWarning = true)
          }
        }
        if (type === 'single') {
          this.input.sku = ''
          this.$nextTick(() => this.$refs['input-sku'].reset())
        }
        // gtm
        if (result.data.success_items.length) {
          this.showSuccess = true
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'addToCart',
            ecommerce: {
              add: {
                products: result.data.success_items.length
                  ? result.data.success_items.map((item) => {
                      let catLv1 = ''
                      let catLv2 = ''
                      let catLv3 = ''
                      let catIdLv1 = ''
                      let catIdLv2 = ''
                      let catIdLv3 = ''
                      if (this.$utils.isObjectKeyValid(item, 'categories')) {
                        if (item.categories.find((key) => key.level === 1)) {
                          catLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).display_name['en']
                          catIdLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 2)) {
                          catLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).display_name['en']
                          catIdLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 3)) {
                          catLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).display_name['en']
                          catIdLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).id
                        }
                      }
                      return {
                        id: item.sku_code,
                        price: this.$utils.formatPrice(
                          item.price_include_vat,
                          2,
                          true
                        ),
                        categoryLv1: catLv1,
                        categoryLv2: catLv2,
                        categoryLv3: catLv3,
                        categoryIdLv1: catIdLv1,
                        categoryIdLv2: catIdLv2,
                        categoryIdLv3: catIdLv3,
                        quantity: item.qty,
                      }
                    })
                  : [],
              },
            },
          })

          // gtm GA4
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'add_to_cart',
            ecommerce: {
              // user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              item_list_id: '',
              item_list_name: '',
              items: result.data.success_items.map((item) => {
                return {
                  item_id: item.sku_code,
                  item_name: '',
                  ...this.$utils.productCategories(item.categories),
                  quantity: item.qty,
                  price: item.price_include_vat,
                  ...this.$store.getters['user/userPromotionTrack'],
                  item_list_id:
                    (item.is_flash_sale && 'flash_sale') ||
                    (item.is_promotion && 'promotions') ||
                    '',
                  item_list_name:
                    (item.is_flash_sale && 'flash sale') ||
                    (item.is_promotion && 'Promotions') ||
                    '',
                }
              }),
            },
          })
        }

        this.reloadPage()
        await this.getCartCount()
      }
    },
    async getCartCount() {
      const cartResponsed = await this.$shoppingCartProvider.getCartCount(
        this.cartId,
        this.userProfile.id
      )
      if (cartResponsed.status === 200) this.setCartCount(cartResponsed.data)
    },
    async onChangeQty(item) {
      console.log('item', item)
      let stockAvar = item.onhand - item.reserve
      if (item.is_available_stock && parseInt(item.qty) > stockAvar)
        item.qty = stockAvar
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        sku: item.sku_code,
        qty: parseInt(item.qty),
      })
      const result = await this.$shoppingCartProvider.updateCartItemQty(apiData)
      if (result.status === 204) {
        await this.reloadPage()
        await this.getCartCount()
      }
    },
    async onChangeSelectedCheckout(event, skuList) {
      console.log('event', event)
      console.log('skuList', skuList)
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        skus: skuList,
        is_selected: event,
      })
      const result = await this.$shoppingCartProvider.updateCheckoutCartItem(
        apiData
      )
      if (result.status === 204) {
        await this.reloadPage()
      }
    },
    async onRemoveItem(item) {
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        sku: item.sku_code,
        qty: parseInt(item.qty),
      })
      const result = await this.$shoppingCartProvider.removeCartItemQty(apiData)
      if (result.status === 200) {
        this.addProductWarningMessages = []
        this.isShowLimitWarning = false
        this.handleScroll()
        // gtm
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  id: item.sku_code,
                  price: this.$utils.formatPrice(
                    item.display_price_include_vat,
                    2,
                    true
                  ),
                },
              ],
            },
          },
        })

        // gtm GA4
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'remove_from_cart',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            currency: 'THB',
            value: item.price_include_vat,
            items: [
              {
                item_id: item.sku_code,
                item_name: item.product_names[1]?.display_name.th,
                item_brand: item.brand?.display_name.en,
                item_category: item.categories[2]?.display_name.en,
                item_category2: item.categories[1]?.display_name.en,
                item_category3: item.categories[0]?.display_name.en,
                price: item.price_include_vat,
                discount:
                  item.retail_price.include_vat - item.price_include_vat,
                ...this.$store.getters['user/userPromotionTrack'],
              },
            ],
          },
        })
      }
      await this.reloadPage()
      await this.getCartCount()
    },
    async onCheckCoupon() {
      // if (this.$utils.isValid(this.cart.voucher_no))
      await this.getCalculateCart()
    },
    async onRemoveVoucher(type) {
      if (type === 'voucher_no') {
        this.cart.voucher_no = ''
        this.is_use_coupon = false
      } else if (type === 'delivery_fee_voucher_no') {
        this.cart.delivery_fee_voucher_no = ''
        this.is_use_coupon_delivery = false
      }
      // this.voucher_no = this.cart.voucher_no
      // this.cart.delivery_fee_voucher_no
      // if (
      //   this.cart.voucher_no === '' &&
      //   this.cart.delivery_fee_voucher_no === ''
      // )
      //   this.is_use_coupon = false
      this.$root.$emit('bv::collapse::state', ('coupon-collapse', false))
      Cookie.set(
        `coupons_${this.openId}-${this.userProfile.id}`,
        {
          voucher_no: this.cart.voucher_no,
          delivery_fee_voucher_no: this.cart.delivery_fee_voucher_no,
          selected_suborders: this.cookie_selected_suborders,
        },
        {
          expires: 1,
          secure: true,
          sameSite: 'none',
          // domain: 'localhost',
        }
      )
      // if (
      //   this.is_use_coupon === false &&
      //   this.is_use_coupon_delivery === false
      // ) {
      //   this.isCouponCollapsed = false
      // }
      await this.getCalculateCart()
    },
    onCheckOutItem(items) {
      this.getItems = false
      items.forEach((e) => {
        console.log(e.productId + ' ' + e.qty)
      })
    },
    onCheckOutCart() {
      if (
        !(
          this.checkoutWarningMessages.length ||
          this.hasWrongConditionLimitMinMaxQty ||
          this.hasWrongConditionOverStock ||
          this.isover_goodsreceive_period ||
          !this.hasCheckoutItem
        )
      ) {
        // if (this.$utils.isValid(this.cart.voucher_no) && !this.is_use_coupon) {
        //   this.cart.voucher_no = ''
        // }
        // if (
        //   this.$utils.isValid(this.cart.delivery_fee_voucher_no) &&
        //   !this.is_use_coupon_delivery
        // ) {
        //   this.cart.delivery_fee_voucher_no = ''
        // }
        // gtm GA4
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'begin_checkout',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            currency: 'THB',
            value: this.summary.net_amt + this.summary.vat_amt,
            coupon: this.cart.voucher_no,
            items: this.items
              .filter((item) => item.is_selected)
              .map((item, index) => {
                return {
                  item_id: item.sku_code,
                  item_name: item.product_names[1]?.display_name.th,
                  index: index,
                  item_brand: item.brand?.display_name.en,
                  item_category: item.categories[2]?.display_name.en,
                  item_category2: item.categories[1]?.display_name.en,
                  item_category3: item.categories[0]?.display_name.en,
                  price: item.price_include_vat,
                  discount:
                    item.retail_price.include_vat - item.price_include_vat,
                  quantity: item.qty,
                  ...this.$store.getters['user/userPromotionTrack'],
                  item_list_id:
                    (item.is_flash_sale && 'flash_sale') ||
                    (item.is_promotion && 'promotions') ||
                    '',
                  item_list_name:
                    (item.is_flash_sale && 'flash sale') ||
                    (item.is_promotion && 'Promotions') ||
                    '',
                }
              }),
          },
        })

        if (this.profileType === 'standard') {
          if (!this.userProfile.is_activate)
            this.$router.push({
              path: this.$i18n.path(
                'account/new-register/check-tax?callback=checkout/shipping'
              ),
            })
          else this.createOrderTemporary(this.$i18n.path('checkout/shipping'))
        } else this.createOrderTemporary(this.$i18n.path('checkout/shipping'))
      }
    },
    async createOrderTemporary(redirectPath) {
      console.log('checkout productGroups', this.productGroups)
      let selected_suborders = []
      this.productGroups.forEach((e) => {
        if (e.seller_voucher_no)
          selected_suborders.push({
            vendor_type: e.vendor?.vendor_type, // <optional><options: "OFMStock", "DropShip">
            vendor_code: e.vendor?.code,
            seller_voucher_no: e.seller_voucher_no,
          })
      })
      const sessionShopback = JSON.parse(
        sessionStorage.getItem('sessionShopback')
      )
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        reqesterline_id:
          this.profileType === 'eprocurement' ? this.requesterLineId : '',
        companies_budget_control_id:
          this.profileType === 'eprocurement' ? this.budget.id : '',
        // voucher_no: this.cart.voucher_no,
        is_use_budget_control:
          this.profileType === 'eprocurement'
            ? this.userProfile.is_use_budget_control
            : false,
        t1c_no: this.t1cCardNo,
        affiliate_source: sessionShopback
          ? sessionShopback.affiliate_source
          : '',
        affiliate_transaction_id: sessionShopback
          ? sessionShopback.affiliate_transaction_id
          : '',
        // multi coupon
        voucher_no: this.cart.voucher_no,
        delivery_fee_voucher_no: this.cart.delivery_fee_voucher_no,
        selected_suborders: selected_suborders,
      })
      const result = await this.$shoppingCartProvider.createOrderTemporary(
        apiData
      )
      if (result.status === 201) {
        if (
          result.data.warning_messages.length != null &&
          result.data.warning_messages.length
        ) {
          if (
            result.data.warning_messages.find(
              (q) => q.key === 'closed_site'
            ) !== undefined
          )
            this.checkoutWarningMessages.push('closed_site')
        } else {
          this.$router.push({
            path: redirectPath,
            name:
              this.$i18n.locale === 'th'
                ? 'checkout-shipping-tempId'
                : 'lang-checkout-shipping-tempId',
            params: { tempId: result.data.temp_id },
          })
        }
      }
    },
    handleScroll() {
      if (this.$refs.scrollWrapper !== undefined) {
        this.fixedPosition = false
        this.absoluteBottom = false
        const checkDiffHeight =
          document.body.clientHeight >
          this.$refs.scrollWrapper.clientHeight + 206
        const bredcrumbHeight =
          document.querySelectorAll('ol.breadcrumb')[0].clientHeight
        const fromTop =
          this.$refs['container-cart'].offsetTop - (bredcrumbHeight + 16)

        if (this.productGroups.length > 0 && checkDiffHeight) {
          if (
            this.$refs.scrollWrapper.clientHeight + 40 <
            this.$refs.contentWrapper.clientHeight
          ) {
            // if (window.scrollY > 80) {
            //   this.fixedPosition = true
            // } else {
            //   this.fixedPosition = false
            // }
            this.fixedPosition = window.scrollY > fromTop

            this.absoluteBottom =
              window.scrollY +
                (this.$refs.scrollWrapper.clientHeight +
                  (bredcrumbHeight - 16)) >=
              this.$refs.contentWrapper.clientHeight + fromTop
            // if (
            //   window.scrollY + (this.$refs.scrollWrapper.clientHeight - 37) >=
            //   this.$refs.contentWrapper.clientHeight
            // ) {
            //   this.absoluteBottom = true
            // } else {
            //   this.absoluteBottom = false
            // }
          }
        }
      }
    },
    async getUserProductHistory() {
      console.log('getUserProductHistory')
      let params = new URLSearchParams()
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('status', 'Active')
      const result = await this.$productProvider.getUserProductHistory(
        this.cartId,
        this.userProfile.id,
        params
      )

      console.log('user product history', result.data)
      if (result.status === 200 && result.data.length) {
        // this.items = responsed.data.items
        this.itemsRecommended = []
        result.data.forEach((item, index) => {
          item.product_name = item.product_names.find(
            (q) => q.type === 'Online'
          ).display_name
          item.seq = index + 1
          this.itemsRecommended.push(item)
          // this.itemsRecommended.push({
          //   index: index,
          //   productId: item.sku_code,
          //   productName: item.product_name[this.$i18n.locale],
          //   productImg: `https://biblio-cdn.officemate.co.th/products/${item.sku_code}/tpimage`,
          //   productPrice: item.display_price_include_vat,
          //   productDiscount: item.discount_rate,
          //   subCatagories: 0,
          // })
        })

        // overlay promotion
        if (this.itemsRecommended.length) {
          let pid = this.itemsRecommended.map((item) => {
            return item.sku_code
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          if (graphqlResponse) {
            this.overlayImagesItemsRecommended = graphqlResponse
          }
        }

        // gtm
        if (this.itemsRecommended.length) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.itemsRecommended.map((item) => {
                return {
                  id: item.sku_code,
                  price: this.$utils.formatPrice(
                    item.price_include_vat,
                    2,
                    true
                  ),
                  list: 'Recently Viewed Products',
                  position: item.seq,
                }
              }),
            },
          })
        }
      } else this.itemsRecommended = []
      this.$nextTick(() => {
        this.isLoadingRecentProduct = false
      })
    },
    async getUserFavorites() {
      let params = new URLSearchParams()
      params.append(
        'company_id',
        this.profileType === 'standard' ? '' : this.userProfile.id
      )
      params.append('user_open_id', this.openId)
      params.append('account_channel', this.profileType)
      const result = await this.$productProvider.getCatalogsDataById(
        params,
        false
      )
      console.log('catalog result', result)
      if (result.status === 200) {
        if (result.data.length) {
          this.favoriteList = result.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              default: item.is_default,
              items: item.items,
            }
          })
        } else this.favoriteList = []
      } else this.favoriteList = []
    },
    async onAddItemToFavorite(objData) {
      console.log('onAddItemToFavorite', objData)
      let quantity =
        this.$utils.isObjectValid(objData) &&
        this.$utils.isValid(objData.quantity)
          ? objData.quantity
          : 1
      let apiData = JSON.stringify([
        {
          sku_code: objData.sku_code,
          quantity: parseInt(quantity),
        },
      ])
      const result = await this.$productProvider.createProductCatalogsData(
        objData.id,
        apiData
      )
      if (result.status === 201) {
        this.getUserFavorites()

        // gtm GA4
        const item = this.items.find((item) => item.id === objData.sku_code)
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'add_to_wishlist',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            currency: 'THB',
            value: item.price_include_vat,
            items: [
              {
                item_id: item.sku_code,
                item_name: item.product_names[1]?.display_name.th,
                item_brand: item.brand?.display_name.en,
                item_category: item.categories[2]?.display_name.en,
                item_category2: item.categories[1]?.display_name.en,
                item_category3: item.categories[0]?.display_name.en,
                price: item.price_include_vat,
                discount:
                  item.retail_price.include_vat - item.price_include_vat,
                ...this.$store.getters['user/userPromotionTrack'],
              },
            ],
          },
        })
      }
    },
    async onCreateNewFavorite(name) {
      if (this.$utils.isValid(name)) {
        this.isResetName = false
        let apiData = JSON.stringify({
          user_open_id: this.openId,
          account_channel: this.profileType,
          company_id:
            this.profileType === 'eprocurement' ? this.userProfile.id : null, // this.companyId
          account_id:
            this.profileType === 'standard' ? this.userProfile.id : null,
          name: name,
        })
        const result = await this.$productProvider.createCatalogData(apiData)
        if (result.status === 201) {
          this.getUserFavorites()
          this.isResetName = true
        }
      }
    },
    downloadExampleFile() {
      let element = document.createElement('a')
      element.setAttribute(
        'href',
        'https://ofm-cdn0.ofm.co.th/documents/example-products.csv'
      )
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    async checkOverGoodsReceivePeroid() {
      console.log('GR', this.userProfile)
      if (
        this.profileType === 'eprocurement' &&
        this.userProfile.is_use_goods_receive
      ) {
        const result = await this.$orderProvider.checkGoodsReceivePeroid(
          this.userProfile.id
        )
        console.log('order_goods_receive', result)
        if (result.status === 200) {
          this.isover_goodsreceive_period =
            result.data.isover_goodsreceive_period
        }
      }
    },
    checkAccountActivate() {
      if (this.userProfile.is_activate) {
        this.$router.push({ path: this.$i18n.path('cart') })
      }
    },
    onSetCookieShift() {
      setTimeout(() => {
        // console.log('ref', this.$refs['ref-product-summary'].clientHeight)
        let height = this.$refs['cart-checkout']
          ? this.$refs['cart-checkout'].clientHeight
          : 0
        this.$nuxt.$emit('cookieShift', {
          isShift: true,
          height: height,
        })
      }, 50)
    },
    async onSubmitCoupon(payload) {
      const { voucher_no, delivery_fee_voucher_no, isSellerCoupon, seller } =
        payload
      if (!isSellerCoupon) {
        this.cart.voucher_no = voucher_no
        // this.voucher_no = voucher_no
        this.cart.delivery_fee_voucher_no = delivery_fee_voucher_no
      } else {
        let selected_suborders = `${seller?.vendor_type}-${seller?.code}-${voucher_no}`
        if (!this.cookie_selected_suborders.includes(selected_suborders)) {
          this.cookie_selected_suborders.push(selected_suborders)
        }

        // console.log(`${seller?.vendor_type}-${seller?.code}-${voucher_no}`)
        // this.selected_suborders =
        // this.selectedSubOrderCoupon.push(payload)
      }
      Cookie.set(
        `coupons_${this.openId}-${this.userProfile.id}`,
        {
          voucher_no: this.cart.voucher_no,
          delivery_fee_voucher_no: this.cart.delivery_fee_voucher_no,
          selected_suborders: this.cookie_selected_suborders,
        },
        {
          expires: 1,
          secure: true,
          sameSite: 'none',
          // domain: 'localhost',
        }
      )

      await this.onCheckCoupon()
    },
    async onRemoveSubVoucher(seller) {
      let selected_suborders = `${seller.vendor?.vendor_type}-${seller.vendor?.code}-${seller.seller_voucher_no}`

      this.cookie_selected_suborders = this.cookie_selected_suborders.filter(
        (item) => item !== selected_suborders
      )
      Cookie.set(
        `coupons_${this.openId}-${this.userProfile.id}`,
        {
          voucher_no: this.cart.voucher_no,
          delivery_fee_voucher_no: this.cart.delivery_fee_voucher_no,
          selected_suborders: this.cookie_selected_suborders,
        },
        {
          expires: 1,
          secure: true,
          sameSite: 'none',
          // domain: 'localhost',
        }
      )
      await this.onCheckCoupon()
    },
    // onSubOrderCoupon(subOrderCoupon) {
    //   console.log('subOrderCoupon', subOrderCoupon)
    // },
    onModalCoupon(e) {
      this.isModalCoupon = true
      this.modalData = e
    },
  },
}
