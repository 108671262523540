
import { mapGetters } from 'vuex'

export default {
  name: 'ShopID',
  // layout: 'SharedLayout',
  components: {
    SwiperItemSkeleton: async () =>
      await import('@/components/skeleton/swiper-skeleton-item.vue'),
    // componentBannerSwiper: async () =>
    //   await import('@/components/shop-in-shop/display/banner-swiper.vue'),
    componentBrandsSwiper: async () =>
      await import('@/components/shop-in-shop/display/brands-swiper.vue'),
    componentCouponSwiper: async () =>
      await import('@/components/shop-in-shop/display/coupon-swiper.vue'),
  },
  async asyncData({ store }) {
    const theme = 'shop-type-a'
    return { theme }
  },
  data() {
    return {
      bannerBrands: [],
      brand_official_shop_banner: [],
      brandsItems: [],
      ofmStockItems: [],
      dropShipItems: [],
      coupons: [],
      couponsSeller: [],
      ofm_mall_banner: [],
      isFlashSale: true,
      isSkeletonPage: true,
      // alphabet
      alphabets: [],
      isAlphabet: true,
      activeRef: 0,
      fixedPosition: false,
      alphabetOptions: {
        navigation: {
          nextEl: '#alphabet-next',
          prevEl: '#alphabet-prev',
        },
        slidesPerView: 6,
        slidesPerGroup: 6,
        observeParents: true,
        on: {
          resize: () => {
            this.$nextTick(() => {
              this.$refs['alphabet-swiper'].$swiper.update()
            })
          },
        },
        breakpoints: {
          768: {
            slidesPerView: 12,
            slidesPerGroup: 12,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 18,
            slidesPerGroup: 18,
            spaceBetween: 0,
          },
          1280: {
            slidesPerView: 24,
            slidesPerGroup: 24,
            spaceBetween: 0,
          },
        },
      },
      activeAlphabet: '',
      isLoading: true,
      isLoadRefBrand: true,
      stateLoad: [],
      // coupon detail
      coupon_code: '',
      couponDetail: '',
      promotionNo: '',
      product_include_flag: '',
      isShowCouponDetail: false,
      brandsCouponItems: [],
      sellerCouponItems: [],
    }
  },
  async created() {
    await this.onGetBrandsHighlight('OFMStock')
    await this.onGetShopHighlight(true, 'DropShip')
    await this.getฺBannerHome()
    await this.getBrandsHome()
    this.isSkeletonPage = false
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    filterBrands() {
      return this.alphabets.find((item) => item.title === this.activeAlphabet)
    },
  },
  async mounted() {
    // Add scroll listener to check visibility on scroll
    if (this.profileType === 'eprocurement') {
      this.$router.push({
        path: this.$i18n.path(`403`),
      })
    }

    window.addEventListener(
      'scroll',
      this.debounce(this.checkBoundingLoad, 150)
    )

    this.isSkeletonPage = false
  },
  beforeDestroy() {
    // Clean up event listener
    window.removeEventListener('scroll', this.checkBoundingLoad)
  },
  methods: {
    debounce(func, delay) {
      let timeout
      return function (...args) {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(context, args), delay)
      }
    },
    async checkBoundingLoad() {
      const threshold = 100
      // this.shop_components.forEach((e, index) => {
      const refElement = this.$refs[`filter-brands`]
      // Check if the ref is defined and rendered
      //  && refElement.length > 0
      if (refElement) {
        const bounding = refElement.getBoundingClientRect()
        // Check if element is within the viewport
        if (bounding.top < window.innerHeight - threshold) {
          // Mark as loaded if not already
          if (!this.stateLoad[`filter-brands`]) {
            this.$set(this.stateLoad, `filter-brands`, true)
            // Check for coupon code
            await this.getBrandsAll()
          }
        }
      }
      // })
    },
    // handleScroll() {
    //   if (
    //     window.scrollY >=
    //     document.getElementById('alphabet-bar').getBoundingClientRect().top + 60
    //   ) {
    //     this.fixedPosition = true
    //   } else {
    //     this.fixedPosition = false
    //     console.log('remove')
    //   }
    //   this.alphabets.forEach((element, index) => {
    //     if (
    //       document.getElementById('alphabet-' + index) !== null &&
    //       window.scrollY >=
    //         document.getElementById('alphabet-' + index).offsetTop
    //     ) {
    //       this.activeRef = index
    //     }
    //   })
    // },
    // scrollToRef(refName) {
    //   if (document.getElementById(refName) !== null) {
    //     let top = document.getElementById(refName).offsetTop + 210
    //     window.scroll({
    //       behavior: 'smooth',
    //       top: top,
    //     })
    //   }
    // },
    async getฺBannerHome() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType || 'Standard')
      if (this.userProfile.segment)
        params.append('segment', this.userProfile.segment)
      params.append('page_type', 'brand')
      params.append('status', 'active')

      await this.$profileProvider.getฺBannerHomeData(params).then(
        async (result) => {
          if (result.status === 200) {
            const data = result.data

            data
              .filter((a) => a.position === 'brand_official_shop')
              .forEach((e) => {
                this.brand_official_shop_banner.push({
                  src: e.image.url,
                  src_mobile: e?.image_mobile?.url,
                  url: e.path_url,
                  gtmSeq: e.sequence,
                  active_from: e.active_from,
                  active_to: e.active_to,
                  update_info: e.update_info.timestamp,
                  banner_name: e.banner_name,
                  alt_tag: e.alt_tag,
                  title_tag: e.title_tag,
                })
              })
            data
              .filter((a) => a.position === 'ofm_mall')
              .forEach((e) => {
                this.ofm_mall_banner.push({
                  src: e.image.url,
                  src_mobile: e?.image_mobile?.url,
                  url: e.path_url,
                  gtmSeq: e.sequence,
                  active_from: e.active_from,
                  active_to: e.active_to,
                  update_info: e.update_info.timestamp,
                  banner_name: e.banner_name,
                  alt_tag: e.alt_tag,
                  title_tag: e.title_tag,
                })
              })
            console.log(
              'getฺBannerHome',
              this.brand_official_shop_banner,
              this.ofm_mall_banner
            )

            this.brand_official_shop_banner =
              this.brand_official_shop_banner.sort(
                (a, b) => a.gtmSeq - b.gtmSeq
              )

            this.ofm_mall_banner = this.ofm_mall_banner.sort(
              (a, b) => a.gtmSeq - b.gtmSeq
            )

            // this.brand_official_shop_banner

            // this.ofm_mall_banner
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
    },
    async getBrandsHome() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType || 'Standard')
      if (this.userProfile.segment)
        params.append('segment', this.userProfile.segment)

      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type)
      }
      params.append('page_type', 'home')
      await this.$profileProvider.getBrandsHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            // console.log('object', data)
            // let brandHome = data.brand_homes.sort(function (a, b) {
            //   return a.sequence - b.sequence
            // })
            // brandHome.forEach((c) => {
            //   let banner = c.banner_images[0]
            //   let src = banner?.image?.url || ''
            //   // this.$utils.isObjectValid(c) &&
            //   // this.$utils.isObjectValid(c.image) &&
            //   // this.$utils.isValid(c.image.url)
            //   //   ? c.image.url
            //   //   : ''
            //   this.brandsItems.push({
            //     id: c.brand_id,
            //     src: src,
            //     sequence: c.sequence,
            //     subTitle: 'By Brand',
            //     title: banner?.title_tag?.[this.$i18n.locale], // new
            //     alt: banner?.alt_tag?.[this.$i18n.locale], // new
            //     path_url: banner?.path_url,
            //   })
            // })

            data.brand_images
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .forEach((a, index) => {
                let src =
                  // this.$utils.anyArrayObjectValid(a) &&
                  this.$utils.isObjectValid(a.images[0]) &&
                  this.$utils.isValid(a.images[0].url)
                    ? a.images[0].url
                    : this.defaultImageBrand
                let path_url =
                  // this.$utils.anyArrayObjectValid(a) &&
                  this.$utils.isValid(a.path_url) ? a.path_url : ''
                // brandHome[0].image.url,
                this.bannerBrands.push({
                  sequence: a.sequence,
                  // src: src,
                  brandID: a.id,
                  brandName: a.display_name.th,
                  index: index + 1,
                  src: this.$utils.imageTimeStamp(
                    src,
                    this.$utils.isObjectKeyValid(a.update_info, 'timestamp')
                      ? a.update_info.timestamp
                      : ''
                  ),
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.title_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  url: path_url,
                })
              })
            console.log('bannerBrands', this.bannerBrands)
          }
        },
        (error) => {
          console.log('getBrandsHome', error.responsed)
        }
      )
    },
    async getBrandsAll() {
      await this.$profileProvider.getBrandAllData().then(
        (result) => {
          if (result.status === 200) {
            this.alphabets = result.data
            // result.data.forEach((a, i) => {
            //   this.alphabets.push({
            //     index: i + 1,
            //     title: a.title,
            //     brands: a.brand_names,
            //   })
            // })
            this.activeAlphabet = this.alphabets[0].title
            console.log('alphabets1', result.data, this.activeAlphabet)
          }
        },
        (error) => {
          console.log('getBrandsAll', error.responsed)
        }
      )
      this.isLoadRefBrand = false
      this.isLoading = false
    },

    // coupon
    async onGetPromotionCoupons() {
      console.log('onGetPromotionCoupons')
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile?.id || '')
      params.append(
        'user_category_type',
        this.userProfile?.categories_type || 'Standard'
      )
      this.brandsCouponItems.forEach((brand) => {
        params.append('brand_ids', brand)
      })
      console.log('brandsCouponItems', this.brandsCouponItems)
      params.append('coupon_types', 'Brand')
      params.append('page', 1)
      params.append('page_size', 20)
      await this.$productProvider.getPromotionCoupons(params).then(
        (result) => {
          if (result.status === 200) {
            console.log('getPromotionCoupons2', result.data)
            if (result?.data?.items?.length > 0) {
              this.coupons = [...result.data.items]
            }
          }
        },
        (error) => {
          console.log('getPromotionCoupons', error.responsed)
        }
      )
      this.isLoading = false
    },
    async onGetPromotionCouponsSeller() {
      console.log('onGetPromotionCouponsSeller')
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile?.id || '')
      params.append(
        'user_category_type',
        this.userProfile?.categories_type || 'Standard'
      )
      this.sellerCouponItems.forEach((seller) => {
        params.append('seller_ids', seller)
      })
      params.append('coupon_types', 'Seller')
      params.append('page', 1)
      params.append('page_size', 20)
      await this.$productProvider.getPromotionCoupons(params).then(
        (result) => {
          if (result.status === 200) {
            console.log('getPromotionCoupons', result.data)
            if (result?.data?.items?.length > 0) {
              this.couponsSeller = [...result.data.items]
            }
          }
        },
        (error) => {
          console.log('getPromotionCoupons', error.responsed)
        }
      )
      this.isLoading = false
    },
    onActiveAlphabet(target) {
      this.activeAlphabet = target
      setTimeout(() => {
        this.isLoading = false
      }, 500)
      console.log(this.filterBrands)
    },
    // check brands_id have shop
    // async onGetBrandsID(brand) {
    // const brands_id = await this.$graphQL.onGetBrandsID(brand.id)
    // if (brands_id) {
    //   let isBrandFound = brands_id.shops_brands.find(
    //     (e) => e.brands_id === brand.id
    //   )
    //   return isBrandFound
    //     ? this.$i18n.path(`shop/${isBrandFound?.translations?.display_name}`)
    //     : this.$i18n.path(`brand/${brand.display_name}`)
    // }
    // return this.$i18n.path(`brand/${brand.display_name}`)
    // },

    // async onGetBrandsHighlight(type) {
    //   const BrandsHighlight = await this.$graphQL.onGetBrandsHighlight(type)
    //   if (BrandsHighlight?.shops.length) {
    //     const shops = [...BrandsHighlight.shops]
    //     console.log('onGetBrandsHighlight', shops)
    //     if (type === 'OFMStock') this.ofmStockItems = shops
    //     else if (type === 'DropShip') this.dropShipItems = shops
    //   }
    // },
    async onGetBrandsHighlight(
      type,
      isHighlight = true,
      pageNo = 1,
      pageSize = 9999
    ) {
      const BrandsHighlight = await this.$graphQL.onGetBrandsAsync(
        'OFMStock',
        isHighlight,
        pageNo,
        pageSize
      )

      console.log('onGetBrandsHighlightNEW', BrandsHighlight)

      if (BrandsHighlight?.shops.length) {
        const shops = [...BrandsHighlight.shops]
        if (type === 'OFMStock') this.ofmStockItems = shops
        // this.brandsHighlightItems = shops
      }
      console.log('this.ofmStockItems', this.ofmStockItems)
      let brandsId = []
      this.ofmStockItems.forEach((item) => {
        const id = item.shops_brands.map((brand) => brand.brands_id.id)
        brandsId = [...brandsId, ...id]
      })
      console.log('brandsId', brandsId)
      this.brandsCouponItems = brandsId
      if (brandsId.length > 0) {
        await this.onGetPromotionCoupons()
      }
    },

    async onGetShopHighlight(isHighlight = true, type) {
      const MallHighlight = await this.$graphQL.onGetDropShipHighlight(
        isHighlight,
        'DropShip'
      )

      console.log('onGetMallHighlight', MallHighlight)

      if (MallHighlight?.shops.length) {
        const shops = [...MallHighlight.shops]
        if (type === 'DropShip') this.dropShipItems = shops
        // this.brandsHighlightItems = shops
      }
      console.log('this.dropShipItems', this.dropShipItems)
      let sellerId = []
      this.dropShipItems.forEach((item) => {
        const id = item.sellers_id.seller_id
        sellerId.push(id)
      })
      this.sellerCouponItems = sellerId
      console.log('this.sellerCouponItems', this.sellerCouponItems)

      if (sellerId.length > 0) {
        await this.onGetPromotionCouponsSeller()
      }
    },

    onShowPromotionDetail(obj) {
      console.log('promotion_detail_html', obj)
      this.coupon_code = obj?.coupon_code
      this.couponDetail = obj?.promotion_detail_html
      this.promotionNo = obj?.promotion_no
      this.product_include_flag = obj?.product_include_flag
      if (this.couponDetail) this.isShowCouponDetail = true
    },
  },
}
