import { render, staticRenderFns } from "./special-deal.vue?vue&type=template&id=5526f769"
import script from "./special-deal.vue?vue&type=script&lang=js"
export * from "./special-deal.vue?vue&type=script&lang=js"
import style0 from "./special-deal.vue?vue&type=style&index=0&id=5526f769&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default})
