
import { mapGetters } from 'vuex'
export default {
  name: 'MainNavbar',
  props: {
    items: { type: Array, default: () => [] },
    // isMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      activeSearch: false,
      active: false,
      isMobile: false,
      activeCategory: '',
      activeDepartment: '',
      categories: this.items,
      local: '',
      deptArray: [],
      deptPath: '',
      setTimeoutCategory: '',
      setTimeoutDepartment: '',
      moc: [],
      contentBody: '',
      activeMobile: {
        name: '',
        seo_name: '',
      },
      banners: [],
      backupBanners: [],
      allbannerBrands: [],
      brands: [],
      lineLink: 'http://bit.ly/LineOfficeMate',
      display_hot: 'https://ofm-cdn0.ofm.co.th/images/logo/ic_hot.png',
      isDisplayIconPromotion: false,
      isDisplayIconRanking: false,
      promotions: [],
      ranking: [],
      isLoading: true,
      oldPath: '',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    companyLogo() {
      if (
        this.profileType === 'eprocurement' &&
        Object.keys(this.userProfile).length
      ) {
        return this.userProfile.company_logo_url
      } else return require('@/assets/images/accounts/m/icon_m.png')
    },
    companyName() {
      if (
        this.profileType === 'eprocurement' &&
        Object.keys(this.userProfile).length
      ) {
        return this.userProfile.name
          ? this.userProfile.name[this.$i18n.locale]
          : ''
      } else return ''
    },
    isEprocurement() {
      return this.profileType === 'eprocurement' ? true : false
    },
  },
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        this.isLoading = false
        this.categories = newVal
        if (
          this.$utils.anyArrayObjectValid(this.categories) &&
          !this.$utils.anyArrayObjectValid(this.banners)
        ) {
          this.getBannersCategory()
        }
        // console.log('this.categories', this.categories)
      },
      deep: true,
    },
    isMobile: {
      handler: function (val) {
        this.active = val

        val
          ? document.getElementsByTagName('body')[0].classList.add('lock')
          : document.getElementsByTagName('body')[0].classList.remove('lock')
        // this.$emit('onMobile', val)

        this.activeCategory = ''
        this.activeDepartment = ''
        this.deptArray = []
        console.log('outer', this.active)
      },
      deep: true,
    },
    active: {
      handler: function (val) {
        if (!val)
          document.getElementsByTagName('body')[0].classList.remove('lock')
      },
    },
  },
  mounted() {
    this.getbannerBrandSeeAll()
    // if (
    //   this.$utils.anyArrayObjectValid(this.items) &&
    //   !this.$utils.anyArrayObjectValid(this.banners)
    // ) {
    //   this.getBannersCategory()
    // }
    // this.onGetPromotion()
    // this.onGetRanking()
    this.$nuxt.$on('isMobile', (val) => {
      this.isMobile = val
      this.active = val
      console.log('on', val)
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('isMobile', () => {
      this.isMobile = false
    })
    this.$nuxt.$off('onActiveOverlay')
  },
  created() {
    this.$nuxt.$on('onActiveOverlay', (e) => {
      // console.log('test', e)
      this.activeSearch = e
    })
  },
  methods: {
    displayBrandImage(img) {
      return img || 'https://ofm-cdn0.ofm.co.th/images/default-brand-logo.png'
    },
    imgBrandError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/default-brand-logo.png'
    },
    onChangeLanguage(language) {
      this.$router.push(
        `${
          language === 'th'
            ? this.$route.fullPath.replace(/^\/[^\/]+/, '')
            : this.$route.fullPath.includes('/en/')
            ? this.$route.fullPath
            : `/en${this.$route.fullPath}`
        }`
      )
    },
    isBrands(catId) {
      // console.log('brands', this.brands)
      return (
        this.$utils.anyArrayObjectValid(this.brands) &&
        this.brands.some((q) => q.catId === catId)
      )
    },
    // redirectToCart() {
    //   // window.open(this.$i18n.path('cart'), '_self')
    //   // this.$router.push({ path: this.$i18n.path('cart') })
    // },
    // redirectToStoreLocation() {
    //   this.onClickResetMenu()
    //   // this.$router.push({ path: this.$i18n.path('store-location') })
    // },
    // redirectOrderHistory() {
    //   // this.$router.push({ path: this.$i18n.path('order/history?tab=waiting') })
    // },
    // redirectToSearchBrand(item) {
    //   console.log('item', item)
    //   // this.$router.push({ path: this.$i18n.path(`brand/${item.name}`) })
    //   this.onClickResetMenu()
    //   // if (banner !== undefined) {
    //   //   if (this.$utils.isValid(bannerCreative)) {
    //   //     //gtm promoClicked
    //   //     this.$gtm.push({ ecommerce: null })
    //   //     this.$gtm.push({
    //   //       event: 'promoClicked',
    //   //       ecommerce: {
    //   //         promoClick: {
    //   //           promotions: [
    //   //             {
    //   //               id: banner.src,
    //   //               name: `HP|${banner.gtmSeq}|${
    //   //                 banner.title
    //   //               }|${this.$utils.formatDateGTM(
    //   //                 banner.active_from
    //   //               )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //   //               creative: bannerCreative,
    //   //               position: banner.gtmSeq,
    //   //             },
    //   //           ],
    //   //         },
    //   //       },
    //   //     })
    //   //   }
    //   //   if (this.$utils.isValid(banner.url)) {
    //   //     this.$nextTick(() => {
    //   //       window.location.href = banner.url
    //   //     })
    //   //   }
    //   // }
    // },
    onClickBanner(banner, bannerCreative) {
      console.log('onClickBanner', banner)

      if (banner !== undefined) {
        // if (this.$utils.isValid(bannerCreative)) {
        //   this.$gtm.push({ ecommerce: null })
        //   this.$gtm.push({
        //     event: 'promoClicked',
        //     ecommerce: {
        //       promoClick: {
        //         promotions: [
        //           {
        //             id: banner.image.url,
        //             name: `HP|${banner.gtmSeq}|${
        //               banner.title
        //             }|${this.$utils.formatDateGTM(
        //               banner.active_from
        //             )}_${this.$utils.formatDateGTM(banner.active_to)}`,
        //             creative: bannerCreative,
        //             position: banner.gtmSeq,
        //           },
        //         ],
        //       },
        //     },
        //   })
        // }

        // gtm GA4
        const promotion = {
          creative_name: banner.title,
          creative_name: bannerCreative,
          creative_slot: `${bannerCreative}_${banner.gtmSeq}`,
          promotion_id: banner.image.url,
          promotion_name: `Mega|${banner.gtmSeq}|${
            banner.title
          }|${this.$utils.formatDateGTM(
            banner.active_from
          )}_${this.$utils.formatDateGTM(banner.active_to)}`,
        }
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'select_promotion',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            ...promotion,
          },
        })
        this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)

        if (this.$utils.isValid(banner.path_url)) {
          this.$nextTick(() => {
            window.location.href = banner.path_url
            this.onClickResetMenu()
          })
        }
      }
    },
    collapseMenu(e, index) {
      // let touch =
      //   'sourceCapabilities' in e
      //     ? e.sourceCapabilities.firesTouchEvents
      //     : false

      let el = e.relatedTarget === null ? '' : e.relatedTarget.className
      // alert(touch + '-' + el)
      //  && el !== 'category-lists'
      //  && el !== 'category'
      if (!this.isTouchDevice()) {
        if (index === 1) {
          if (
            el !== 'nav-item navbar-menu' &&
            el !== 'category-wrapper active' &&
            el !== 'category'
          ) {
            this.onClickResetMenu()
          }
        } else {
          if (this.activeSearch === false) {
            this.contentBody =
              'height:' + (this.$refs.categoryWrapper.clientHeight - 46) + 'px'
            this.active = true
          }
        }
      } else {
        // if (el !== 'category') this.onClickResetMenu()
      }
    },
    onTouchMenu(e) {
      this.active = !this.active
      if (this.active) {
        this.contentBody =
          'height:' + (this.$refs.categoryWrapper.clientHeight - 46) + 'px'
      } else {
        this.onClickResetMenu()
      }
      // if (e.sourceCapabilities.firesTouchEvents) {

      // }
    },
    onMenuBack(lvl) {
      if (lvl === 1) {
        this.activeCategory = ''
        this.deptArray = []
      } else if (lvl === 2) {
        this.deptArray = []
      }
    },
    gotoPath(path) {
      let newPath = path
      console.log('path', path + '///' + this.oldPath)
      if (!this.isMobile) {
        if (this.isTouchDevice()) {
          if (newPath === this.oldPath) {
            this.$router.push({
              path: this.$i18n.path(`category/${path}`),
            })
            this.onClickResetMenu()
          }
        } else {
          this.$router.push({
            path: this.$i18n.path(`category/${path}`),
          })
          this.onClickResetMenu()
        }
        this.oldPath = path
      }

      // console.log(newPath + '-' + this.oldPath)
    },
    gotoPathMobile(path, data) {
      if (this.isMobile) {
        this.$router.push({
          // path: '/' + 'category/' + path,
          path: this.$i18n.path(`category/${path}`),
        })
        this.isMobile = false
        this.activeCategory = ''
      }
    },
    onCategory(catIndex, cat_id, e, path) {
      let touch =
        'sourceCapabilities' in e
          ? e.sourceCapabilities.firesTouchEvents
          : false
      let _this = this
      // _this.deptArray = []
      if (!this.isMobile) {
        /*  && !touch */
        this.setTimeoutCategory = setTimeout(function () {
          _this.activeCategory =
            _this.categories.length > 0 ? _this.categories[catIndex].id : ''
          // _this.activeCategory = _this.categories[catIndex].id
          _this.deptArray =
            _this.categories[catIndex].items.length > 0
              ? _this.categories[catIndex].items[0].items.filter(
                  (a) => a.status === 'Active'
                )
              : []
          for (const element of _this.deptArray) {
            element['parent_meta'] = _this.categories[catIndex].items[0].meta
          }
          _this.activeDepartment =
            _this.categories[catIndex].items.length > 0
              ? _this.categories[catIndex].items[0].id
              : ''
        }, 100)
        this.onCheckPromotion(cat_id)
        this.onCheckRanking(cat_id)
        // Add preventDefault() to touch events
        if (
          e.type === 'touchstart' ||
          e.type === 'touchend' ||
          e.type === 'touchmove'
        ) {
          e.preventDefault()
        }

        console.log(touch)
        this.oldPath = !touch ? path : ''
      }

      // gtm GA4 Mega_Banner
      for (const banner of this.banners.filter((el) => el.catId === cat_id)) {
        for (const item of banner.imag_data) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_promotion',
            ecommerce: {
              creative_name: 'Mega_Banner',
              creative_slot: `Mega_Banner_${item.gtmSeq}`,
              promotion_id: item.image.url,
              promotion_name: `Mega|${item.gtmSeq}|${
                item.title
              }|${this.$utils.formatDateGTM(
                item.active_from
              )}_${this.$utils.formatDateGTM(item.active_to)}`,
            },
          })
        }
      }
    },
    onMobileCategory(catIndex, cat_id) {
      if (this.isMobile) {
        this.activeCategory =
          this.categories.length > 0 ? this.categories[catIndex].id : ''
        console.log('onMobileCategory')
        this.onCheckPromotion(cat_id)
        this.onCheckRanking(cat_id)
      }
    },
    onDepartment(subcat, deptData, deptPath) {
      if (!this.isMobile) {
        let _this = this
        this.setTimeoutDepartment = setTimeout(function () {
          _this.activeDepartment = subcat.id
          _this.deptArray = []
          _this.deptArray = deptData
          console.log('subcat', subcat)
          for (const element of _this.deptArray) {
            element['parent_meta'] = subcat.meta
          }
          console.log('onDepartment', _this.deptArray)
          _this.deptPath = deptPath
        }, 100)
        this.oldPath = ''
      }
    },
    onMobileDepartment(subcat, deptData, deptPath) {
      if (this.isMobile) {
        this.activeDepartment = subcat.id
        this.deptArray = []
        this.deptArray = deptData
        // console.log('subcat', subcat)
        for (const element of this.deptArray) {
          element['parent_meta'] = subcat.meta
        }
        console.log('onMobileDepartment', this.deptArray)
        this.deptPath = deptPath

        this.activeMobile.name = subcat.display_name[this.$i18n.locale]
        this.activeMobile.seo_name = subcat.meta.seo_name[this.$i18n.locale]
        console.log(this.activeMobile)
      }
    },
    removeTimeout(data) {
      clearTimeout(data)
    },
    async getBannersCategory() {
      this.banners = []
      this.brands = []
      let params = new URLSearchParams()
      // params.append('category_id', catId)
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)
      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type)
      }
      let _this = this
      await this.$profileProvider.getBannerCategoryData(params).then(
        (result) => {
          if (result.status === 200) {
            let datas = result.data
            let group = this.groupBy(datas, 'category_id')
            let tempBrands = []
            let tempBanners = []
            Object.keys(group).forEach(function (key) {
              if (!_this.$utils.anyArrayObjectValid(group[key])) return

              let mega = group[key]
                .filter((a) => a.page_type === 'mega')
                .sort(function (a, b) {
                  return a.position.localeCompare(b.position, undefined, {
                    sensitivity: 'base',
                    numeric: true,
                  })
                })

              // console.log('mega', mega)
              if (_this.$utils.anyArrayObjectValid(mega)) {
                let position1 = mega.find((a) => a.position === 'position1')
                /* new */
                if (_this.$utils.isObjectValid(position1)) {
                  position1.banner_images.forEach((element, index) => {
                    _this.$set(element, 'alt', element.title)
                    _this.$set(element, 'url', element.path_url)
                    _this.$set(element, 'asset', false)
                    _this.$set(element, 'gtmSeq', 1)
                    _this.$set(element, 'active_from', position1.active_from)
                    _this.$set(element, 'active_to', position1.active_to)
                  })

                  tempBanners.push({
                    catId: position1?.category_id,
                    position: position1?.position,
                    pattern: position1?.pattern,
                    imag_data: position1?.banner_images,
                  })
                }

                let position2 = mega.find((a) => a.position === 'position2')
                /* new */
                if (_this.$utils.isObjectValid(position2)) {
                  position2.banner_images.forEach((element, index) => {
                    _this.$set(element, 'alt', element.title)
                    _this.$set(element, 'url', element.path_url)
                    _this.$set(element, 'asset', false)
                    _this.$set(element, 'gtmSeq', 2)
                    _this.$set(element, 'active_from', position2.active_from)
                    _this.$set(element, 'active_to', position2.active_to)
                  })

                  tempBanners.push({
                    catId: position2?.category_id,
                    position: position2?.position,
                    pattern: position2?.pattern,
                    imag_data: position2?.banner_images,
                  })
                }
              }
            })

            let group_categories = this.groupBy(this.categories, 'id')
            Object.keys(group_categories).forEach(function (key) {
              if (_this.allbannerBrands.some((a) => a.category.id === key)) {
                let resultBrand = _this.allbannerBrands.find(
                  (a) => a.category.id === key
                )
                // console.log('getBanner', result)
                resultBrand.brands.forEach((element) => {
                  tempBrands.push({
                    catId: key,
                    name: element.display_name.th,
                    img: element.images[0]?.url,
                  })
                })
              }
            })

            _this.brands = tempBrands
            _this.banners = tempBanners
          }
        },
        (error) => {
          console.log('getBannerCategoryData', error.responsed)
        }
      )
    },
    async getbannerBrandSeeAll() {
      await this.$productProvider.getBannerBrandSeeAllData().then((result) => {
        if (result.status === 200) {
          this.allbannerBrands = result.data
          console.log('this.allbannerBrands', this.allbannerBrands)
        }
      })
    },
    // async getBanner(catId) {
    //   var result = this.allbannerBrands.find((a) => a.category.id === catId)
    //   if (result !== undefined) {
    //     // console.log('getBanner', result)
    //     result.brands.forEach((element) => {
    //       this.brands.push({
    //         catId: catId,
    //         name: element.display_name.th,
    //         img: element.images[0].url,
    //       })
    //     })
    //   }
    // },
    groupBy(array, key) {
      const result = {}
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    async onCheckPromotion(catId) {
      this.isDisplayIconPromotion = false
      if (
        this.$utils.anyArrayObjectValid(this.promotions) &&
        this.promotions.some((q) => q.catId === catId)
      ) {
        let objCatId = this.promotions.find((q) => q.catId === catId)
        this.isDisplayIconPromotion = objCatId.value
        return
      }
      let params = new URLSearchParams()
      params.append('categoryId', catId)
      await this.$productProvider
        .headPromotion(params)
        .then((result) => {
          this.isDisplayIconPromotion = result.status === 200
          this.promotions.push({ catId, value: this.isDisplayIconPromotion })
        })
        .catch((err) => {})
    },
    async onCheckRanking(catId) {
      this.isDisplayIconRanking = false
      if (
        this.$utils.anyArrayObjectValid(this.ranking) &&
        this.ranking.some((q) => q.catId === catId)
      ) {
        let objCatId = this.ranking.find((q) => q.catId === catId)
        this.isDisplayIconRanking = objCatId.value
        return
      }
      let params = new URLSearchParams()
      params.append('categoryId', catId)
      await this.$productProvider
        .headRanking(params)
        .then((result) => {
          this.isDisplayIconRanking = result.status === 200
          this.ranking.push({ catId, value: this.isDisplayIconRanking })
        })
        .catch((err) => {})
    },
    // async onGetPromotion() {
    //   this.promotions = []
    //   await this.$productProvider
    //     .getPromotion()
    //     .then((result) => {
    //       if (result.status === 200) {
    //         // this.promotions = result.data
    //         let promotions = []
    //         result.data.forEach((value, index) => {
    //           promotions = [].concat(promotions, ...value.category_ids)
    //         })
    //         this.promotions = [...new Set(promotions)]
    //         // console.log('this.promotions', this.promotions.length)
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('err', err)
    //     })
    // },
    // async onGetRanking(catId) {
    //   // this.ranking = []
    //   // await this.$productProvider
    //   //   .getRanking()
    //   //   .then((result) => {
    //   //     if (result.status === 200) {
    //   //       // this.promotions = result.data
    //   //       let ranking = []
    //   //       result.data.forEach((value, index) => {
    //   //         ranking = [].concat(ranking, ...value.category_ids)
    //   //       })
    //   //       this.ranking = [...new Set(ranking)]
    //   //       // console.log('this.ranking', this.ranking.length)
    //   //     }
    //   //   })
    //   //   .catch((err) => {
    //   //     console.log('err', err)
    //   //   })
    // },
    onClickResetMenu() {
      this.activeCategory = ''
      this.activeDepartment = ''
      this.deptPath = ''
      this.deptArray = []
      this.active = false
      this.isMobile = false
    },
    isTouchDevice() {
      //  ||
      //   navigator.maxTouchPoints > 0 ||
      //   navigator.msMaxTouchPoints > 0
      return 'ontouchstart' in window
    },
    onClickOutsideMenu() {
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth > 1180) {
        this.onClickResetMenu()
      }
    },
  },
}
