
import SwiperSlider from '@/components/share/swiper-item.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TabProductCatagorie',
  components: {
    // 'slider-items': SliderItemsComponent,
    'swiper-item': SwiperSlider,
  },
  props: {
    categoriesName: { type: Object, default: Object },
    meta: { type: Object, default: Object },
    imgCategories: { type: String, default: '' },
    subCatagories: { type: Array, default: Array },
    defaultSubCatagorieID: { type: String, default: '' },
    titleCategories: { type: String, default: '' },
    hasCategoryUrl: { type: Boolean, default: false },
    isShowNormalPrice: { type: Boolean, default: true },
    categoriesProductHome: { type: Array, default: Array },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    update_info: { type: String, default: '' },
    url: { type: String, default: '' },
  },
  data() {
    return {
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      items: [],
      activeTab: '',
      isloanding: false,
      lang: '',
      filterProductStatus: ['Inactive', 'Delete'],
      overlayImagesSwiper: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    categoriesDisplayName() {
      return this.$utils.isObjectValid(this.categoriesName) &&
        this.$utils.isValid(this.categoriesName[this.lang])
        ? this.categoriesName[this.lang]
        : ''
    },
    metaSEODisplayName() {
      return this.$utils.isObjectValid(this.meta) &&
        this.$utils.isObjectValid(this.meta.seo_name) &&
        this.$utils.isValid(this.meta.seo_name[this.lang])
        ? this.meta.seo_name[this.lang]
        : ''
    },
  },
  async created() {},
  async mounted() {
    this.lang = this.$store.state.locale
    console.log(
      'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      this.categoriesProductHome
    )
    if (this.$utils.anyArrayObjectValid(this.subCatagories)) {
      if (this.$utils.isValid(this.defaultSubCatagorieID)) {
        await this.GetCategoriesProductHome(this.defaultSubCatagorieID)
        await this.onActiveCategories(this.defaultSubCatagorieID)
      }
    }
    // drag scroll
    setTimeout(function () {
      const sliders = document.querySelectorAll(
        '.tab-product-categories-right .nav-tabs'
      )
      sliders.forEach((slider, index) => {
        let isDown = false
        let startX
        let scrollLeft
        let next = slider.querySelector('.categories-caret-next')
        let prev = slider.querySelector('.categories-caret-prev')
        window.addEventListener(
          'resize',
          function () {
            if (slider.offsetWidth < slider.scrollWidth) {
              next.classList.add('active')
            } else {
              next.classList.remove('active')
            }
          },
          true
        )
        if (slider.offsetWidth < slider.scrollWidth) {
          next.classList.add('active')
        } else {
          next.classList.remove('active')
        }
        // click next
        next.addEventListener('click', (e) => {
          slider.scrollLeft = slider.scrollWidth - slider.offsetWidth
          next.classList.remove('active')
          prev.classList.add('active')
        })
        // click prev
        prev.addEventListener('click', (e) => {
          slider.scrollLeft = 0
          prev.classList.remove('active')
          next.classList.add('active')
        })

        slider.addEventListener(
          'mousedown',
          (e) => {
            isDown = true
            startX = window.event.pageX - slider.offsetLeft
            scrollLeft = slider.scrollLeft
            document.body.style.setProperty('cursor', 'grabbing', 'important')
          },
          { passive: true }
        )

        window.addEventListener(
          'mouseleave',
          () => {
            isDown = false
            slider.classList.remove('active')
            document.body.style.cursor = 'default'
          },
          { passive: true }
        )
        window.addEventListener(
          'mouseup',
          () => {
            isDown = false
            slider.classList.remove('active')
            document.body.style.cursor = 'default'
          },
          { passive: true }
        )
        // window
        window.addEventListener(
          'mousemove',
          (e) => {
            if (!isDown) return
            slider.classList.add('active')
            // window.event.preventDefault()
            const x = window.event.pageX - slider.offsetLeft
            const move = x - startX
            slider.scrollLeft = scrollLeft - move
            document.body.style.setProperty('cursor', 'grabbing')
            // active next
            if (
              slider.scrollWidth - slider.scrollLeft - 20 <
              slider.offsetWidth
            ) {
              next.classList.remove('active')
            } else {
              next.classList.add('active')
            }
            // active prev
            if (slider.scrollLeft < 20) {
              prev.classList.remove('active')
            } else {
              prev.classList.add('active')
            }
          },
          { passive: true }
        )
        // mobile
        slider.addEventListener(
          'touchmove',
          (e) => {
            // active next
            if (
              slider.scrollWidth - slider.scrollLeft - 20 <
              slider.offsetWidth
            ) {
              next.classList.remove('active')
            } else {
              next.classList.add('active')
            }
            // active prev
            if (slider.scrollLeft < 20) {
              prev.classList.remove('active')
            } else {
              prev.classList.add('active')
            }
          },
          { passive: true }
        )
      })
    }, 100)
  },
  watch: {
    // subCatagories: function (value) {
    //   console.log('value subCatagories', value)
    // },
    // defaultSubCatagorieID: function (value) {
    //   if (this.$utils.isValid(value)) {
    //     this.onActiveCategories(value)
    //   }
    // },
    subCatagories: async function (value) {
      console.log('subCatagories', value)
      if (this.$utils.anyArrayObjectValid(value)) {
        if (this.$utils.isValid(this.defaultSubCatagorieID)) {
          await this.actionActiveCategories(this.defaultSubCatagorieID)
        }
      }
    },
    imgCategories: {
      handler: function (val) {
        if (val) {
          this.$utils.onPreload(
            this.$utils.genImgSrcSet(
              this.$utils.imageTimeStamp(val, this.update_info, false),
              [
                { w: 228, h: 188, q: 90, ws: '228w' },
                { w: 328, h: 270, q: 90, ws: '328w' },
                { w: 380, h: 313, q: 90, ws: '380w' },
                { w: 608, h: 500, q: 90, ws: '608w' },
                { w: 340, h: 280, q: 90, ws: '340w' },
              ]
            ),
            '(max-width: 360px) 328px, (max-width: 412px) 380px, (max-width: 768px) 228px, (max-width: 1024px) 228px, (max-width: 1280px) 340px, (max-width: 1920px) 340px'
          )
        }
      },
      deep: true,
    },
    items: {
      handler: async function (val) {
        // overlay promotion
        if (val.length) {
          let pid = val.map((item) => {
            return item.productId
          })
          const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          if (graphqlResponse) {
            this.overlayImagesSwiper = graphqlResponse
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    async onActiveCategories(deptID) {
      this.activeTab = deptID
      await this.GetCategoriesProductHome(deptID)
    },
    async actionActiveCategories(deptID) {
      this.activeTab = deptID
      await this.loadCategoriesProductHome(deptID)
    },
    async GetCategoriesProductHome(deptID) {
      this.isloanding = true
      this.items = []

      let category = this.categoriesProductHome.find((q) => q.key === deptID)
      console.log('category', category)
      if (
        this.$utils.isObjectValid(category) &&
        this.$utils.anyArrayObjectValid(category.items)
      ) {
        this.items = category.items
      }
      this.isloanding = false
    },
    async loadCategoriesProductHome(deptID) {
      this.isloanding = true
      this.items = []
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      params.append('pageNo', 1)
      params.append('pageSize', 20)

      await this.$productProvider
        .getCategoriesProductHomeData(deptID, params, null, false)
        .then(
          async (result) => {
            if (result.status === 200) {
              let data = result.data
              console.log(
                'api/v1/ProductHomePage/categories/${id}/products',
                data
              )
              data.items.forEach((a, index) => {
                this.items.push({
                  productId: a.skus[0]?.sku_code,
                  productName: a.skus[0]?.product_names.find(
                    (b) => b.type.toLowerCase() === 'online'
                  ).display_name,
                  // productImg: a.skus[0]?.images.find(
                  //   (c) => c.size === 'thumbnail' && c.sequence === 1
                  // ).url,
                  productImg: this.imageNormalShowcase(a.skus[0]),
                  price_include_vat: a.skus[0]?.price_include_vat,
                  retail_price: a.skus[0]?.retail_price.include_vat,
                  is_promotion: a.skus[0]?.is_promotion,
                  is_fixprice: a.skus[0]?.is_fixprice,
                  is_flash_sale: a.skus[0]?.is_flash_sale,
                  is_online_only: a.skus[0]?.is_online_only,
                  promotion_prices: a.skus[0]?.promotion_prices,
                  display_discount_rate: a.skus[0]?.display_discount_rate,
                  meta: a.skus[0]?.meta,
                  seoMeta: a.skus[0]?.meta.seo_name,
                  seq: index + 1,
                  display_ratings: a.skus[0]?.display_ratings,
                  display_ratings_count: a.skus[0]?.display_ratings_count,
                  update_info: a.skus[0]?.update_info,
                  overlay_web_image: a.skus[0]?.overlay_web_image,
                  is_promotion_discount: a.skus[0]?.is_promotion_discount,
                  brand: a.brand,
                })
              })

              if (this.items.length) {
                // overlay promotion
                // if (this.items.length) {
                //   let pid = this.items.map((item) => {
                //     return item.productId
                //   })
                //   const graphqlResponse =
                //     await this.$graphqlProvider.onGetOverlay(pid)
                //   if (graphqlResponse) {
                //     this.overlayImagesSwiper = graphqlResponse
                //   }
                // }
                //gtm
                this.$gtm.push({ ecommerce: null })
                this.$gtm.push({
                  event: 'impression',
                  currencyCode: 'THB',
                  ecommerce: {
                    impressions: this.items.map((item) => {
                      return {
                        id: item.productId,
                        price: this.$utils.formatPrice(
                          item.price_include_vat,
                          2,
                          true
                        ),
                        list: 'Top Categories',
                        position: item.seq,
                      }
                    }),
                  },
                })
                this.$emit('onGetItems', this.items)
              }
            }
          },
          (error) => {
            console.log('GetCategoriesProductHome', error.responsed)
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.isloanding = false
          }, 100)
        })
    },
    async openCat(deptID) {
      const params = new URLSearchParams()
      params.append('catalogId', deptID)
      params.append('isFree', false)
      if (this.$utils.anyArrayObjectValid(this.filterProductStatus)) {
        this.filterProductStatus.forEach((item) => {
          params.append('productStatus', item)
        })
      }

      const responsed = await this.$productProvider.getProductList(params)
      if (responsed.status === 200) {
        // this.items = responsed.data.items
        this.items = []
        responsed.data.items.forEach((item, index) => {
          this.items.push({
            index: index,
            productId: item.pID,
            productName: item.pTName,
            productImg: `https://biblio-cdn.officemate.co.th/products/${item.pID}/tpimage`,
            productPrice: item.priceIncVat,
            productDiscount: 0,
            //subCatagories: item.deptID,
          })
        })
      } else this.items = []
      this.activeTab = deptID
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return (
          sku.images.some((q) => q.size === 'large')
            ? sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'large').url
            : this.imageTemp
        ).replace('/large', '/original')
      } else {
        return this.imageTemp.replace('/large', '/original')
      }
    },
    getImgUrl(pic) {
      return pic //+ '?x-image-process=image/format,webp/resize,m_fixed,h_353,w_1172'
    },

    // genImgSrcSet(pathName, options = [{ w: 150, h: 150, q: 90, ws: '150w' }]) {
    //   let srcsets = []
    //   if (options.length > 0) {
    //     for (let index = 0; index < options.length; index++) {
    //       const option = options[index]
    //       srcsets = [
    //         ...srcsets,
    //         `${pathName}${this.genXimage(option.w, option.h, option.q)} ${
    //           option.ws
    //         }`,
    //       ]
    //     }
    //     return srcsets.join(',')
    //   }
    //   return `${pathName}`
    // },
    // genXimage(w, h, q) {
    //   return `?x-image-process=image/format,webp/resize,m_fixed,w_${w},h_${h}/quality,q_${q}/marker,u_plus/sharpen,50`
    // },
  },
}
