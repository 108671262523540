
import MainCategoryList from '@/components/category/main-category-list.vue'
import SwiperItem from '@/components/share/swiper-item.vue'
import TabProductBrand from '@/components/share/tab-header-product-brand.vue'
import SwiperItemSkeleton from '@/components/skeleton/swiper-skeleton-item.vue'
import { mapGetters } from 'vuex'
import notfound from '@/components/share/notfound.vue'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'CategoryLevel1',
  components: {
    'main-category-list': MainCategoryList,
    'swiper-item': SwiperItem,
    TabProductBrand,
    SwiperItemSkeleton,
    notfound,
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            `https://www.ofm.co.th` +
            this.$i18n.path(`category/${this.department_title}`),
        },
      ],
      script: [
        // {
        //   src:
        //     this.$nuxt.context.store.getters['user/profileType'] !== null &&
        //     this.$nuxt.context.store.getters['user/profileType'] ===
        //       'eprocurement'
        //       ? '//eprocurementth.api.useinsider.com/ins.js?id=10002769'
        //       : '//eprocurementth.api.useinsider.com/ins.js?id=10006310',
        //   async: true,
        // },
        {
          // Breadcrumb Rich Snippet
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: this.$nuxt._route.params.department, //Main Category
              },
            ],
          },
        },
      ],
    }
  },
  // async fetch() {
  //   if (process.server) {
  //     this.seoNameLevelOne = this.$nuxt._route.params.department
  //     if (this.seoNameLevelOne !== '') {
  //       await this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
  //     }
  //     // await this.getProductBestSeller()
  //     // await this.getRecommendedProductHome()
  //   }
  // },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'category',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  async asyncData({ route, app, store, $axios }) {
    let pageTitle = `${app.i18n.t('meta.defaultTitle')}`
    let pageContent = `${app.i18n.t('meta.description')}`
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'
    let categoryId = ''

    let department_title = ''
    // let pathSEOName = ''
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    console.log('http-server', http)
    // axios
    try {
      const getCategoryMeta = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/${route.params.department}/meta/`
        ),
        { headers }
      )
      if (getCategoryMeta.status === 200) {
        const categories = getCategoryMeta.data.categories[0]
        console.log('categories', categories)
        let otherLang = store.state.locale === 'th' ? 'en' : 'th'
        // default name
        department_title = categories.meta.seo_name[store.state.locale]
        let pathName = categories.display_name[store.state.locale]
        categoryId = categories.id
        // pathSEOName = categories.meta.seo_name
        if (app.$utils.isObjectValid(categories.meta)) {
          if (app.$utils.isObjectValid(categories.meta.title_tag)) {
            let titleTag = categories.meta.title_tag

            pageTitle = app.$utils.isValid(titleTag[store.state.locale])
              ? titleTag[store.state.locale]
              : titleTag[otherLang]
          } else {
            // default pageTitle
            pageTitle = `${pathName}`
          }

          if (app.$utils.isObjectValid(categories.meta.description_tag)) {
            let descriptionTag = categories.meta.description_tag
            pageContent =
              // app.i18n.t('meta.descShop') +
              app.$utils.isValid(descriptionTag[store.state.locale])
                ? descriptionTag[store.state.locale]
                : descriptionTag[otherLang]
          } else {
            // default pageContent
            pageContent = `${app.i18n.t('meta.descShop')}${pathName}`
          }
        }
      }
    } catch (e) {
      console.log('catch', e)
    }
    return {
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
      asyncCategoryId: categoryId,
      department_title: department_title,
      // pathSEOName: pathSEOName,
    }
  },
  data() {
    return {
      asyncCategoryId: '',
      breadcrumbItems: [
        {
          text: 'Furniture',
          href: '/',
        },
      ],
      itemPromotionBanner: [],
      itemsBestSeller: [],
      itemsRecommendedProducts: [],
      condition: {},
      seoNameLevelOne: '',
      categoryName: {},
      categorySEOName: {},
      categoryId: '',
      category: [],
      categoryBanner1: [],
      categoryBanner2: [],
      brandItems: [],
      activeBrand: '',
      brandHomeImage: [],
      page: 1,
      perPage: 45,
      pageTitle: '',
      pageContent: '',
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeletonItems: true,
      skeletonPage: true,
      isNotFound: false,
      defaultImageBrand:
        'https://ofm-cdn0.ofm.co.th/images/default-brand-logo.png',
      categoryContent: '',
      showContent: false,
      showBestsellerContent: false,
      showRecommendedContent: false,
      showPromotionContent: false,
      showCategoryContent: false,
      overlayImagesItemsBestSeller: [],
      overlayImagesItemsPromotion: [],
      overlayImagesItemsRecommended: [],
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    isItemsBestSeller() {
      return this.$utils.anyArrayObjectValid(this.itemsBestSeller)
    },
    isBrandItems() {
      return this.$utils.anyArrayObjectValid(this.brandItems)
    },
    isCategoryBanner() {
      return this.$utils.anyArrayObjectValid(this.categoryBanner2)
    },
    isItemPromotionBanner() {
      return this.$utils.anyArrayObjectValid(this.itemPromotionBanner)
    },
    isItemsRecommendedProducts() {
      return this.$utils.anyArrayObjectValid(this.itemsRecommendedProducts)
    },
    onCategoryBanner2() {
      return this.categoryBanner2
    },
  },
  watch: {
    categoryId: {
      handler: function (val) {
        this.skeletonItems = false
        this.setTitle()
        this.getฺBannerCategory()
        this.getCategoryBrands()
        this.getProductBestSeller()
      },
      deep: true,
    },
  },
  created() {
    this.seoNameLevelOne = this.$nuxt._route.params.department
    this.$nuxt.$on('returnCategory', (data) => {
      this.setCategory(data)
    })
    this.$nuxt.$on('onCategoryReady', () => {
      this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
    })
    // this.$nuxt.$on('render', (result) => {
    //   console.log('render: ', result)
    //   this.getCategoriesFromLayout()
    // })
    this.categoryId = this.asyncCategoryId
  },
  mounted() {
    if (process.client) {
      this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
    }

    setTimeout(() => {
      this.onBoundingLoad()
    }, 100)

    window.addEventListener('scroll', this.onBoundingLoad)
  },
  updated() {
    // this.skeletonPage = false
    // this.skeletonItems = false
    // this.$_onGetInsider({
    //   page: {
    //     type: 'Category',
    //     custom: {
    //       category_name: this.categoryName[this.$store.state.locale],
    //     },
    //   },
    // })
    // this.$_onInsiderResult({
    //   taxonomy: [this.categoryName[this.$store.state.locale]],
    //   result: [
    //     ...(this.itemsBestSeller || []),
    //     ...(this.itemsRecommendedProducts || []),
    //     ...(this.itemPromotionBanner || []),
    //   ],
    // })
  },
  beforeDestroy() {
    // $off method will turn off the event listner
    this.$nuxt.$off('returnCategory')
    this.$nuxt.$off('onCategoryReady')
    // window.removeEventListener('load', this.onBoundingLoad)
    window.removeEventListener('scroll', this.onBoundingLoad)
  },
  methods: {
    createProfileParams() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)

      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type)
      }
      return params
    },
    async getCategoryBrands() {
      await this.$productProvider.getCategoryBrandsData(this.categoryId).then(
        async (result) => {
          if (result.status === 200) {
            let data = result.data
            data.forEach((a, index) => {
              this.brandItems.push({
                sequence: index,
                src: a.url == '' ? this.defaultImageBrand : a.url,
                brandID: a.id,
                brandName: a.display_name.th,
                update_info: a.update_info,
              })
            })

            this.activeBrand = this.brandItems[0]?.brandID
            // this.itemsBestSeller = await this.GetCategoriesAndBrandProductHome(
            //   this.activeBrand,
            //   'BestSeller'
            // )
            // this.itemPromotionBanner =
            //   await this.GetCategoriesAndBrandProductHome(
            //     this.activeBrand,
            //     'Promotion'
            //   )
            // this.itemsRecommendedProducts =
            //   await this.GetCategoriesAndBrandProductHome(
            //     this.activeBrand,
            //     'Recommended'
            //   )
          }
        },
        (error) => {
          console.log('getBrandsHome', error.responsed)
        }
      )
    },
    // async onActiveBrand(brandID) {
    //   // this.brandItems = []
    //   this.activeBrand = brandID
    //   // console.log(brandID)
    //   // this.itemsBestSeller = await this.GetCategoriesAndBrandProductHome(
    //   //   brandID,
    //   //   'BestSeller'
    //   // )
    //   // this.itemPromotionBanner = await this.GetCategoriesAndBrandProductHome(
    //   //   brandID,
    //   //   'Promotion'
    //   // )
    //   // this.itemsRecommendedProducts =
    //   //   await this.GetCategoriesAndBrandProductHome(
    //   //     this.activeBrand,
    //   //     'Recommended'
    //   //   )
    // },
    async getProductBestSeller() {
      let params = this.createProductParams()
      return await this.$productProvider
        .getCategoryProductBestSellerData(this.categoryId, params)
        .then(
          async (result) => {
            if (result.status === 200) {
              let data = result.data
              this.itemsBestSeller = data.items ?? undefined
              if (
                this.itemsBestSeller !== undefined &&
                this.itemsBestSeller.length
              ) {
                // ทำ seq เพื่อส่งเข้า gtm event position
                let startIndex =
                  (result.data.pageNo - 1) * result.data.pageSize + 1
                this.itemsBestSeller.map((item, index) => {
                  item.seq = index + startIndex
                })
                //gtm
                this.$gtm.push({ ecommerce: null })
                this.$gtm.push({
                  event: 'impression',
                  currencyCode: 'THB',
                  ecommerce: {
                    impressions: this.itemsBestSeller.map((item) => {
                      return {
                        id: item.skus[0].sku_code,
                        price: this.$utils.formatPrice(
                          item.skus[0].price_include_vat,
                          2,
                          true
                        ),
                        list: 'Best Sellers',
                        position: item.seq,
                      }
                    }),
                  },
                })

                // gtm GA4
                this.$gtm.push({ ecommerce: null })
                this.$gtm.push({
                  event: 'view_item_list',
                  ecommerce: {
                    user: this.$store.getters['user/userInfoTrack'],
                    item_list_id: 'best_sellers',
                    item_list_name: 'Best Sellers',
                    items: this.itemsBestSeller.map((item, index) => {
                      const sku = item.skus[0]
                      return {
                        index: index,
                        item_id: sku.sku_code,
                        item_name: sku.product_names[1]?.display_name.th,
                        price: sku.price_include_vat,
                        discount:
                          sku.retail_price.include_vat - sku.price_include_vat,
                        item_list_id: 'best_sellers',
                        item_list_name: 'Best Sellers',
                        ...this.$store.getters['user/userPromotionTrack'],
                      }
                    }),
                  },
                })
              }
            }
            // console.log('itemsBestSeller', this.itemsBestSeller)
            // this.skeletonItems = false
            // overlay promotion
            if (this.itemsBestSeller.length) {
              let pid = this.itemsBestSeller.map((item) => {
                return item.skus[0].sku_code
              })
              const graphqlResponse = await this.$graphqlProvider.onGetOverlay(
                pid
              )
              if (graphqlResponse) {
                this.overlayImagesItemsBestSeller = graphqlResponse
              }
            }
          },
          (error) => {
            console.log('getProductBestSeller', error.responsed)
            // this.skeletonItems = false
          }
        )
    },
    async setTitle() {
      await this.$productProvider.getCategoryById(this.categoryId).then(
        async (result) => {
          if (result.status === 200) {
            console.log('page data', result.data)
            this.categoryContent = result.data.content[this.$i18n.locale]
            // window.history.replaceState(
            //   {},
            //   '',
            //   this.$i18n.path(`category/${this.department_title}`)
            // )
            // let otherLang = this.$i18n.locale === 'th' ? 'en' : 'th'

            // if (this.$utils.isObjectValid(result.data.meta)) {
            //   if (this.$utils.isObjectValid(result.data.meta.title_tag)) {
            //     let titleTag = result.data.meta.title_tag
            //     this.pageTitle = this.$utils.isValid(
            //       titleTag[this.$i18n.locale]
            //     )
            //       ? titleTag[this.$i18n.locale]
            //       : titleTag[otherLang]
            //   }
            //   console.log('this.pageTitle', this.pageTitle)

            //   if (this.$utils.isObjectValid(result.data.meta.description_tag)) {
            //     let descriptionTag = result.data.meta.description_tag
            //     this.pageContent = this.$utils.isValid(
            //       descriptionTag[this.$i18n.locale]
            //     )
            //       ? descriptionTag[this.$i18n.locale]
            //       : descriptionTag[otherLang]
            //   }
            //   this.categoryContent = result.data.content[this.$i18n.locale]
            // }
          }
        },
        (error) => {
          console.log('getBannerCategoryData', error.responsed)
        }
      )
    },
    async setCategory(data) {
      console.warn('setCategory :>> ', data)
      if (this.$utils.isObjectValid(data)) {
        this.categoryName = data.display_name
        this.categorySEOName = data.meta.seo_name
        if (this.$utils.isObjectValid(data.items)) {
          this.category = data.items.filter((a) => a.status === 'Active')
          // this.categoryId = data.id
          console.log('categoryId', data)
        }
        this.skeletonPage = false
      } else {
        console.log('notfound')
        this.skeletonPage = false
        this.skeletonItems = false
        this.isNotFound = true
      }
    },
    getCategoriesFromLayout() {
      this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
    },
    async getฺBannerCategory() {
      this.banners = []
      let params = this.createProfileParams()
      params.append('category_id', this.categoryId)
      await this.$profileProvider.getBannerCategoryData(params).then(
        async (result) => {
          if (result.status === 200) {
            let data = result.data
            let category_banner = data
              .filter((a) => a.page_type === 'categories')
              .sort(function (a, b) {
                return a.position.localeCompare(b.position, undefined, {
                  sensitivity: 'base',
                  numeric: true,
                })
              })

            if (this.$utils.anyArrayObjectValid(category_banner)) {
              let categoryBannerMain = []
              let categoryBannerSecond = []
              let position1 = category_banner
                .filter((a) => a.position === 'position1')
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })

              let position2 = category_banner
                .filter((a) => a.position === 'position2')
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })

              if (this.$utils.anyArrayObjectValid(position1)) {
                position1.forEach((item, index) => {
                  categoryBannerMain.push(
                    this.onParameterCategoryBanner(item, index)
                  )
                })
              }

              if (this.$utils.anyArrayObjectValid(position2)) {
                position2.forEach((item, index) => {
                  categoryBannerSecond.push(
                    this.onParameterCategoryBanner(item, index)
                  )
                })
              }
              this.categoryBanner1 = categoryBannerMain
              this.categoryBanner2 = categoryBannerSecond
              await this.gtmBannerEvent()
            }
          }
        },
        (error) => {
          console.log('getBannerCategoryData', error.responsed)
        }
      )
    },
    onParameterCategoryBanner(item, index) {
      return {
        index: item.sequence,
        src: item.banner_images[0].image.url,
        url: item.banner_images[0].path_url,
        title: item.banner_images[0].title,
        alt: item.banner_images[0].title,
        gtmSeq: index + 1,
        active_from: item.active_from,
        active_to: item.active_to,
        update_info: item.update_info,
      }
    },
    async gtmBannerEvent() {
      //gtm Hero_Banner
      if (this.$utils.anyArrayObjectValid(this.categoryBanner1)) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.categoryBanner1.map((item) => {
                return {
                  id: item.src,
                  name: `Cat|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                  creative: 'Hero_Banner',
                  position: item.gtmSeq,
                }
              }),
            },
          },
        })

        // gtm GA4 Hero_Banner
        for (const item of this.categoryBanner1) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_promotion',
            ecommerce: {
              creative_name: 'Hero_Banner',
              creative_slot: `Hero_Banner_${item.gtmSeq}`,
              promotion_id: item.src,
              promotion_name: `Cat|${item.gtmSeq}|${
                item.title
              }|${this.$utils.formatDateGTM(
                item.active_from
              )}_${this.$utils.formatDateGTM(item.active_to)}`,
            },
          })
        }
      }

      //gtm Promotion_Banner
      if (this.$utils.anyArrayObjectValid(this.categoryBanner2)) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.categoryBanner2.map((item) => {
                return {
                  id: item.src,
                  name: `Cat|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                  creative: 'Promotion_Banner ',
                  position: item.gtmSeq,
                }
              }),
            },
          },
        })

        // gtm GA4 Promotion_Banner
        for (const item of this.categoryBanner2) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_promotion',
            ecommerce: {
              creative_name: 'Promotion_Banner',
              creative_slot: `Promotion_Banner${item.gtmSeq}`,
              promotion_id: item.src,
              promotion_name: `Cat|${item.gtmSeq}|${
                item.title
              }|${this.$utils.formatDateGTM(
                item.active_from
              )}_${this.$utils.formatDateGTM(item.active_to)}`,
            },
          })
        }
      }
    },
    async getRecommendedProductHome() {
      let params = this.createProductParams()
      params.append('user_open_id', this.openId)
      params.append('categoryId', this.categoryId)
      await this.$productProvider.getRecommendedProductHomeData(params).then(
        async (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('getRecommendedProductHomeData', data)
            if (data.items !== null && data.items.length > 0) {
              this.itemsRecommendedProducts = data.items ?? []
              // ทำ seq เพื่อส่งเข้า gtm event position
              let startIndex =
                (result.data.pageNo - 1) * result.data.pageSize + 1
              this.itemsRecommendedProducts.map((item, index) => {
                item.seq = index + startIndex
              })

              // overlay promotion
              if (this.itemsRecommendedProducts.length) {
                let pid = this.itemsRecommendedProducts.map((item) => {
                  return item.skus[0].sku_code
                })
                const graphqlResponse =
                  await this.$graphqlProvider.onGetOverlay(pid)
                if (graphqlResponse) {
                  this.overlayImagesItemsRecommended = graphqlResponse
                }
              }

              //gtm
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'impression',
                currencyCode: 'THB',
                ecommerce: {
                  impressions: this.itemsRecommendedProducts.map((item) => {
                    return {
                      id: item.skus[0].sku_code,
                      price: this.$utils.formatPrice(
                        item.skus[0].price_include_vat,
                        2,
                        true
                      ),
                      list: 'Promotions',
                      position: item.seq,
                    }
                  }),
                },
              })

              // gtm GA4
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'view_item_list',
                ecommerce: {
                  user: this.$store.getters['user/userInfoTrack'],
                  item_list_id: 'recommended',
                  item_list_name: 'Recommended',
                  items: this.itemsRecommendedProducts.map((item, index) => {
                    const sku = item.skus[0]
                    return {
                      item_id: sku.sku_code,
                      item_name: sku.product_names[1]?.display_name.th,
                      index: index,
                      price: sku.price_include_vat,
                      discount:
                        sku.retail_price.include_vat - sku.price_include_vat,
                      item_list_id: 'recommended',
                      item_list_name: 'Recommended',
                      ...this.$store.getters['user/userPromotionTrack'],
                    }
                  }),
                },
              })
            }
          }
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
      this.skeletonItems = false
    },
    createProductParams() {
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('accountId', this.userProfile.id ?? '')
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      params.append('pageNo', 1)
      params.append('pageSize', 20)
      return params
    },
    async getPromotionProduct() {
      let params = this.createProductParams()
      params.append('categoryId', this.categoryId)
      await this.$productProvider.getPromotionProductData(params).then(
        async (result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.items !== null && data.items.length > 0) {
              this.itemPromotionBanner = data.items ?? []
              // ทำ seq เพื่อส่งเข้า gtm event position
              let startIndex =
                (result.data.pageNo - 1) * result.data.pageSize + 1
              this.itemPromotionBanner.map((item, index) => {
                item.seq = index + startIndex
              })

              // overlay promotion
              if (this.itemPromotionBanner.length) {
                let pid = this.itemPromotionBanner.map((item) => {
                  return item.skus[0].sku_code
                })
                const graphqlResponse =
                  await this.$graphqlProvider.onGetOverlay(pid)
                if (graphqlResponse) {
                  this.overlayImagesItemsPromotion = graphqlResponse
                }
              }

              //gtm
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'impression',
                currencyCode: 'THB',
                ecommerce: {
                  impressions: this.itemPromotionBanner.map((item) => {
                    return {
                      id: item.skus[0].sku_code,
                      price: this.$utils.formatPrice(
                        item.skus[0].price_include_vat,
                        2,
                        true
                      ),
                      list: 'Promotions',
                      position: item.seq,
                    }
                  }),
                },
              })

              // gtm GA4
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'view_item_list',
                ecommerce: {
                  user: this.$store.getters['user/userInfoTrack'],
                  item_list_id: 'promotions',
                  item_list_name: 'promotions',
                  items: this.itemPromotionBanner.map((item, index) => {
                    const sku = item.skus[0]
                    return {
                      item_id: sku.sku_code,
                      item_name: sku.product_names[1]?.display_name.th,
                      index: index,
                      price: sku.price_include_vat,
                      discount:
                        sku.retail_price.include_vat - sku.price_include_vat,
                      item_list_id: 'promotions',
                      item_list_name: 'promotions',
                      ...this.$store.getters['user/userPromotionTrack'],
                    }
                  }),
                },
              })
            }
          }
        },
        (error) => {
          console.log('getPromotionProduct', error.responsed)
        }
      )
      this.skeletonItems = false
    },
    async gotoSeeAllByCategory(name) {
      // pathSEOName
      this.$router.push({
        path: this.$i18n.path(
          `widget/${name}/${this.categorySEOName[this.$i18n.locale]}`
        ),
      })
    },
    async onBoundingLoad() {
      // if (!this.skeletonPage) {
      let height = 50

      if (
        this.$refs['promotion-content'].getBoundingClientRect().top <
          window.innerHeight - height &&
        this.showPromotionContent === false
      ) {
        this.showPromotionContent = true
        await this.getPromotionProduct()
      }
      if (
        this.$refs['recommended-content'].getBoundingClientRect().top <
          window.innerHeight - height &&
        this.showRecommendedContent === false
      ) {
        this.showRecommendedContent = true
        await this.getRecommendedProductHome()
      }
      if (
        this.$refs['category-content'].getBoundingClientRect().top <
          window.innerHeight - height &&
        this.showCategoryContent === false
      ) {
        this.showCategoryContent = true
      }
      // }
    },
  },
}
