export default function ({ isHMR, app, store, route, params, error }) {
  // res.setHeader('Cache-Control', 's-maxage=10000, stale-while-revalidate')

  const defaultLocale = app.i18n.fallbackLocale
  const checkLang = params.lang
  // If middleware is called from hot module replacement, ignore it
  // console.log('defaultLocale', defaultLocale)
  if (isHMR) {
    return
  }

  if (params === undefined || Object.keys(params).length === 0) {
    params.lang = route.fullPath.includes('/en/') ? 'en' : 'th'
    // params.lang = /\/en\//.test(route.fullPath) ? 'en' : 'th'
  }
  // Get locale from params
  console.log('i18n', checkLang, params)
  const locale =
    params.lang !== 'th' && params.lang !== 'en' ? defaultLocale : params.lang
  // Set locale
  store.commit('SET_LANG', locale)
  app.i18n.locale = store.state.locale

  if (!store.state.locales.includes(checkLang) && checkLang) {
    return error({ message: 'This page could not be found.', statusCode: 404 })
  }
}
