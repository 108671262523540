import { render, staticRenderFns } from "./address.vue?vue&type=template&id=5401d275"
import script from "./address.vue?vue&type=script&lang=js"
export * from "./address.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,SelectObject: require('/usr/src/nuxt-app/components/share/select-object.vue').default,ModalWarning: require('/usr/src/nuxt-app/components/modal/modalWarning.vue').default})
