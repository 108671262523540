const cookieparser = process.server ? require('cookieparser') : undefined
const Cookie = process.client ? require('js-cookie') : undefined

export const state = () => ({
  routeHistory: '',
  locales: ['th', 'en'],
  locale: 'th',
  paymentResponed: {},
})

export const mutations = {
  SAVE_ROUTE_HISTORY(state, path) {
    state.routeHistory = path
  },
  SET_LANG(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  SET_PAYMENT_RESPONSED(state, payload) {
    state.paymentResponed = payload
  },
}

export const actions = {
  nuxtServerInit({ commit }, { req }) {
    console.log('nuxtServerInit')
    let cookieParsed = null
    // console.log('req.rawHeaders', req.rawHeaders)
    if (
      req !== undefined &&
      req !== null &&
      req.headers !== undefined &&
      req.headers !== null &&
      req.headers.cookie !== undefined &&
      req.headers.cookie !== null &&
      req.headers.cookie
    ) {
      cookieparser.maxLength = 16384
      const parsed = cookieparser.parse(req.headers.cookie)

      console.log('parsed', parsed)
      try {
        cookieParsed = parsed
        // auth = JSON.parse(parsed)
      } catch (err) {
        console.log(err)
        // No valid cookie found
      }
    }
    if (cookieParsed !== null) {
      commit('authen/SET_AUTH_TOKEN', cookieParsed)
      if (cookieParsed.accessToken !== undefined) {
        commit('authen/IS_AUTHENTICATED_BY_COOKIE', cookieParsed.accessToken)
      }
      commit('cart/LOAD_CART_ID', cookieParsed.cartId)
      if (cookieParsed.user !== undefined) {
        commit('authen/LOAD_USER', cookieParsed.user)
      }

      // commit(
      //   'cart/ADD_CART_COUNT',
      //   cookieParsed.cartCount ? cookieParsed.cartCount : 0
      // )

      // console.log('cookieParsed.cart', cookieParsed.cart)
      if (cookieParsed.cart !== undefined && cookieParsed.cart.length) {
        commit('cart/LOAD_CART', cookieParsed.cart)
      }

      if (
        cookieParsed.userProfile !== undefined &&
        cookieParsed.userProfile !== 'undefined'
      ) {
        commit('user/LOAD_USER_PROFILE', {
          payload: cookieParsed.userProfile,
          profileType: cookieParsed.profileType,
        })
      }

      if (cookieParsed.userCostcenter !== undefined)
        commit('user/LOAD_USER_COSTCENTER', {
          payload: cookieParsed.userCostcenter,
        })
      if (cookieParsed.userInfo !== undefined)
        commit('user/LOAD_USER_INFO', {
          payload: cookieParsed.userInfo,
        })
      if (cookieParsed.consentCookieStatus !== undefined)
        commit(
          'user/LOAD_CONSENT_COOKIE_STATUS',
          cookieParsed.consentCookieStatus
        )
      if (cookieParsed.currentTransactionId !== undefined)
        commit('order/LOAD_CURRENT_TRANSACTION_ID', {
          transactionId: cookieParsed.currentTransactionId,
        })
    }
  },
  saveRouteHistory({ commit }, path) {
    commit('SAVE_ROUTE_HISTORY', path)
  },
  paymentResponsed({ commit }, payload) {
    commit('SET_PAYMENT_RESPONSED', payload)
  },
  reloadStoreData({ commit }) {
    console.log('nuxtServerInit Reload')

    let accessToken = Cookie.get('accessToken')
    let token = {
      refreshToken: refreshToken,
      accessToken: accessToken,
    }
    commit('authen/SET_AUTH_TOKEN', token)
    if (accessToken !== undefined) {
      commit('authen/IS_AUTHENTICATED_BY_COOKIE', accessToken)
    }

    let userProfile = Cookie.get('userProfile')
    let profileType = Cookie.get('profileType')
    // console.log('userProfile', userProfile)
    if (userProfile && profileType) {
      commit('user/LOAD_USER_PROFILE', {
        payload: userProfile,
        profileType: profileType,
      })
    }

    let cart = Cookie.get('cart')
    if (cart) {
      commit('cart/LOAD_CART', cart)
    }
    let userCostcenter = Cookie.get('userCostcenter')
    if (userCostcenter) {
      commit('user/LOAD_USER_COSTCENTER', {
        payload: userCostcenter,
      })
    }

    let userInfo = Cookie.get('userInfo')
    if (userInfo) {
      commit('user/LOAD_USER_INFO', {
        payload: userInfo,
      })
    }
  },
}

export const getters = {
  routeHistory: (state) => state.routeHistory,
  paymentResponed: (state) => state.paymentResponed,
}
