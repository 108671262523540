
import BreadCrumb from '@/components/template/bread-crumb.vue'
import InputQty from '@/components/share/input-qty.vue'
// import SwiperSlider from '@/components/share/swiper-item.vue'
import SwiperItemSkeleton from '@/components/skeleton/swiper-skeleton-item.vue'
import { mapGetters, mapActions } from 'vuex'
import ProductNotifyModal from '@/components/modal/productNotify.vue'
import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'
// import countdown from '@/components/flashsale/countdown.vue'
import modalShipping from '@/components/delivery-charge/modalShipping.vue'

export default {
  layout: 'main',
  name: 'ProductDetail',
  components: {
    breadcrumb: BreadCrumb,
    'image-zoom': async () => await import('@/components/share/image-zoom.vue'),
    // 'list-item-add': ListItemAdd,
    // 'list-other-seller': ListOtherSeller,
    'input-qty': InputQty,
    'swiper-item': async () =>
      await import('@/components/share/swiper-item.vue'),
    // 'compare-product': CompareProductComponent,
    // 'question-answer': QuestionAnswerComponent,
    ProductNotifyModal,
    SwiperItemSkeleton,
    // notfound,
    countdown: async () => await import('@/components/flashsale/countdown.vue'),
    TooltipInstallmentDetail: async () =>
      await import('@/components/installment/tooltip-detail.vue'),
    modalShipping,
    // modalShipping: async () =>
    //   await import('@/components/delivery-charge/modalShipping.vue'),
    componentCouponSwiper: async () =>
      await import('@/components/shop-in-shop/display/coupon-swiper.vue'),
  },
  async asyncData({ req, route, app, store, $axios }) {
    let pageTitle = `${app.i18n.t('meta.defaultTitle')}`
    let pageContent = `${app.i18n.t('meta.description')}`
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'
    let paths = route.params.id.split('.')
    let sku_code =
      app.$utils.anyArrayObjectValid(paths) &&
      app.$utils.isValid(paths[paths.length - 1])
        ? paths[paths.length - 1].toUpperCase()
        : ''
    // paths[paths.length - 1].toUpperCase()

    let pdp_title = ''
    let ratingSnippet = {}
    let breadcrumbSnippet = []
    let breadcrumbItems = []
    // let params = new URLSearchParams()
    // // let res = { status: 404, data: {} }

    // params.append('accountChannel', store.state.user.profileType)
    // params.append('skuCode', skuCode)
    // params.append('userOpenId', store.state.cart.cartId)
    // params.append('accountId', store.state.user.userProfile.id)

    // if (
    //   store.state.user.profileType === 'eprocurement' &&
    //   app.$utils.isObjectValid(store.state.user.userProfile)
    // ) {
    //   if (app.$utils.isValid(store.state.user.userProfile.id)) {
    //     params.append('companyId', store.state.user.userProfile.id)
    //   }
    //   if (app.$utils.isValid(store.state.user.userProfile.categories_type)) {
    //     params.append(
    //       'userCategoryType',
    //       store.state.user.userProfile.categories_type
    //     )
    //   }
    //   if (app.$utils.isObjectKeyValid(store.state.user.userCostcenter, 'id')) {
    //     params.append('costcenterId', store.state.user.userCostcenter.id)
    //   }
    // } else if (store.state.user.profileType === 'standard') {
    //   params.append('userCategoryType', 'Standard')
    // }
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    }
    // axios
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    console.log('http-server', http)
    try {
      const response = await $axios.get(
        `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductSkus/${sku_code}/meta`,
        { headers }
      )
      const res = response
      if (res.status === 200) {
        console.log('pdp', res.data)
        let getSku = res.data
        if (
          app.$utils.isObjectValid(getSku) &&
          app.$utils.isObjectValid(getSku.meta)
        ) {
          let productName = getSku.display_name[store.state.locale]
          pageTitle = `${productName}${app.i18n.t('meta.titlePattern')}`
          pageContent = `${app.i18n.t(
            'meta.descShop'
          )}${productName}${app.i18n.t('meta.defaultDesc')}`

          pdp_title = `${getSku.meta.seo_name[store.state.locale]}`

          // snippet breadcrumb
          const levels = [1, 2, 3]
          let currentPath = 'category'
          let breadcrumbPath = 'https://www.ofm.co.th/category'
          let currentCategory = null

          for (const level of levels) {
            currentCategory = getSku.categories.find(
              (category) => category.level === level
            )
            if (!currentCategory) break

            breadcrumbPath += `/${
              currentCategory.meta.seo_name[store.state.locale]
            }`
            currentPath += `/${
              currentCategory.meta.seo_name[store.state.locale]
            }`
            // snippet
            breadcrumbSnippet.push({
              '@type': 'ListItem',
              position: level,
              name: currentCategory.display_name[store.state.locale],
              item: breadcrumbPath, // level category
            })

            // breadcrumb
            breadcrumbItems.push({
              text: currentCategory.display_name[store.state.locale],
              href: {
                path: app.i18n.path(`${currentPath}`),
              },
            })
          }
          breadcrumbSnippet.push({
            '@type': 'ListItem',
            position: levels.length + 1,
            name: productName, // pdp_title, // product seo name
          })
          breadcrumbItems.push({
            text: productName, // product name
            href: '',
          })

          // snippet product
          ratingSnippet = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            description: getSku.meta.description_tag[store.state.locale],
            name: productName,
            image: getSku.url,
            sku: getSku.sku_code,
            review: '',
            brand: getSku.brand.display_name[store.state.locale],
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: app.$utils.formatPrice(
                getSku.display_ratings / 20,
                1,
                true
              ),
              reviewCount: getSku.display_ratings_count,
            },
            offers: {
              '@type': 'Offer',
              availability: 'http://schema.org/InStock',
              price: app.$utils.formatPrice(getSku.price_include_vat, 2, true),
              priceCurrency: 'THB',
              url:
                `https://www.ofm.co.th` +
                app.i18n.path(
                  `product/${getSku.meta.seo_name[store.state.locale]}-p.${
                    getSku.sku_code
                  }`
                ),
            },
          }
        }
        ogImage = getSku.url
      }
    } catch (e) {
      console.log('catch', e)
    }
    const isLighthouse =
      req?.headers?.['user-agent'].includes('Chrome-Lighthouse')
    return {
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
      pdp_title: pdp_title,
      sku_code: sku_code,
      isLighthouse: isLighthouse,
      breadcrumbSnippet: breadcrumbSnippet,
      breadcrumbItems: breadcrumbItems,
      ratingSnippet: ratingSnippet,
    }
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            'https://www.ofm.co.th' +
            this.$i18n.path(`product/${this.pdp_title}-p.${this.sku_code}`),
        },
      ],
      script: [
        {
          // Breadcrumb Rich Snippet
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.breadcrumbSnippet,
          },
        },
        {
          // Product with Rating Rich Snippet
          type: 'application/ld+json',
          json: this.ratingSnippet,
        },
      ],
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'product',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      // breadcrumbSnippet: [],

      ratingSnippet: {},
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      imagelargeTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-large.jpg',
      skeletonPage: true,
      notifySku: '',
      notifyModal: false,
      isBreadcrumbItems: false,
      breadcrumbItems: [],
      setIndex: 1,
      productSlider: 0,
      overflow: false,
      isCatalog: false,
      isCatalogM: false,
      isActiveCatalog: false,
      isCreateCatalog: false,
      createCatalog: {
        name: '',
      },
      colorOptions: [],
      selectedColor: '',
      materialOptions: [],
      skuMechanics: [],
      skuMechanicKeys: [],
      selectedMaterial: {},
      clickMaterial: {},
      itemsRecommended: [],
      itemsAccessory: [],
      otherSeller: [],
      compare: [],
      // unset: false,
      images: [
        {
          index: 0,
          path: '',
          zoom: '',
        },
        {
          index: 1,
          path: '',
          zoom: '',
        },
        {
          index: 2,
          path: '',
          zoom: '',
        },
      ],
      productDetail: {},
      qa: [
        {
          index: 1,
          question:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam interdum elit auctor pellentesque lobortis.',
          answer:
            'Aenean tempor lorem nec elementum ultricies. Ut blandit velit neque, id semper ipsum laoreet quis. Ut pellentesque, quam sed pharetra ultricies, odio felis volutpat magna, nec tempus lectus mauris et diam.',
          by: 'OFM',
          date: '2020-08-27',
          seemore: false,
        },
        {
          index: 2,
          question:
            'Duis sem lectus, vehicula non iaculis vitae, consequat a tortor. ',
          answer:
            'Nulla at pretium purus. Praesent ornare lectus in leo hendrerit, at faucibus lorem euismod. Aliquam convallis pulvinar metus et ullamcorper. Fusce ut consequat lacus, vitae blandit ex. Cras accumsan sapien id finibus convallis. Nunc vitae augue aliquam, consequat diam eget, pellentesque sapien. Integer non dui ut libero eleifend tempor vel a quam. Ut non porta odio. Vivamus sed nibh eget dolor egestas efficitur vel a erat.',
          by: 'OFM',
          date: '2020-08-27',
          seemore: false,
        },
        {
          index: 3,
          question: 'Nulla at pretium purus.',
          answer:
            'Integer non dui ut libero eleifend tempor vel a quam. Ut non porta odio. Vivamus sed nibh eget dolor egestas efficitur vel a erat.',
          by: 'OFM',
          date: '2020-08-27',
          seemore: false,
        },
        {
          index: 4,
          question:
            'Aenean tempor lorem nec elementum ultricies. Ut blandit velit neque, id semper ipsum laoreet quis. Ut pellentesque, quam sed pharetra ultricies, odio felis volutpat magna, nec tempus lectus mauris et diam. ',
          answer:
            'Maecenas quis sem ac felis cursus porta in et sem. Vestibulum sit amet dui quis neque dignissim luctus. Integer sed faucibus nunc, vel consequat ante. Nam facilisis pharetra dignissim. Nulla interdum at ligula in pharetra. Duis blandit facilisis laoreet. Etiam placerat felis mi, id viverra nisl posuere et. Donec hendrerit ornare rhoncus.',
          by: 'OFM',
          date: '2020-08-27',
          seemore: false,
        },
        {
          index: 5,
          question:
            'Duis sem lectus, vehicula non iaculis vitae, consequat a tortor. ',
          answer:
            'Nulla at pretium purus. Praesent ornare lectus in leo hendrerit, at faucibus lorem euismod. Aliquam convallis pulvinar metus et ullamcorper. Fusce ut consequat lacus, vitae blandit ex. Cras accumsan sapien id finibus convallis. Nunc vitae augue aliquam, consequat diam eget, pellentesque sapien. Integer non dui ut libero eleifend tempor vel a quam. Ut non porta odio. Vivamus sed nibh eget dolor egestas efficitur vel a erat.',
          by: 'OFM',
          date: '2020-08-27',
          seemore: false,
        },
        {
          index: 6,
          question:
            'Duis sem lectus, vehicula non iaculis vitae, consequat a tortor. ',
          answer:
            'Nulla at pretium purus. Praesent ornare lectus in leo hendrerit, at faucibus lorem euismod. Aliquam convallis pulvinar metus et ullamcorper. Fusce ut consequat lacus, vitae blandit ex. Cras accumsan sapien id finibus convallis. Nunc vitae augue aliquam, consequat diam eget, pellentesque sapien. Integer non dui ut libero eleifend tempor vel a quam. Ut non porta odio. Vivamus sed nibh eget dolor egestas efficitur vel a erat.',
          by: 'OFM',
          date: '2020-08-27',
          seemore: false,
        },
      ],
      lang: '',
      skuName: '',
      promotionPrice: '',
      promotionEndDate: '',
      content: { short_description: '', long_description: '' },
      promotionCheck: false,
      sku_id: '',
      // sku_code: '',
      dataSku: {},
      attribute: [],
      qty: 0,
      minQty: 0,
      maxQty: 999,
      disableMechanics: {},
      catalogs: [],
      catalogSkus: [],
      companyId: '',
      useropenId: '',
      body: {
        id: null,
        user_open_id: null,
        email: null,
        name: null,
        sku_code: null,
        qty: 0,
      },
      notifyProductName: {},
      itemsRelated: [],
      productId: '',
      itemsAlsoBuy: [],
      itemsProductHistory: [],
      itemsPromotionDiscount: [],
      pageRelated: 1,
      perPageRelated: 20,
      pageTitle: '',
      pageContent: '',
      typeColor: ['color_code', 'image'],
      productStatusToSimilar: ['not_ready', 'cancel'],
      productStatusNotReady: ['not_ready', 'cancel', 'sold_out'],
      recommendPromotionQunatity: {},
      isNotFound: false,
      isInstallmentDetail: false,
      isInstallment: false,
      itemsBankInstallment: [],
      paymentdata: [],
      queryIdEventAlgolia: '',
      isDirectLink: false,
      isAlgolia: {
        loadPage: false,
        addCart: false,
        catalogs: [],
      },
      reduceAttribute: [],
      taxonomy: [],
      taxonomyEN: [],
      taxonomyCatId: [],
      flashSaleEndDate: '',
      currentDate: '',
      conditionPromotions: [],
      urladdon: '',
      // is_promotion_discount_bysku: false,
      onLoadContent: false,
      onLoadInfo: false,
      // overlay
      overlayImages: [],
      overlayImagesItemsPromotion: [],
      overlayImagesItemsAlsoBuy: [],
      overlayImagesItemsRelated: [],
      overlayImagesItemsHistory: [],
      isModalShipping: false,
      shopDetail: {},
      shop: {},
      translations: {},
      templatePublish: [],
      componentsImages: {},
      shop_types_id: {},
      shop_tiers_id: {},
      overlay: {},
      unique_name: '',
      seller_id: '',
      skus: '',
      brand_id: '',
      isShop: false,
      // coupon detail
      coupons: [],
      couponsSeller: [],
      coupon_code: '',
      couponDetail: '',
      promotionNo: '',
      product_include_flag: '',
      isShowCouponDetail: false,
      sharkUrl: process.env.NUXT_ENV_SHOP_IN_SHOP_ASSETS,
      vendor_type: '',
    }
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'isAuthenticated',
      'isGuest',
      'guestId',
    ]),
    // ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    ...mapGetters('cart', ['cartId']),
    ...mapGetters('user', [
      'userProfile',
      'userInfo',
      'profileType',
      'userCostcenter',
    ]),
    isUser() {
      return this.isAuthenticated && !this.isGuest
    },
    isMyGuest() {
      return this.isAuthenticated && this.isGuest
    },
    isDataSkuImages() {
      return this.$utils.anyArrayObjectValid(this.dataSku.images)
    },
    isAddCart() {
      return ['ready_to_ship', 'lead_time', 'preorder'].includes(
        this.dataSku.display_status
      )
    },
    isContactToOrder() {
      return this.dataSku.display_status === 'contact_to_order'
    },
    isAlsoBuy() {
      return this.$utils.anyArrayObjectValid(this.itemsAlsoBuy)
    },
    isRelated() {
      return this.$utils.anyArrayObjectValid(this.itemsRelated)
    },
    isProductHistory() {
      return this.$utils.anyArrayObjectValid(this.itemsProductHistory)
    },
    groupImages() {
      this.dataSku.images.sort((a, b) => a.sequence - b.sequence)
      let images = []
      if (
        this.isDataSkuImages &&
        this.$utils.anyArrayObjectValid(this.dataSku.images)
      ) {
        let group = this.groupBy(this.dataSku.images, 'sequence')
        let index = 0
        console.log('group', group)
        Object.keys(group).forEach((key) => {
          if (this.$utils.anyArrayObjectValid(group[key])) {
            images.push({
              sizeLarge: group[key]
                .find((q) => q.size === 'large')
                ?.url.replace('/large', '/original'),
              sizeThumbnail: group[key].find((q) => q.size === 'thumbnail')
                ?.url,
              sizeZoom: group[key].find((q) => q.size === 'zoom')?.url,
              sequence: index,
            })
            index++
          }
        })
      }

      return images
    },
    isOnlineOnly() {
      return this.dataSku.is_online_only || this.dataSku.is_flash_sale
    },
    isHotFlashSale() {
      return this.dataSku.is_hot_flash_sale
    },
    isDiscountRate() {
      return (
        this.$utils.anyArrayObjectValid(this.dataSku.promotion_prices) &&
        this.$utils.isObjectValid(this.dataSku.display_discount_rate) &&
        this.dataSku.display_discount_rate > 0 &&
        ((this.dataSku.is_promotion !== undefined &&
          this.dataSku.is_promotion) ||
          (this.dataSku.is_online_only !== undefined &&
            this.dataSku.is_online_only) ||
          (this.dataSku.is_flash_sale !== undefined &&
            this.dataSku.is_flash_sale))
        // this.dataSku.is_promotion
      )
    },
    hasSkuMechanics() {
      // console.log('hasSkuMechanics', this.productDetail.sku_mechanics)
      return (
        this.$utils.isObjectValid(this.productDetail) &&
        this.$utils.anyArrayObjectValid(this.productDetail.sku_mechanics) &&
        this.productDetail.sku_mechanics.some(
          (q) =>
            this.$utils.anyArrayObjectValid(q.values) && q.values.length > 1
        ) &&
        this.$utils.anyArrayObjectValid(this.productDetail.skus)
      )
    },
    fileDocument() {
      return (
        this.$utils.isObjectValid(this.productDetail) &&
        this.$utils.isObjectValid(this.productDetail.files) &&
        this.productDetail.files
          .filter(
            (q) =>
              q.document_type.toLowerCase() === 'document' &&
              q.url != null &&
              q.url != ''
          )
          .sort((a, b) => a.sequence - b.sequence)
      )
    },
    fileVideo() {
      return (
        this.$utils.isObjectValid(this.productDetail) &&
        this.$utils.isObjectValid(this.productDetail.files) &&
        this.productDetail.files
          .filter(
            (q) =>
              q.document_type.toLowerCase() === 'video' &&
              q.url != null &&
              q.url != ''
          )
          .sort((a, b) => a.sequence - b.sequence)
      )
    },
    isDiscount() {
      return (
        (this.dataSku.is_promotion !== undefined &&
          this.dataSku.is_promotion) ||
        (this.dataSku.is_online_only !== undefined &&
          this.dataSku.is_online_only) ||
        (this.dataSku.is_flash_sale !== undefined && this.dataSku.is_flash_sale)
      )
    },
    isFileDocument() {
      return this.$utils.anyArrayObjectValid(this.fileDocument)
    },
    isFileVideo() {
      return this.$utils.anyArrayObjectValid(this.fileVideo)
    },
    isMinimumQty() {
      return (
        this.$utils.isObjectValid(this.dataSku) &&
        this.dataSku.minimum_quantity > this.qty
      )
    },
    isDeliveryRestriction() {
      return (
        this.$utils.isObjectValid(this.dataSku) &&
        this.dataSku.is_delivery_restriction
      )
    },
    productInsiderParent() {
      return this.dataSku?.sku_code
        ? {
            id: this.dataSku.sku_code,
            name: this.dataSku.product_names[0].display_name[
              this.$store.state.locale
            ],
            taxonomy: this.taxonomy,
            currency: 'THB',
            unit_price: this.dataSku.retail_price.include_vat,
            unit_sale_price: this.dataSku.price_include_vat,
            url:
              `https://www.ofm.co.th` +
              this.$i18n.path(
                `product/${
                  this.dataSku.meta.seo_name[this.$store.state.locale]
                }-p.${this.dataSku.sku_code}`
              ),
            stock: this.dataSku.onhand,
            // color: null,
            // size: null,
            product_image_url:
              this.dataSku.images.length > 0
                ? this.dataSku.images[0].url
                : null,
          }
        : ''
      // }
    },
    isFlashSale() {
      // let now = new Date().toLocaleString('en-US', {
      //   timeZone: 'Asia/Bangkok',
      // })
      // // Time remaining
      // let diff = this.$utils.isObjectValid(
      //   this.dataSku,
      //   'display_price_end_date'
      // )
      //   ? new Date(this.dataSku.display_price_end_date).getTime() -
      //     new Date(now).getTime()
      //   : 0
      // return diff > 0
      return (
        this.dataSku.is_flash_sale &&
        this.$store.state.user.profileType !== 'eprocurement'
      )
    },
    // isOutofstockFlash() {
    //   return this.dataSku.is_outofstock_flash_sale
    // },
    // isOverlay() {
    //   return this.$utils.isObjectValid(this.dataSku.overlay_web_image)
    //     ? this.dataSku.overlay_web_image.some((e) => e.size === 'large')
    //     : false
    // },

    isOverlay() {
      if (this.overlayImages.length && this.sku_code) {
        const selectedProduct = this.overlayImages.filter(
          (product) => product.sku_code === this.sku_code
        )
        console.log('overlayImages', selectedProduct[0]?.overlay_web_image)
        return selectedProduct[0]
      }

      // return this.$utils.isObjectValid(this.dataSku.overlay_web_image)
      //   ? this.dataSku.overlay_web_image.some((e) => e.size === 'large')
      //   : false

      // return this.$utils.isObjectValid(this.overlay?.overlay_web_image?.id)
      //   ? this.overlay?.overlay_web_image?.id
      //   : false
    },
    // isOverlay() {
    //   console.log('this.overlay', this.overlay)

    //   return this.$utils.isObjectValid(this.overlay?.overlay_web_image?.id)
    //     ? this.overlay?.overlay_web_image?.id
    //     : false
    // },
    isPromotionDiscount() {
      return this.dataSku.is_promotion_discount || false
    },
    isInstallmentBySku() {
      return this.dataSku?.is_installment_sku
    },
  },
  watch: {
    clickMaterial: {
      handler: function (obj) {
        // this.changeSku(val)
        if (!this.disableMaterial(obj)) this.changeSku(obj)
      },
      deep: true,
    },
    qty: {
      handler: function (val) {
        // // // console.log('cc', val)
      },
    },
    skeletonPage: {
      handler: function (val) {
        if (!val) {
          this.onSetCookieShift()
        }
      },
      immediate: true,
    },
    // groupImages: {
    //   handler: function (val) {
    //     if (val.length) {
    //       val.forEach((image) => {
    //         this.$utils.onPreload(
    //           this.$utils.genImgSrcSet(
    //             this.$utils.imageTimeStamp(
    //               imageShowcase(image.sizeThumbnail),
    //               this.productDetail?.update_info?.timestamp,
    //               true
    //             ),
    //             [{ w: 72, h: 72, q: 100, ws: '72w' }]
    //           ),
    //           '(min-width: 1024px) 72px'
    //         )
    //       })
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    console.log(' this.queryIdEventAlgolia', this.$route.query)
    if (this.$utils.containsKey(this.$route.query, 'queryIdEventAlgolia')) {
      this.queryIdEventAlgolia = this.$route.query.queryIdEventAlgolia
      let query = Object.assign({}, this.$route.query)
      delete query.queryIdEventAlgolia
      this.$router.replace({
        path: this.$i18n.path(`product/${this.$route.params.id}`),
      })
      if (this.$utils.isValid(this.queryIdEventAlgolia))
        console.log(' this.queryIDEventAlgolia2', this.queryIdEventAlgolia)
      else console.log(' this.queryIDEventAlgolia2', 'non-search')
    } else {
      this.isDirectLink = true
      console.log(' this.isDirectLink')
    }
    // if (process.client) {
    this.getProductDetail()
    // }
    // if (!this.$route.params.id)
    //   this.$router.push({
    //     path: this.$i18n.path('_'),
    //   })
    // // this.sku_id = this.$nuxt._route.params.id.split('.')[1]
    // let paths = this.$nuxt._route.params.id.split('.')
    // this.sku_code = paths[paths.length - 1]
    // if (
    //   this.profileType !== '' &&
    //   this.profileType === 'eprocurement' &&
    //   Object.keys(this.userCostcenter).length
    // ) {
    //   this.companyId = this.userProfile.id
    //   this.useropenId = this.openId
    // } else if (this.profileType === 'standard') {
    //   this.useropenId = this.openId
    // }
    // // console.log(this.sku_id)
  },
  async mounted() {
    this.otherSeller = []
    this.itemsAccessory = []
    this.itemsRecommended = []
    this.compare = []
    this.collapseComment()
    this.lang = this.$store.state.locale
    const _this = this
    window.addEventListener('resize', function () {
      _this.onSetCookieShift()
    })
    window.addEventListener('load', this.onBoundingLoad)
    window.addEventListener('scroll', this.onBoundingLoad)
  },
  beforeDestroy() {
    this.$nuxt.$emit('cookieShift', { isShift: false })
    window.removeEventListener('load', this.onBoundingLoad)
    window.removeEventListener('scroll', this.onBoundingLoad)
  },
  updated() {
    // if (!this.$_isLighthouse())
    // this.$_onGetInsider({
    //   page: {
    //     type: 'Product',
    //     custom: {
    //       category_name: null,
    //     },
    //   },
    //   product: this.productInsiderParent,
    // })
  },
  methods: {
    ...mapActions('authen', ['signout']),
    ...mapActions('user', ['removeUserProfile']),
    ...mapActions('cart', ['setCartCount']),
    stockAvailable(product) {
      return product.is_available_stock
        ? product.onhand - product.reserve
        : 9999
    },
    stockFlashSaleAvailable(product) {
      let stockAva =
        product.flash_sale_quota_stock - product.flash_sale_used_stock
      return stockAva < 0 ? 0 : stockAva
    },
    checkProductStatusToSimilar() {
      return this.productDetail.skus.some(
        (q) =>
          q.sku_code === this.sku_code &&
          this.productStatusToSimilar.includes(q.display_status)
      )
    },
    async getProductDetail() {
      if (!this.$route.params.id)
        this.$router.push({
          path: this.$i18n.path('_'),
        })
      // this.sku_id = this.$nuxt._route.params.id.split('.')[1]
      // let paths = this.$nuxt._route.params.id.split('.')
      // this.sku_code =
      //   this.$utils.anyArrayObjectValid(paths) &&
      //   this.$utils.isValid(paths[paths.length - 1])
      //     ? paths[paths.length - 1].toUpperCase()
      //     : ''

      if (
        this.profileType !== '' &&
        this.profileType === 'eprocurement' &&
        Object.keys(this.userCostcenter).length
      ) {
        this.companyId = this.userProfile.id
        this.useropenId = this.openId
      } else if (this.profileType === 'standard') {
        this.useropenId = this.openId
      }
      /* กำหนด params สำหรับดึง product detail */
      let params = await this.createParams()
      /* เรียก api */
      let responsed = await this.$productProvider.getProductBySkuCode(params)
      // let responsed = this.responsed
      if (responsed.status === 200) {
        console.log('getProductBySkuCode : ', responsed)
        this.notifyProductName = responsed.data.product_names
        // this.is_promotion_discount_bysku =
        //   responsed.data.skus[0].is_promotion_discount
        // responsed.data.skus[0].is_promotion_discount = false
        this.productDetail = responsed.data
        this.productId = responsed.data.id

        let objSukcode = this.productDetail.skus.find(
          (f) => f.sku_code === this.sku_code
        )

        // get overlay
        // if (this.sku_code) {
        //   let pid = this.sku_code
        //   const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
        //   if (graphqlResponse) {
        //     this.overlayImages = graphqlResponse
        //   }
        // }

        // this.isInstallment = objSukcode.payment.some(
        //   (s) => s.payment_group === 'Installments'
        // )
        // if (this.isInstallment) {
        //   this.getPaymentAllBankInstallment()
        // }
        this.getPaymentAllBankInstallment()

        let checkIsFree = this.productDetail.skus.some(function (item) {
          return item.is_free
        })
        if (checkIsFree) {
          return this.$nuxt.error({ statusCode: 404, message: '' })
        }

        if (this.checkProductStatusToSimilar()) {
          this.$router.push({
            path: this.$i18n.path(`similar/${this.sku_code}`),
          })
        } else {
          console.log('this.productDetail.skus', this.productDetail.skus)
          let getSku = this.productDetail.skus.find(
            (q) => q.sku_code === this.sku_code
          )
          console.log('getSku', getSku)
          console.log('sku_code', getSku.sku_code)
          console.log('vendor.code', getSku.vendor.code)
          console.log('brand.id', this.productDetail.brand.id)
          this.seller_id = getSku.vendor.code
          this.skus = getSku.sku_code
          this.brand_id = this.productDetail.brand.id
          this.vendor_type = getSku.vendor.vendor_type

          console.log('this.seller_id', this.seller_id)
          // await this.getShopDetail('0000108', 'x', '11919')
          // if (this.profileType !== 'eprocurement') {
          await this.getShopDetail(
            this.skus,
            this.brand_id,
            this.seller_id,
            this.vendor_type
          )
          // } else {
          //   // get overlay
          //   if (this.sku_code) {
          //     let pid = this.sku_code
          //     const graphqlResponse = await this.$graphqlProvider.onGetOverlay(pid)
          //     if (graphqlResponse) {
          //       this.overlayImages = graphqlResponse
          //     }
          //   }
          // }
          // if (
          //   this.$utils.isObjectValid(getSku) &&
          //   this.$utils.isObjectValid(getSku.meta)
          // ) {
          //   // console.log('getSku', getSku)
          //   this.pageTitle = getSku.meta.title_tag[this.lang]
          //   this.pageContent = getSku.meta.description_tag[this.lang]
          //   // this.pageTitle = getSku.product_names.some(
          //   //   (q) => q.type.toLowerCase() === 'online'
          //   // )
          //   //   ? getSku.product_names.find((q) => q.type.toLowerCase() === 'online')
          //   //       .display_name[this.lang]
          //   //   : 'Product Detail'
          // }
          this.attribute = []
          // console.log('productDetail', this.productDetail)
          this.skuMechanics = this.productDetail.sku_mechanics
          /* สร้างตัวแปร attribute เพื่อใช้ในการแสดงในส่วน Product Information */
          this.attribute =
            this.productDetail.attributes !== undefined
              ? this.productDetail.attributes
              : {}

          /*Check attribute null or "" */
          //this.attribute = {}
          if (this.attribute.length > 0) {
            this.attribute = this.attribute.filter(
              (w) =>
                w.value[this.lang] !== '' &&
                w.value[this.lang] !== undefined &&
                w.value[this.lang] !== null &&
                w.is_display_on_product_page
            )

            const map = new Map(
              this.attribute.map(({ id, display_name, sequence }) => [
                sequence,
                { id, display_name, sequence, value: [] },
              ])
            )
            for (let { sequence, value } of this.attribute)
              map.get(sequence).value.push(...[value].flat())

            this.reduceAttribute = [...map.values()]
            console.log('reduceAttribute :', this.reduceAttribute)
          }

          if (
            this.$utils.isObjectValid(
              this.productDetail.content.short_description[this.lang]
            )
          )
            this.content.short_description =
              this.productDetail.content.short_description[this.lang]
          // this.productDetail.content.short_description[this.lang].replace(
          //   /(\n)/g,
          //   '<br>'
          // )
          if (
            this.$utils.isObjectValid(
              this.productDetail.content.long_description[this.lang]
            )
          )
            this.content.long_description =
              this.productDetail.content.long_description[this.lang]
          // this.productDetail.content.long_description[this.lang].replace(
          //   /(\n)/g,
          //   '<br>'
          // )
          /* function สำหรับเลือก sku ส่ง parameter เป็น id ของ sku */
          this.image = this.images[0].path
          this.zoom = this.images[0].zoom

          /* function สำหรับสร้าง breadcrumb ด้านบนสุด*/
          this.selectSku()
          this.skeletonPage = false

          await this.onGetPromotionCouponsSeller()

          // move to lazyload
          // this.getProductRelatedOrAlsoBuy('Related')
          // this.getProductRelatedOrAlsoBuy('AlsoBuy')
          if (this.isUser) {
            // this.addUserProductHistory()
            this.getCatalogsById()
          }
          // move to lazyload
          console.log('this.productDetail', this.productDetail)
          this.taxonomy = []
          if (this.$utils.isObjectKeyValid(this.productDetail, 'categories')) {
            this.taxonomy.push(
              this.productDetail.categories.display_name[
                this.$store.state.locale
              ]
            )
            this.taxonomyEN = []
            this.taxonomyCatId.push(this.productDetail.categories.id)
            let sortTaxonomy =
              this.productDetail.categories.parent_categories.sort(
                (a, b) => b.level - a.level
              )
            let sorted_categories = new Array(sortTaxonomy.length)
            if (sortTaxonomy.length > 0) {
              sortTaxonomy.forEach((e) => {
                // this.taxonomyEN.unshift(e.display_name['en'])
                this.taxonomy.unshift(e.display_name[this.$store.state.locale])
                this.taxonomyCatId.unshift(e.id)
                sorted_categories[e.level - 1] = e.display_name['en']
              })
            }
            this.taxonomyEN = [
              ...sorted_categories,
              this.productDetail.categories.display_name['en'],
            ]
          }

          //gtm
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'productViewed',
            ecommerce: {
              detail: {
                products: [
                  {
                    id: this.dataSku.sku_code,
                    price: this.$utils.formatPrice(
                      this.dataSku.price_include_vat,
                      2,
                      true
                    ),
                    categoryLv1: !!this.taxonomyEN[0] ? this.taxonomyEN[0] : '',
                    categoryLv2: !!this.taxonomyEN[1] ? this.taxonomyEN[1] : '',
                    categoryLv3: !!this.taxonomyEN[2] ? this.taxonomyEN[2] : '',
                    categoryIdLv1: !!this.taxonomyCatId[0]
                      ? this.taxonomyCatId[0]
                      : '',
                    categoryIdLv2: !!this.taxonomyCatId[1]
                      ? this.taxonomyCatId[1]
                      : '',
                    categoryIdLv3: !!this.taxonomyCatId[2]
                      ? this.taxonomyCatId[2]
                      : '',
                  },
                ],
              },
            },
          })

          // gtm GA4
          const item_list_id =
            (this.dataSku.is_flash_sale && 'flash_sale') ||
            (this.dataSku.is_promotion && 'promotions') ||
            ''
          const item_list_name =
            (this.dataSku.is_flash_sale && 'flash sale') ||
            (this.dataSku.is_promotion && 'Promotions') ||
            ''
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_item',
            ecommerce: {
              item_list_id,
              item_list_name,
              user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              value: this.dataSku.price_include_vat,
              items: [
                {
                  item_list_id,
                  item_list_name,
                  item_id: this.dataSku.sku_code,
                  item_name: this.dataSku.product_names[1]?.display_name.th,
                  item_brand: this.productDetail.brand?.display_name.en,
                  item_category: this.taxonomyEN[0] || '',
                  item_category2: this.taxonomyEN[1] || '',
                  item_category3: this.taxonomyEN[2] || '',
                  price: this.dataSku.price_include_vat,
                  discount:
                    this.dataSku.retail_price.include_vat -
                    this.dataSku.price_include_vat,
                  ...this.$store.getters['user/userPromotionTrack'],
                },
              ],
            },
          })

          // TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
          if (process.client && this.isDirectLink && !this.isAlgolia.loadPage) {
            console.log('send algolia isDirectLink')
            this.$eventInsightsAlgolia.sendEvent(
              TriggerInsightsAlgoliaEnum.VIEWPDP,
              this.isMyGuest ? this.guestId : this.openId,
              '',
              [this.dataSku.product_id]
            )
            this.isAlgolia.loadPage = true
          }
        }
        // snippet rating
        console.log('sku', this.dataSku)
        // this.ratingSnippet = {
        //   '@context': 'http://schema.org',
        //   '@type': 'Product',
        //   description: this.dataSku.meta.description_tag[this.lang],
        //   name: this.dataSku.meta.seo_name[this.lang],
        //   image:
        //     this.dataSku.images.length > 0 ? this.dataSku.images[0].url : '',
        //   sku: this.dataSku.sku_code,
        //   review: '',
        //   brand: this.productDetail.brand.display_name[this.lang],
        //   aggregateRating: {
        //     '@type': 'AggregateRating',
        //     ratingValue: this.$utils.formatPrice(
        //       this.dataSku.display_aggregate_rating,
        //       1
        //     ),
        //     reviewCount: this.dataSku.display_ratings_count,
        //   },
        //   offers: {
        //     '@type': 'Offer',
        //     availability: 'http://schema.org/InStock',
        //     price: this.$utils.formatPrice(this.dataSku.price_include_vat, 2),
        //     priceCurrency: 'THB',
        //     url:
        //       `https://www.ofm.co.th` +
        //       this.$i18n.path(
        //         `product/${this.dataSku.meta.seo_name[this.lang]}-p.${
        //           this.dataSku.sku_code
        //         }`
        //       ),
        //   },
        // }
        // window.history.replaceState(
        //   {},
        //   '',
        //   this.$i18n.path(`product/${this.pdp_title}-p.${this.sku_code}`)
        // )
        await this.getRecommendPromotionQuantity(this.sku_code)
      } else {
        this.isNotFound = true
        // this.getUserProductHistory()
        this.skeletonPage = false
      }
    },
    async getCatalogsById() {
      this.catalogs = []
      this.catalogSkus = []
      let params = new URLSearchParams()
      params.append('company_id', this.companyId)
      params.append('user_open_id', this.useropenId)
      params.append('account_channel', this.profileType)
      await this.$productProvider.getCatalogsDataById(params, false).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            data.forEach((e, index) => {
              // console.log('e', e.items.map(q => q.sku_code),  this.dataSku.sku_code)
              this.catalogs.push({
                index: index,
                is_default: e.is_default,
                catId: e.id,
                catName: e.name,
                items: e.items,
                isActive: e.items.some(
                  (q) => q.sku_code === this.dataSku.sku_code
                ),
              })
              this.catalogSkus = [
                ...this.catalogSkus,
                ...e.items.map((q) => q.sku_code),
              ]
            })
            this.isActiveCatalog = this.catalogSkus.some(
              (q) => q === this.dataSku.sku_code
            )
          }
        },
        (error) => {
          console.log('getCatalogsById', error.responsed)
        }
      )
    },
    async onCreateCatalog() {
      if (this.createCatalog.name === '') {
        this.isCatalog = true
      } else {
        this.catalog = {
          user_open_id: this.useropenId,
          account_channel: this.profileType,
          company_id: this.companyId,
          account_id:
            this.profileType === 'standard' ? this.userProfile.id : null,
          name: this.createCatalog.name,
        }
        await this.$productProvider.createCatalogData(this.catalog).then(
          (result) => {
            if (result.status === 201) {
              this.isCreateCatalog = false
              this.createCatalog.name = ''
              this.getCatalogsById()
            }
          },
          (error) => {
            console.log('createCatalog', error.responsed)
          }
        )
      }
    },
    async createProductCatalogs(catalogId) {
      let isExist = this.catalogSkus.some((q) => q === this.sku_code)
      let sku = [
        {
          sku_code: this.dataSku.sku_code,
          quantity: 1,
          //quantity: isExist ? 0 : 1,
        },
      ]
      await this.$productProvider
        .createProductCatalogsData(catalogId, sku)
        .then((result) => {
          if (result.status === 201) {
            let data = result.data
            this.isActiveCatalog = !isExist
            this.getCatalogsById()

            // gtm GA4
            this.$gtm.push({ ecommerce: null })
            this.$gtm.push({
              event: 'add_to_wishlist',
              ecommerce: {
                user: this.$store.getters['user/userInfoTrack'],
                currency: 'THB',
                value: this.dataSku.price_include_vat,
                items: [
                  {
                    item_id: this.dataSku.sku_code,
                    item_name: this.dataSku.product_names[1]?.display_name.th,
                    item_brand: this.productDetail.brand?.display_name.en,
                    item_category: this.taxonomyEN[0] || '',
                    item_category2: this.taxonomyEN[1] || '',
                    item_category3: this.taxonomyEN[2] || '',
                    price: this.dataSku.price_include_vat,
                    discount:
                      this.dataSku.retail_price.include_vat -
                      this.dataSku.price_include_vat,
                    ...this.$store.getters['user/userPromotionTrack'],
                  },
                ],
              },
            })
          }
        })
        .catch((error) => {
          console.log('createProductCatalogs', error.responsed)
        })

      if (
        (this.$utils.isValid(this.queryIdEventAlgolia) || !this.isDirectLink) &&
        !this.isAlgolia.catalogs.includes(catalogId)
      ) {
        this.$eventInsightsAlgolia.sendEvent(
          this.$utils.isValid(this.queryIdEventAlgolia)
            ? TriggerInsightsAlgoliaEnum.SEARCHPDPWISHLIST
            : TriggerInsightsAlgoliaEnum.NONSEARCHPDPWISHLIST,
          this.isMyGuest ? this.guestId : this.openId,
          this.queryIdEventAlgolia,
          [this.dataSku.product_id]
        )
        this.isAlgolia.catalogs.push(catalogId)
      }
    },
    async onSubmitProductNotify(data) {
      let apiData = JSON.stringify(data)
      console.log('apiData Notify : ', apiData)
      const result = await this.$productNotifyMeProvider.sendNotifyMe(apiData)
      if (result.status === 201) {
        // // console.log('onSubmitProductNotify success')
      }
    },
    colorCode(obj) {
      return this.$utils.isObjectValid(obj) && obj.type === 'color_code'
        ? `padding: 12px; background-color: ${obj.value}`
        : ''
    },
    isShowDisplayNameTypeColor(mechanic) {
      return mechanic.values.some(
        (q) =>
          q.some((x) => this.typeColor.includes(x.name)) &&
          q.some(
            (x) =>
              this.$utils.isObjectValid(x) &&
              this.$utils.isValid(x.value) &&
              x.name === '_id' &&
              x.value === this.selectedMaterial[mechanic.id]
          )
      )
    },
    showDisplayNameTypeColor(mechanic) {
      let result = ''
      let mechanicValue = mechanic.values.find(
        (q) =>
          q.some((x) => this.typeColor.includes(x.name)) &&
          q.some(
            (x) =>
              this.$utils.isObjectValid(x) &&
              this.$utils.isValid(x.value) &&
              x.name === '_id' &&
              x.value === this.selectedMaterial[mechanic.id]
          )
      )
      if (
        this.$utils.anyArrayObjectValid(mechanicValue) &&
        mechanicValue.some((y) => y.name === 'display_name')
      ) {
        let displayName = mechanicValue.find((y) => y.name === 'display_name')
        if (displayName.value.some((z) => z.name === this.lang)) {
          result = displayName.value.find((z) => z.name === this.lang).value
        }
      }
      return result
    },
    classMaterial(obj) {
      let _class = ''

      if (this.selectedMaterial[obj.id] === obj.valueKey) _class += ' active'
      if (this.disableMaterial(obj)) _class += ' btn-line-disable'
      return _class
    },
    disableMaterial(obj) {
      // let _keys = Object.keys(this.selectedMaterial)
      let _values = Object.values(this.selectedMaterial)

      // let _isMyMechanic = _keys.length < 2 && _keys.some((q) => q === obj.id)
      let _objFilters = []
      if (this.$utils.anyArrayObjectValid(_values)) {
        _objFilters = _objFilters.filter((q) =>
          q.values.map((q) => q.key).some((s) => _values.includes(s))
        )
      }
      _objFilters = this.skuMechanicKeys.filter(
        (q) =>
          q.values.map((q) => q.key).includes(obj.valueKey) && !q.isOutOfStock
      )

      return _objFilters.length === 0 // && !_isMyMechanic
      // let _isMyMechanic = _keys.length < 2 && _keys.some((q) => q === obj.id)
      // let _objFilters = this.skuMechanicKeys.filter(
      //   (q) =>
      //     q.values.map((q) => q.key).includes(obj.valueKey) && !q.isOutOfStock
      // )

      // if (_values !== null && _values.length > 0)
      //   _objFilters = _objFilters.filter((q) =>
      //     q.values.map((q) => q.key).some((s) => _values.includes(s))
      //   )
      // return _objFilters.length === 0 && !_isMyMechanic
    },
    mechanicKeyValue(type, values) {
      if (type === undefined) return ''
      return type.toLowerCase() === 'database'
        ? this.bsonDocument(values, '_id')
        : this.bsonDocument(
            this.bsonDocument(values, 'display_name'),
            this.lang
          )
    },
    displayMechanic(values) {
      // let images = this.bsonDocument(values, 'images')
      let image = this.bsonDocument(values, 'image')
      let color_code = this.bsonDocument(values, 'color_code')
      let displayName = this.bsonDocument(
        this.bsonDocument(values, 'display_name'),
        this.lang
      )
      // if (images !== undefined && images.length > 0) {
      //   let url = this.bsonDocument(
      //     images.find((q) =>
      //       q.some((s) => s.name === 'size' && s.value === 'sp')
      //     ),
      //     'url'
      //   )
      //   return { type: 'image', value: url }
      // }
      if (image !== undefined && image !== '')
        return {
          type: 'image',
          value: this.bsonDocument(image, 'url'),
          name: displayName,
        }
      else if (color_code !== undefined && color_code !== '')
        return { type: 'color_code', value: color_code, name: displayName }
      else return { type: 'name', value: displayName }
    },
    findSKUByMechanics() {
      let _this = this
      let _values = Object.values(this.selectedMaterial)
      let sku = undefined
      this.skuMechanicKeys.forEach((element) => {
        // console.log('s', _this.productDetail.skus, element)
        let ids = element.values.map((e) => e.key)
        let compareLength = this.getArraysIntersection(_values, ids).length
        if (
          _values !== null &&
          ids !== null &&
          _values.length === ids.length &&
          _values.length === compareLength &&
          _this.$utils.isObjectValid(_this.productDetail.skus)
        ) {
          sku = _this.productDetail.skus.find(
            (q) => q.sku_id === element.sku_id
          )
          // console.log('sku', sku)
        }
      })

      return sku
    },
    bsonDocument(objArray, key) {
      if (objArray == null || objArray.length == 0) return ''

      for (const element of objArray) {
        if (element.name === key) return element.value
      }
    },
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      )
    },
    getArraysIntersection(a1, a2) {
      return a1.filter(function (n) {
        return a2.indexOf(n) !== -1
      })
    },
    onNotify() {
      if (this.isMyGuest) return this.gotoLogin()

      if (!this.dataSku.is_company_product_control) {
        return
      }
      this.notifyModal = true
      this.notifySku = this.dataSku.sku_code
      if (
        this.$utils.isObjectValid(this.sku) &&
        this.$utils.anyArrayObjectValid(this.sku.product_names)
      ) {
        let _notifyProductName = this.sku.product_names.find(
          (q) => q.type.toLowerCase() === 'online'
        )
        if (this.$utils.isObjectValid(_notifyProductName))
          this.notifyProductName = _notifyProductName.display_name
        // this.sku.product_names.forEach((element) => {
        //   if (element.type.toLowerCase() === 'online') {
        //     this.notifyProductName = element.display_name
        //   }
        // })
      }
    },
    onCatalog() {
      this.isCatalog = false
    },
    onCatalogM() {
      this.isCatalogM = false
    },
    onInstallmentDetail() {
      // this.isInstallmentDetail = false
      this.$refs.tooltip.$emit('close')
    },
    selectSku() {
      if (this.productDetail.skus !== undefined) {
        this.skuMechanics = this.productDetail.sku_mechanics
        this.dataSku = this.productDetail.skus.find(
          (q) => q.sku_code === this.sku_code
        )
        if (this.dataSku.is_flash_sale) this.getFlashSaleExpired()
        this.sku_id = this.dataSku.sku_id
        this.qty =
          this.dataSku.minimum_quantity <= 0 ? 1 : this.dataSku.minimum_quantity
        let product_name = this.dataSku.product_names.find(
          (q) => q.type.toLowerCase() === 'online'
        )
        if (
          this.$utils.isObjectValid(product_name) &&
          this.$utils.isObjectValid(product_name.display_name) &&
          this.$utils.isValid(product_name.display_name[this.lang])
        ) {
          this.skuName = product_name.display_name[this.lang]
          // this.setRoutePath()
          if (this.breadcrumbItems.length) {
            this.isBreadcrumbItems = true
          } else {
            this.setRoutePath()
          }
        }

        /* ------ */
        this.productDetail.skus.forEach((element) => {
          let values = []
          element.mechanics.forEach((mechanic) => {
            let mechanicSku = this.productDetail.sku_mechanics.find((q) =>
              q.id.includes(mechanic.id)
            )
            if (mechanicSku !== undefined && mechanicSku !== null) {
              let id = mechanicSku.id
              let type = mechanicSku.type.toLowerCase()
              let key = this.mechanicKeyValue(type, mechanic.value)
              values.push({ id, key, type })
              if (element.sku_code === this.sku_code)
                this.selectedMaterial[id] = key
            }
          })

          this.skuMechanicKeys.push({
            sku_id: element.sku_id,
            isOutOfStock: this.productStatusNotReady.includes(
              element.display_status
            ), //element.onhand - element.reserve <= 0,
            values,
          })
        })
        /* ------- */
        this.$_onGetInsider({
          page: {
            type: 'Product',
            custom: {
              category_name: null,
            },
          },
          product: this.productInsiderParent,
        })
      }
    },
    // selectSku() {
    //   if (this.productDetail.skus !== undefined) {
    //     this.skuMechanics = this.productDetail.sku_mechanics
    //     // this.randomSku(this.productDetail.skus)
    //     this.productDetail.skus.forEach((element) => {
    //       let isMySku = element.sku_code === this.sku_code
    //       //  console.log('isMySku', isMySku,  element.sku_code , this.sku_code)
    //       // let isMySku = element.sku_id === this.sku_id
    //       if (isMySku) {
    //         this.dataSku = element //this.productDetail.skus.find(q => q.sku_id === this.sku_id)
    //         // console.log('this.dataSku', this.dataSku)
    //         this.sku_id = this.dataSku.sku_id
    //         this.qty =
    //           this.dataSku.minimum_quantity <= 0
    //             ? 1
    //             : this.dataSku.minimum_quantity
    //         this.dataSku.product_names.forEach((objName) => {
    //           if (objName.type === 'Online') {
    //             this.skuName = objName.display_name[this.lang]
    //           }
    //         })
    //       }

    //       let values = []
    //       element.mechanics.forEach((mechanic) => {
    //         let mechanicSku = this.productDetail.sku_mechanics.find((q) =>
    //           q.id.includes(mechanic.id)
    //         )
    //         if (mechanicSku !== undefined && mechanicSku !== null) {
    //           let id = mechanicSku.id
    //           let type = mechanicSku.type.toLowerCase()
    //           let key = this.mechanicKeyValue(type, mechanic.value)
    //           values.push({ id, key, type })
    //           if (isMySku) {
    //             this.selectedMaterial[id] = key
    //             // // console.log('selectSku', key, mechanicId)
    //           }
    //         }

    //         // let key = ''
    //         // let type = ''
    //         // if (mechanic.id !== '000000000000000000000000') {
    //         //   key = mechanic.id
    //         //   type = 'database'
    //         // } else {
    //         //   key = this.bsonDocument(
    //         //     this.bsonDocument(mechanic.value, 'display_name'),
    //         //     this.lang
    //         //   )
    //         //   type = 'freetext'
    //         // }

    //         // let mechanicId = this.productDetail.sku_mechanics.find((q) =>
    //         //   q.values.map((q) => this.mechanicKeyValue(type, q)).includes(key)
    //         // )
    //         // values.push({ id: mechanicId, key, type })

    //         // if (isMySku) {
    //         //   this.selectedMaterial[mechanicId] = key
    //         //   // // console.log('selectSku', key, mechanicId)
    //         // }
    //       })

    //       this.skuMechanicKeys.push({
    //         sku_id: element.sku_id,
    //         isOutOfStock: element.onhand - element.reserve <= 0,
    //         values,
    //       })
    //     })
    //   }
    // },
    /* เมื่อกดเลือก mechanics */
    changeSku(obj) {
      if (
        this.selectedMaterial[obj.id] !== undefined &&
        this.selectedMaterial[obj.id] === obj.valueKey
      ) {
        delete this.selectedMaterial[obj.id]
        // console.log('this.selectedMaterial2', this.selectedMaterial)
        return
      } else this.selectedMaterial[obj.id] = obj.valueKey

      // console.log('this.selectedMaterial3', this.selectedMaterial)
      // // console.log('changeSku', obj.id, obj.valueKey)
      if (
        this.skuMechanics !== null &&
        this.selectedMaterial !== null &&
        this.skuMechanics.length === Object.keys(this.selectedMaterial).length
      ) {
        let selectSku = this.findSKUByMechanics()
        // console.log('selectSku', selectSku)
        if (selectSku !== undefined) {
          this.dataSku = {}
          this.sku_id = selectSku.sku_id
          this.sku_code = selectSku.sku_code
          // // console.log('selectSku', selectSku)
          this.skuMechanicKeys = []
          this.selectedMaterial = {}
          this.selectSku()
          // this.dataSku = this.productDetail.skus.find(q => q.sku_id === this.sku_id)
        }
      }
    },
    /* function กำหนด breadcrumb ด้านบนเพื่อ rount ไปยัง category ต่างๆ
    ซึ่งจะหาร route จากการเช็ค category level จากโปรดัก */
    setRoutePath() {
      let objectOne = {}
      let objectTwo = {}
      this.breadcrumbItems = []
      this.isBreadcrumbItems = false
      objectOne = this.productDetail.categories.parent_categories.find(
        (q) => q.level === 1
      )
      objectTwo = this.productDetail.categories.parent_categories.find(
        (q) => q.level === 2
      )
      console.log(
        'this.productDetail.categories',
        this.productDetail.categories
      )
      // /* วนลูปจาก categories.parent_categories เพื่อเก็บรายละเอียด category level one-two */
      // this.productDetail.categories.parent_categories.forEach((element) => {
      //   console.log('cate', element)
      //   /* เก็บ category level one ค่า */
      //   if (element.level === 1) objectOne = element
      //   /* เก็บ category level two ค่า */ else if (element.level === 2)
      //     objectTwo = element
      // })

      // // // console.log('objectOne', this.productDetail.categories)
      /* กำหนดค่า route ในตัวแปร breadcrumbItems */
      if (
        this.$utils.isObjectValid(objectOne) &&
        this.$utils.isObjectValid(objectOne.meta) &&
        this.$utils.isObjectValid(objectOne.meta.seo_name) &&
        this.$utils.isValid(objectOne.meta.seo_name[this.$i18n.locale])
      ) {
        this.breadcrumbItems.push({
          /* ตัวหนังสือ เพื่อ display จะอยู่ที่ key text */
          text: objectOne.display_name[this.$i18n.locale],
          /* กำหนด link */
          href: {
            path: this.$i18n.path(
              `category/${objectOne.meta.seo_name[this.$i18n.locale]}`
            ),
          },
        })
        if (
          this.$utils.isObjectValid(objectTwo) &&
          this.$utils.isObjectValid(objectTwo.meta) &&
          this.$utils.isObjectValid(objectTwo.meta.seo_name) &&
          this.$utils.isValid(objectTwo.meta.seo_name[this.$i18n.locale])
        ) {
          this.breadcrumbItems.push({
            text: objectTwo.display_name[this.$i18n.locale],
            href: {
              path: this.$i18n.path(
                `category/${objectOne.meta.seo_name[this.$i18n.locale]}/${
                  objectTwo.meta.seo_name[this.$i18n.locale]
                }`
              ),
            },
          })
          if (
            this.$utils.isObjectValid(this.productDetail) &&
            this.$utils.isObjectValid(this.productDetail.categories) &&
            this.$utils.isObjectValid(
              this.productDetail.categories.display_name
            ) &&
            this.$utils.isValid(
              this.productDetail.categories.display_name[this.$i18n.locale]
            ) &&
            this.$utils.isObjectValid(
              this.productDetail.categories.meta.seo_name
            ) &&
            this.$utils.isValid(
              this.productDetail.categories.meta.seo_name[this.$i18n.locale]
            )
          ) {
            this.breadcrumbItems.push({
              text: this.productDetail.categories.display_name[
                this.$i18n.locale
              ],
              href: {
                path: this.$i18n.path(
                  `category/${objectOne.meta.seo_name[this.$i18n.locale]}/${
                    objectTwo.meta.seo_name[this.$i18n.locale]
                  }/${
                    this.productDetail.categories.meta.seo_name[
                      this.$i18n.locale
                    ]
                  }`
                ),
              },
            })

            this.breadcrumbItems.push({
              text: this.skuName,
              href: '#',
            })
          }
        }
      }
      // this.breadcrumbItems.push({
      //   /* ตัวหนังสือ เพื่อ display จะอยู่ที่ key text */
      //   text: objectOne.display_name[this.lang],
      //   /* กำหนด link */
      //   href:
      //     '/' + this.lang + '/category/' + objectOne.meta.seo_name[this.lang],
      // })
      /* text เป็นตัวแปรที่ใช้ในการแสดงผลของลำดับของ cate ที่เข้ามา
      href เป็นลิ้งเมือกดใช้ */
      // this.breadcrumbItems.push({
      //   text: objectTwo.display_name[this.lang],
      //   href:
      //     '/' +
      //     this.lang +
      //     '/category/' +
      //     objectOne.meta.seo_name[this.lang] +
      //     '/' +
      //     objectTwo.meta.seo_name[this.lang],
      // })

      // this.breadcrumbItems.push({
      //   text: this.productDetail.categories.display_name[this.lang],
      //   href:
      //     '/' +
      //     this.lang +
      //     '/category/' +
      //     objectOne.meta.seo_name[this.lang] +
      //     '/' +
      //     objectTwo.meta.seo_name[this.lang] +
      //     '/' +
      //     this.productDetail.categories.meta.seo_name[this.lang],
      // })

      // console.log('breadcrumb', this.breadcrumbItems)
      this.isBreadcrumbItems = true
      // snippet breadcrumb
      // this.breadcrumbSnippet = [
      //   {
      //     '@type': 'ListItem',
      //     position: 1,
      //     name: this.breadcrumbItems[0].text, //Main Category
      //     item: `https://www.ofm.co.th` + this.breadcrumbItems[0].href.path,
      //   },
      //   {
      //     '@type': 'ListItem',
      //     position: 2,
      //     name: this.breadcrumbItems[1].text, //Tier 2 Sub-Category
      //     item: `https://www.ofm.co.th` + this.breadcrumbItems[1].href.path,
      //   },
      //   {
      //     '@type': 'ListItem',
      //     position: 3,
      //     name: this.breadcrumbItems[2].text, //Tier 3 Sub-Category
      //     item: `https://www.ofm.co.th` + this.breadcrumbItems[2].href.path,
      //   },
      //   {
      //     '@type': 'ListItem',
      //     position: 4,
      //     name: this.breadcrumbItems[3].text, //product name
      //   },
      // ]
      // console.log('product', this.breadcrumbItems)
    },
    onChangeQty(qty) {
      let max = Number(this.dataSku.maximum_quantity > 0)
        ? Number(this.dataSku.maximum_quantity)
        : this.maxQty

      // console.log('item', qty, max, Number(qty), max < Number(qty))
      if (max < Number(qty)) this.qty = String(max)

      // this.qty =
      //   this.dataSku.minimum_quantity <= qty
      //     ? qty
      //     : this.dataSku.minimum_quantity
    },
    async onAddToCart() {
      if (this.isMyGuest) return this.gotoLogin()

      console.log(
        'this.dataSku.is_company_product_control',
        this.dataSku.is_company_product_control
      )
      if (!this.dataSku.is_company_product_control) {
        return
      }

      console.log('add to cart', this.qty)
      let parseData = this.userProfile
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: parseData.id,
        account_channel: this.profileType,
        skus: [
          {
            sku: this.dataSku.sku_code,
            qty: this.qty,
          },
        ],
      })
      const result = await this.$shoppingCartProvider.addCart(apiData)
      if (result.status === 201) {
        // gtm
        if (result.data.success_items.length) {
          this.$nuxt.$emit('addToCart')
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'addToCart',
            ecommerce: {
              add: {
                products: result.data.success_items.length
                  ? result.data.success_items.map((item) => {
                      let catLv1 = ''
                      let catLv2 = ''
                      let catLv3 = ''
                      let catIdLv1 = ''
                      let catIdLv2 = ''
                      let catIdLv3 = ''
                      if (this.$utils.isObjectKeyValid(item, 'categories')) {
                        if (item.categories.find((key) => key.level === 1)) {
                          catLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).display_name['en']
                          catIdLv1 = item.categories.find(
                            (key) => key.level === 1
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 2)) {
                          catLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).display_name['en']
                          catIdLv2 = item.categories.find(
                            (key) => key.level === 2
                          ).id
                        }
                        if (item.categories.find((key) => key.level === 3)) {
                          catLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).display_name['en']
                          catIdLv3 = item.categories.find(
                            (key) => key.level === 3
                          ).id
                        }
                      }
                      return {
                        id: item.sku_code,
                        price: this.$utils.formatPrice(
                          item.price_include_vat,
                          2,
                          true
                        ),
                        categoryLv1: catLv1,
                        categoryLv2: catLv2,
                        categoryLv3: catLv3,
                        categoryIdLv1: catIdLv1,
                        categoryIdLv2: catIdLv2,
                        categoryIdLv3: catIdLv3,
                        quantity: item.qty,
                      }
                    })
                  : [],
              },
            },
          })
          if (
            (this.$utils.isValid(this.queryIdEventAlgolia) ||
              !this.isDirectLink) &&
            !this.isAlgolia.addCart
          ) {
            this.$eventInsightsAlgolia.sendEvent(
              this.$utils.isValid(this.queryIdEventAlgolia)
                ? TriggerInsightsAlgoliaEnum.SEARCHPDPADDCART
                : TriggerInsightsAlgoliaEnum.NONSEARCHPDPADDCART,
              this.isMyGuest ? this.guestId : this.openId,
              this.queryIdEventAlgolia,
              [this.dataSku.product_id]
            )
            this.isAlgolia.addCart = true
          }

          // gtm GA4
          const item_list_id =
            (this.dataSku.is_flash_sale && 'flash_sale') ||
            (this.dataSku.is_promotion && 'promotions') ||
            ''
          const item_list_name =
            (this.dataSku.is_flash_sale && 'flash sale') ||
            (this.dataSku.is_promotion && 'Promotions') ||
            ''
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'add_to_cart',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              currency: 'THB',
              item_list_id,
              item_list_name,
              items: [
                {
                  item_list_id,
                  item_list_name,
                  item_id: this.dataSku.sku_code,
                  item_name: this.dataSku.product_names[1]?.display_name.th,
                  item_brand: this.productDetail.brand?.display_name.en,
                  item_category: this.taxonomyEN[0] || '',
                  item_category2: this.taxonomyEN[1] || '',
                  item_category3: this.taxonomyEN[2] || '',
                  quantity: this.qty,
                  price: this.dataSku.price_include_vat,
                  discount:
                    this.dataSku.retail_price.include_vat -
                    this.dataSku.price_include_vat,
                  ...this.$store.getters['user/userPromotionTrack'],
                },
              ],
            },
          })
        }
        if (
          result.data.warning_messages.length &&
          result.data.warning_messages.filter(
            (item) => item.key === 'is_sku_limit'
          ).length
        ) {
          const sku_limit = result.data.warning_messages.filter(
            (item) => item.key === 'is_sku_limit'
          )
          this.$nuxt.$emit('addToCart', {
            message: {
              title: sku_limit[0].message_title,
              description: sku_limit[0].message_description,
            },
            isWarning: true,
          })
        }
        const cartResponsed = await this.$shoppingCartProvider.getCartCount(
          this.cartId,
          parseData.id
        )
        if (cartResponsed.status === 200) this.setCartCount(cartResponsed.data)
      }
    },
    getIndex(index) {
      this.productSlider = index
    },
    collapseComment() {
      document.querySelectorAll('.customer-review').forEach((obj) => {
        if (obj.querySelectorAll('.customer-comment')[0].clientHeight > 200) {
          setTimeout(function () {
            obj.classList.add('extend')
          }, 100)
        }
      })
    },
    extend(index) {
      document
        .querySelectorAll('.customer-review')
        [index].classList.toggle('active')
    },
    async createParams() {
      let params = new URLSearchParams()
      params.append('accountChannel', this.profileType)
      params.append('skuCode', this.sku_code)
      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }
      return params
    },
    groupBy(array, key) {
      const result = {}
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    async addUserProductHistory() {
      let apiData = JSON.stringify({
        user_open_id: this.openId,
        profile_id: this.userProfile.id,
        account_channel: this.profileType,
        sku_code: this.dataSku.sku_code,
      })
      await this.$productProvider
        .addUserProductHistory(apiData)
        .then(async () => {
          await this.getUserProductHistory()
        })
    },
    async getUserProductHistory() {
      let params = new URLSearchParams()
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('status', 'Active')
      const result = await this.$productProvider.getUserProductHistory(
        this.cartId,
        this.userProfile.id,
        params
      )
      console.log('user product history', result.data)
      if (result.status === 200 && result.data.length) {
        this.itemsProductHistory = []
        result.data = result.data.filter((q) => q.id !== this.dataSku.sku_code)
        result.data.forEach((item, index) => {
          item.product_name = item.product_names.find(
            (q) => q.type.toLowerCase() === 'online'
          ).display_name
          this.itemsProductHistory.push(item)
          console.warn('object :>> ', item)
        })

        if (this.itemsProductHistory.length > 0) {
          let pid = this.itemsProductHistory.map((item) => {
            return item.sku_code
          })
          if (pid?.length > 0) {
            const graphqlResponse = await this.$graphqlProvider.onGetOverlay(
              pid
            )
            if (graphqlResponse) {
              this.overlayImagesItemsHistory = graphqlResponse
            }
          }
        }
      } else this.itemsProductHistory = []
    },
    async getProductRelated(type) {
      let params = this.createParamsProduct(type)
      await this.$productProvider
        .getProductRelatedData(params)
        .then(async (result) => {
          if (result.status === 200) {
            let data = result.data
            let items =
              this.$utils.anyArrayObjectValid(data.items) &&
              data.items.some((q) => this.$utils.anyArrayObjectValid(q.skus))
                ? data.items.filter((q) =>
                    this.$utils.anyArrayObjectValid(q.skus)
                  )
                : []
            this.itemsRelated = items

            if (this.itemsRelated.length > 0) {
              let pid = this.itemsRelated.map((item) => {
                return item.skus[0].sku_code
              })
              if (pid?.length > 0 && this.itemsRelated.length > 0) {
                const graphqlResponse =
                  await this.$graphqlProvider.onGetOverlay(pid)
                if (graphqlResponse) {
                  this.overlayImagesItemsRelated = graphqlResponse
                }
              }
            }
          }
        })
    },
    async getProductAlsoBuy(type) {
      let params = this.createParamsProduct(type)
      await this.$productProvider
        .getProductRelatedData(params)
        .then(async (result) => {
          if (result.status === 200) {
            let data = result.data
            let items =
              this.$utils.anyArrayObjectValid(data.items) &&
              data.items.some((q) => this.$utils.anyArrayObjectValid(q.skus))
                ? data.items.filter((q) =>
                    this.$utils.anyArrayObjectValid(q.skus)
                  )
                : []
            this.itemsAlsoBuy = items

            if (this.itemsAlsoBuy.length > 0) {
              let pids = this.itemsAlsoBuy.map((item) => {
                return item.skus[0].sku_code
              })

              if (pids?.length > 0 && this.itemsAlsoBuy.length > 0) {
                const graphqlResponse =
                  await this.$graphqlProvider.onGetOverlay(pids)
                if (graphqlResponse) {
                  this.overlayImagesItemsAlsoBuy = graphqlResponse
                }
              }
            }
          }
        })
    },
    // async getProductAlsoBuy() {
    //   let params = this.createParamsProduct('AlsoBuy')
    //   await this.$productProvider
    //     .getProductRelatedData(params)
    //     .then((result) => {
    //       if (result.status === 200) {
    //         let data = result.data
    //         this.itemsAlsoBuy = data.items ?? undefined
    //       }
    //     })
    // },
    createParamsProduct(type) {
      let params = new URLSearchParams()
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('productId', this.productId)
      params.append('skuCode', this.sku_code)
      params.append('type', type)
      params.append('isFree', false)
      params.append('status', 'Active')
      params.append('page', this.pageRelated)
      params.append('pageSize', this.perPageRelated)
      return params
    },
    GroupFulfilledBy(fulfilledBy) {
      return fulfilledBy === 'OFMStock' ? 'OfficeMate' : fulfilledBy
    },
    fileExtension(name) {
      return this.$utils.isValid(name)
        ? `[${name.split('.').pop().toUpperCase()}]`
        : ''
    },
    imageShowcase(imageUrl, size) {
      if (this.$utils.isValid(imageUrl)) return imageUrl
      else return size === 'zoom' ? this.imagelargeTemp : this.imageTemp
    },
    imageNormalShowcase(images) {
      if (images != null && images.length) {
        return (
          images.some((q) => q.size === 'thumbnail')
            ? images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'thumbnail').url
            : this.imageTemp
        ).replace('/thumbnail', '/original')
      } else {
        return this.imageTemp.replace('/thumbnail', '/original')
      }
    },
    imagelargeNormalShowcase(images) {
      if (images != null && images.length) {
        return images.some((q) => q.size === 'large')
          ? images.find((q) => q.size === 'large').url
          : this.imagelargeTemp
      } else {
        return this.imagelargeTemp
      }
    },
    imageZoomNormalShowcase(images) {
      if (images != null && images.length) {
        return images.some((q) => q.size === 'zoom')
          ? images.find((q) => q.size === 'zoom').url
          : this.imagelargeTemp
      } else {
        return this.imagelargeTemp
      }
    },
    warningMinimumQty(minimum_quantity) {
      let msg = this.$t('orderItemsComponent.warningMinimumQty')
      return msg.replace(
        '{product.minimum_quantity}',
        minimum_quantity.toString()
      )
    },
    warningMaximumQty(maximum_quantity) {
      let msg = this.$t('orderItemsComponent.warningMaximumQty')
      return msg.replace(
        '{product.maximum_quantity}',
        maximum_quantity.toString()
      )
    },
    tooltipDeliveryFee(delivery_fee) {
      let msg = this.$t('status_product.delivery_fee_remark')
      return msg.replace('{delivery_fee}', delivery_fee.toString())
    },
    gotoLogin() {
      this.signout()
      this.removeUserProfile()
      this.$router.push({ path: this.$i18n.path('login') })
    },
    // brandseeall(name) {
    //   if (name !== undefined) {
    //     this.$router.push({
    //       path: this.$i18n.path(
    //         `brand/${name.display_name[this.$i18n.locale]}`
    //       ),
    //     })
    //   }
    // },
    isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return true
    },
    async downloadExampleFile(urls, filename) {
      let encoded_urls = encodeURIComponent(urls)
      var checkSubUrl = this.isValidHttpUrl(
        `${process.env.NUXT_ENV_SERVICE_PRODUCT}`
      )
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization:
            this.$store.state.authen.accessToken !== ''
              ? `Bearer ${this.$store.state.authen.accessToken}`
              : '',
        },
      }
      fetch(
        `${checkSubUrl ? '' : process.env.NUXT_ENV_BASE_URL}${
          process.env.NUXT_ENV_SERVICE_PRODUCT
        }api/v1/Files?uri=${encoded_urls}`,
        requestOptions
      )
        .then((res) => res.blob())
        .then((myBlob) => {
          console.log('DataFile blob: ', myBlob)
          const FileSaver = require('file-saver')
          FileSaver.saveAs(myBlob, filename)
        })
    },
    async getRecommendPromotionQuantity(skuCode) {
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        params.append('company_id', this.userProfile.id)
      }
      let responsed =
        await this.$shoppingCartProvider.getRecommendPromotionQuantity(
          skuCode,
          params
        )
      // console.log('getRecommendPromotionQuantity', responsed)
      if (responsed.status === 200) {
        this.recommendPromotionQunatity = responsed.data
      }
      if (responsed.status === 404) {
        // is_promotion_discount_bysku
        if (this.isPromotionDiscount) {
          this.getPromotionDiscountpdp(this.sku_code)
        }
      }
    },
    async getPromotionDiscountpdp(skuCode) {
      console.log('sku', skuCode)
      const params = new URLSearchParams()
      params.append('sku_code', skuCode)
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile.id)
      // params.append('user_category_type', this.userProfile.categories_type)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', 1)
      params.append('pageSize', 20)
      let responsed = await this.$shoppingCartProvider.getPromotionDiscountpdp(
        params
      )
      console.log('PromotionDiscount', responsed)
      if (responsed.status === 200) {
        this.conditionPromotions = responsed.data.conditions
        this.urladdon = responsed.data.id
        this.itemsPromotionDiscount = []
        this.dataSku.is_promotion_discount = true
        this.getPromotionDiscountList(responsed.data.id)
        // console.log('resultProductDiscount0', responsed)
        // var resultProductDiscount = responsed.data.items.filter(
        //   (q) => q.id !== this.dataSku.sku_code
        // )
        // resultProductDiscount.forEach((item, index) => {
        //   console.log('resultProductDiscount1', item)
        //   item.skus.forEach((sku) => {
        //     console.log('sku', sku)
        //     sku.product_name = sku.product_names.find(
        //       (q) => q.type.toLowerCase() === 'online'
        //     ).display_name
        //     sku.display_product_status = sku.display_status
        //     sku.id = sku.sku_code
        //     console.log('itemsPromotionDiscountsku', sku)
        //     this.itemsPromotionDiscount.push(sku)
        //   })
        // })
      } else this.itemsPromotionDiscount = []

      console.log('itemsPromotionDiscount', this.itemsPromotionDiscount)

      if (responsed.status === 404) {
        this.dataSku.is_promotion_discount = false
      }
    },
    async getPromotionDiscountList(promotionId) {
      // console.log('promotionId', promotionId)
      const params = new URLSearchParams()
      params.append('sku_code', this.sku_code)
      params.append('promotion_id', promotionId)
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile.id || '')
      // params.append('user_category_type', this.userProfile.categories_type)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', 1)
      params.append('pageSize', 20)
      let responsed = await this.$productProvider.getPromotionDiscountList(
        params
      )
      console.log('PromotionDiscount', responsed)
      if (responsed.status === 200) {
        // this.conditionPromotions = responsed.data.conditions
        // this.urladdon = responsed.data.id
        this.itemsPromotionDiscount = []
        // this.dataSku.is_promotion_discount = true
        console.log('resultProductDiscount0', responsed.data.items)
        var resultProductDiscount = responsed.data.items.filter(
          (q) => q.id !== this.dataSku.sku_code
        )
        resultProductDiscount.forEach((item, index) => {
          console.log('resultProductDiscount1', item)
          item.skus.forEach((sku) => {
            console.log('sku', sku)
            sku.product_name = sku.product_names.find(
              (q) => q.type.toLowerCase() === 'online'
            ).display_name
            sku.display_product_status = sku.display_status
            sku.id = sku.sku_code
            sku.brand = item.brand
            console.log('itemsPromotionDiscountsku', sku)
            this.itemsPromotionDiscount.push(sku)
          })
        })
        // overlay promotion
        if (this.itemsPromotionDiscount.length > 0) {
          let pid = this.itemsPromotionDiscount.map((item) => {
            return item.id
          })
          if (pid?.length > 0) {
            const graphqlResponse = await this.$graphqlProvider.onGetOverlay(
              pid
            )
            if (graphqlResponse) {
              this.overlayImagesItemsPromotion = graphqlResponse
            }
          }
        }
      } else this.itemsPromotionDiscount = []

      console.log('itemsPromotionDiscount', this.itemsPromotionDiscount)

      if (responsed.status === 404) {
        this.dataSku.is_promotion_discount = false
      }
    },

    async getPaymentAllBankInstallment() {
      let responsed = await this.$productProvider.getPaymentAllBankInstallment(
        this.sku_code
      )
      if (responsed.status === 200) {
        this.itemsBankInstallment = responsed.data
        this.isInstallment =
          responsed.data.installment_cart_banks.length ||
          responsed.data.installment_sku_banks.length
        // this.itemsBankInstallment.forEach((a) => {
        //   let payments = []
        //   a.installment.forEach((c) => {
        //     payments.push({
        //       bank_id: c.bank_id,
        //       bank_name: c.name,
        //       period: c.period,
        //     })
        //   })
        //   this.paymentdata.push({
        //     image:
        //       a.installment[0].bank_images.find((a) => a.type === 'image')
        //         ?.url ?? '',

        //     payment: payments,
        //   })
        // })
      }
    },
    highlightActiveTier(proQty, currentQty, ListProQty) {
      let currentTier = null
      ListProQty.forEach((pro) => {
        if (currentQty >= pro.qty) currentTier = pro
      })
      return currentTier !== null && currentTier.qty === proQty ? true : false
    },
    calculatePromotionQuantityDiscount(
      originalPrice,
      discountPrice,
      campaignType,
      discountType,
      discountAmt
    ) {
      return campaignType === 'FixedPrice'
        ? `${this.$t('cart.cartItem.labelSave')} ${Math.round(
            ((originalPrice - discountPrice) / originalPrice) * 100
          )}%`
        : discountType === 'Baht'
        ? `${this.$t('cart.cartItem.lableQuantityDiscount')} ฿ ${discountAmt}`
        : `${this.$t('cart.cartItem.lableQuantityDiscount')} ${discountAmt}%`
    },
    // onFlashsaleTimeout(data) {
    //   console.log('onFlashsaleTimeout', data)
    // },
    async getFlashSaleExpired() {
      await this.$productProvider.getFlashSaleExpired().then(
        (result) => {
          if (result.status === 200) {
            this.currentDate = new Date(
              result.data.current_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })

            this.flashSaleEndDate = new Date(
              result.data.end_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })
            // let now = new Date().toLocaleString('en-US', {
            //   timeZone: 'Asia/Bangkok',
            // })
            // // Time remaining
            // let diff =
            //   new Date(this.flashSaleEndDate).getTime() -
            //   new Date(now).getTime()

            // if (diff > 0) this.getFlashSaleProductHome()
          }
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
    },
    onSetCookieShift() {
      setTimeout(() => {
        // console.log('ref', this.$refs['ref-product-summary'].clientHeight)
        let height = this.$refs['ref-product-summary']
          ? this.$refs['ref-product-summary'].clientHeight
          : 0
        if (height > 0) {
          this.$nuxt.$emit('cookieShift', {
            isShift: true,
            height: height,
          })
        }
      }, 50)
    },

    checkQuota(productQty, promotion) {
      console.log('checkQuota', productQty, promotion)
      if (promotion.quota_stock > 0) {
        const promotionQty = promotion.quota_stock - promotion.used_stock
        if (promotionQty <= 0) {
          return true
        }
        console.log('promotionQty', promotionQty)
        const quotaLimit = promotion.limit_quota
        let qty = productQty
        if (promotionQty < productQty) {
          qty = promotionQty
        }
        const ratio = Math.floor(qty / promotion.qty)
        console.log('ratio', ratio, quotaLimit)
        if (quotaLimit === 0) {
          if (ratio > 0) {
            if (productQty > promotionQty) {
              return true
            }
            return false
          }
        } else {
          if (ratio <= quotaLimit || true) {
            if (quotaLimit > 0 && productQty <= promotionQty) {
              return false
            } else {
              return true
            }
          } else {
            //alert(ratio)
            return false
          }
        }

        // const promotionQty = promotion.quota_stock - promotion.used_stock
        // if (promotionQty > 0) {
        //   const quotaLimit = promotion.limit_quota
        //   let qty = productQty
        //   if (promotionQty < productQty) {
        //     qty = promotionQty
        //   }
        //   // convert to int ratio
        //   //int ratio = Convert.ToInt32(qty / promotionQty);
        //   const ratio = Math.floor(qty / promotionQty)
        //   if (quotaLimit == 0 || ratio < promotionQty) {
        //     if (ratio > 0) {
        //       if (productQty > promotionQty) {
        //         return true
        //       }
        //       return false
        //     } else {
        //       return false
        //     }
        //   } else {
        //     return true
        //   }
        // } else {
        //   return true
        // }
      }

      return false
    },

    async onBoundingLoad() {
      if (!this.skeletonPage) {
        let height = 0
        if (
          this.$refs['lazy-content'].getBoundingClientRect().top <
            window.innerHeight - height &&
          this.onLoadContent === false
        ) {
          this.onLoadContent = true
          await this.onLazyLoad()
        }

        if (
          this.$refs?.['lazy-production-info'] &&
          this.$refs['lazy-production-info'].getBoundingClientRect().top <
            window.innerHeight - height &&
          this.onLoadInfo === false
        ) {
          this.onLoadInfo = true
        }
      }
    },
    async onLazyLoad() {
      await this.getProductRelated('Related')
      await this.getProductAlsoBuy('AlsoBuy')
      // await this.getProductRelatedOrAlsoBuy('Related')
      // await this.getProductRelatedOrAlsoBuy('AlsoBuy')
      if (this.isUser) {
        await this.addUserProductHistory()
        // await this.getCatalogsById()
      }
    },
    // onCloseDropdown() {
    //   this.$refs['tooltip-installment-detail'].$emit('close')
    // },
    async getShopDetail(skucode, product_brand_id, product_seller_id, type) {
      let responsed = await this.$graphQL.onGetShopDetail(
        skucode,
        product_brand_id,
        product_seller_id,
        type
      )

      if (responsed.product_overlays_product_skus?.length > 0) {
        const chooseProduct = responsed.product_overlays_product_skus?.filter(
          (shop) => shop.product_overlays_id !== null
        )

        const productChoose = chooseProduct.map((over) => {
          return {
            ...over,
            product_overlays_id: {
              ...over.product_overlays_id,
              overlay_web_image: `${process.env.NUXT_ENV_OVERLAY_ASSETS}/${over.product_overlays_id.overlay_web_image.id}/shark_product_overlay_${over.product_overlays_id.overlay_web_image.filename_download}?format=webp`,
            },
          }
        })
        console.log('productChoose', productChoose)

        this.overlayImages = productChoose.map((p) => {
          return {
            sku_code: p.product_skus_sku_code.sku_code,
            overlay_web_image: p.product_overlays_id?.overlay_web_image,
          }
        })
      }

      if (
        responsed?.shops &&
        responsed?.shops.length &&
        responsed?.shops[0].template_publish !== null
      ) {
        this.isShop = true
        this.shop = responsed

        this.translations = responsed?.shops.filter(
          (shop) => shop.translations !== null
        )[0]?.translations

        this.unique_name = responsed?.shops.filter(
          (shop) => shop.unique_name !== null
        )[0]?.unique_name

        this.shop_types_id = responsed?.shops.filter(
          (shop) => shop.shop_types_id !== null
        )[0]?.shop_types_id.type

        this.shop_tiers_id = responsed?.shops.filter(
          (shop) => shop.shop_tiers_id !== null
        )[0]?.shop_tiers_id.value

        const templatePublish = responsed?.shops.filter(
          (shop) => shop.template_publish !== null
        )[0]?.template_publish

        const componentsImages = templatePublish?.shop_components.filter(
          (component) => component.shop_components !== null
        )[0]?.components_images

        this.shopDetail = componentsImages?.filter(
          (component) => component.component_section === 'logo'
        )[0]
      } else {
        this.isLoading = false
        this.isShop = false
      }
    },

    async onGetPromotionCouponsSeller() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('account_id', this.userProfile?.id || '')
      params.append(
        'user_category_type',
        this.userProfile?.categories_type || 'Standard'
      )

      // params.append('seller_ids', this.seller_id)

      if (this.seller_id && this.vendor_type === 'DropShip')
        params.append('seller_ids', this.seller_id)
      params.append('coupon_types', 'Seller')
      // params.append('seller_id', this.seller_id)

      // const brandIds =
      //   this?.shop_id_Component?.shops_brands?.map((x) => x.brands_id) || []

      if (this.vendor_type === 'OFMStock') {
        params.append('brand_ids', this.brand_id)
        params.append('coupon_types', 'Brand')
      }

      // console.log('brandIds', brandIds, this.shop_types_id)
      // params.append('brand_ids', this.brand_id)
      params.append('skus', this.skus)
      params.append('page', 1)
      params.append('page_size', 20)
      await this.$productProvider.getPromotionCoupons(params).then(
        (result) => {
          if (result.status === 200 && result.data.items) {
            console.log('getPromotionCoupons', result.data)
            this.couponsSeller = [...result.data.items]
            console.log('couponsSeller', this.couponsSeller)
            console.log(
              'brand_id',
              this.couponsSeller.map((q) => q.brand_id)
            )
            console.log(
              'seller_id',
              this.couponsSeller.map((q) => q.seller_id)
            )
          }
        },
        (error) => {
          console.log('getPromotionCoupons', error.responsed)
        }
      )
      this.isLoading = false
    },

    onShowPromotionDetail(obj) {
      this.coupon_code = obj?.coupon_code
      this.couponDetail = obj?.promotion_detail_html
      this.promotionNo = obj?.promotion_no
      this.product_include_flag = obj?.product_include_flag
      if (this.couponDetail) this.isShowCouponDetail = true
    },
    getLinkShop() {
      const link =
        this.shop_types_id === 'OFMStock'
          ? `/ofm-shop/${this.unique_name}`
          : `/shop/${this.unique_name}`
      return link
    },
    getDisplayName() {
      return this.translations?.find(
        (x) =>
          x?.languages_code?.code ===
          (this.$i18n?.locale === 'en' ? 'en-US' : 'th-TH')
      )?.display_name
    },
  },
}
